import $api from '../../utils/api.js'


const state = () => ({
    payment: [],
    sorting: {
        search: '',
        status: '',
        package: '',
        member: '',
        date_start: '',
        date_end: '',
        placeholder:'Search Invoice Number'
    },
    errors:[],
})

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.payment = payload
    },
    SET_ERRORS(state, payload) {
        state.errors = payload
    },
}

const actions = {
    getPayment({ commit, state }) {
        return new Promise((resolve) => {
            $api.get(`payments?search=${state.sorting.search}&status=${state.sorting.status}&package=${state.sorting.package}&member=${state.sorting.member}&start_date=${state.sorting.date_start}&end_date=${state.sorting.date_end}`)
            .then((response) => {
                commit('ASSIGN_DATA', response.data)
                resolve(response.data)
            })
        })
    },
    exportPayment({ state }) {
        return new Promise((resolve) => {
            $api.get(`export-payments?search=${state.sorting.search}&status=${state.sorting.status}&package=${state.sorting.package}&member=${state.sorting.member}&start_date=${state.sorting.date_start}&end_date=${state.sorting.date_end}`, {responseType: 'arraybuffer'})
            .then((response) => {
                resolve(response.data)
            })
        })
    },
    exportInvioce(_,payload) {
        return new Promise((resolve) => {
            $api.get(`export-invoice/${payload}`, {responseType: 'arraybuffer'})
            .then((response) => {
                resolve(response.data)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}