<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <router-link :to="{name:'member-add'}"  class="btn btn-new"><i class="uil uil-plus mr-2"></i> Add Data</router-link>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">
                <div class="row mt-4">
                    <div class="col-12 text-center mt-3 hv-100" v-if="no_data === null">
                        <div class="half-circle-spinner">
                            <div class="circle circle-1"></div>
                            <div class="circle circle-2"></div>
                        </div>
                    </div>
                    <div class="col-12" v-if="no_data === false">
                        <div class="card bdr-20">
                            <div class="card-header border-0 py-4 tb-card">
                                <div class="table-sorting">
                                    <div class="table-search">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group mb-0">
                                                    <input placeholder="Search Member Name" @keyup="searchMember()" v-model="sorting.search" type="text" class="form-control" >
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group mb-0">
                                                    <select class="form-control" v-model="sorting.package" @change="getMember">
                                                        <option selected value="">Choose Package</option>
                                                        <option v-for="data in pck" :key="data.id" :value="data.id">{{data.category_name}} - {{data.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group mb-0">
                                                    <select class="form-control" v-model="sorting.status" @change="getMember">
                                                        <option selected value="">Choose Status</option>
                                                        <option value="ACTIVE">ACTIVE</option>
                                                        <option value="INACTIVE">INACTIVE</option>
                                                        <option value="REGISTER">REGISTER</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="button-modal text-right">
                                                    <button  v-bind:class="loading_export ? 'btn-progress' : ''" class="btn btn-new2" @click="_exportMember()"><i class="uil uil-file-export mr-2"></i> Export Excel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body px-0 pb-0">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th width="20px">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="all_id" v-model="selectAll">
                                                        <label class="custom-control-label" for="all_id"></label>
                                                    </div>
                                                </th>
                                                <th>Member Name</th>
                                                <th>Package</th>
                                                <th>Status</th>
                                                <th>Show on Web</th>
                                                <th>Subscribe Date</th>
                                                <th>Created Date</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="data in member.data" :key="data.id">
                                                <td width="20px" v-on:click.stop=""> 
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" v-model="del_member" :value="data.id" :id="data.id">
                                                        <label class="custom-control-label" :for="data.id"></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div>
                                                            <img :src="data.logo_url" alt="profile" class="my-3 mr-4 bdr-100 img-tb">
                                                        </div>
                                                        <div>
                                                            <div v-on:click.stop="" class="font-weight-bold">{{data.organization_name}}</div>
                                                            <div class="tx-g">{{data.category_name}}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{data.package_name}}</td>
                                                <td>
                                                    <label v-if="data.status === 'ACTIVE'" class="badge badge-success">{{data.status}}</label>
                                                    <label v-if="data.status === 'INACTIVE'"  class="badge badge-warning">{{data.status}}</label>
                                                    <label v-if="data.status === 'EXPIRED'"  class="badge badge-secondary">{{data.status}}</label>
                                                    <label v-if="data.status === 'REGISTER'"  class="badge badge-info">{{data.status}}</label>
                                                    <label v-if="data.status === 'REGISTER_PAYMENT'"  class="badge badge-info">REGISTER PAYMENT</label>
                                                    <label v-if="data.status === 'REGISTER_FAIL'"  class="badge badge-danger">REGISTER FAIL</label>
                                                </td>
                                                <td>
                                                    <label v-if="data.is_show === '1'" class="badge badge-success">YES</label>
                                                    <label v-if="data.is_show === '0'"  class="badge badge-warning">NO</label>
                                                </td>
                                                <td>{{data.subscribe_date |  formatDate('MMMM DD, YYYY')}}</td>
                                                <td>{{data.created_at |  formatDate('MMMM DD, YYYY')}}</td>
                                                <td width="20px" @click.stop="">
                                                    <b-dropdown>
                                                        <template #button-content>
                                                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"> <g> <g> <g> <circle cx="256" cy="256" r="64"/> <circle cx="256" cy="448" r="64"/> <circle cx="256" cy="64" r="64"/> </g> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                                                        </template>
                                                        <b-dropdown-item  v-on:click.prevent="_editMember(data)" href="#"><i class="uil uil-pen"></i> Edit Member</b-dropdown-item>
                                                        <b-dropdown-item  v-on:click.prevent="_viewMember(data)" href="#"><i class="uil uil-eye"></i> View Member</b-dropdown-item>
                                                        <b-dropdown-item  v-on:click.prevent="_exportDetailMember(data)" href="#"><i class="uil uil-file-export"></i> Export Member</b-dropdown-item>
                                                        <b-dropdown-item  v-on:click.prevent="_updateUserMember(data)" href="#"><i class="uil uil-user"></i> Update Member User</b-dropdown-item>
                                                    </b-dropdown>
                                                </td>
                                            </tr>
                                        
                                        </tbody>
                                        
                                    </table>
                                    <p v-if="member.total === 0" class="text-center no-data">Member data not found</p>
                                </div>
                            </div>
                            <div class="card-footer text-right">
                                <vue-pagination :pagination="member"
                                                :sorting="sorting"
                                                @paginate="getMember()">
                                </vue-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" v-if="no_data === true && sorting.search === ''">
                        <div class="card bdr-20 py-5">
                            <div class="card-body card-nitem text-center py-5">
                                <img class="mb-4" width="150px" src="@/assets/img/no-data.svg" alt="">
                                <div class="nitem-desc">
                                    <h3>Member Data Still Empty</h3>
                                    <p>Please create your member data on this member</p>
                                    <div class="tabel-action">
                                        <router-link :to="{name:'member-add'}"  class="btn btn-new"><i class="uil uil-plus mr-2"></i> Add Data</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-action" v-if="del_member.length !== 0">
                <div class="card-body">
                    <div class="float-left text-white mt-1">
                        <span v-on:click.prevent="del_member = []" class="times"><i class="uil uil-times"></i></span>
                        <span><span class="badge">{{del_member.length}}</span> data selected</span>
                    </div>
                    <div class="float-right">
                        <div v-b-modal.modal-Alert class="btn btn-danger">
                            <i class="uil uil-times"></i> Delete
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <b-modal id="modal-Alert" hide-footer hide-header centered>
            <div class="text-center pt-2 pb-3 px-3">
                <i style="font-size: 60px; color: #f8d735;" class="uil uil-exclamation-triangle"></i>
                <h3 class="mt-1">Confirmation</h3>
                <p style="font-size:16px;">Are you sure to delete this data ? data that has been deleted cannot be returned</p>
                <div class="btn-alert mt-4">
                    <button @click="_deleteMember()" v-bind:class="loading ? 'btn-progress' : ''" class="btn btn-danger mr-2">Sure</button>
                    <button @click="$bvModal.hide('modal-Alert')" class="btn btn-secondary">Cancel</button>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations} from 'vuex'
    import VuePagination from "../../components/Pagination.vue";
    import Alert from "../../components/Alert.vue";
    import moment from 'moment'

    export default {
        components: {
            VuePagination,
            Alert
        },
        data(){
            return {
                no_data: null,
                loading:false,
                loading_export:false,
                loading_export2:false,
                del_member:[],
            }
        },
        mounted(){
            this._getMember()
            this.selectPackage()
        },
        filters: {
            formatDate: function(val,format){
                if(val){
                    return moment(String(val)).format(format)
                }else{
                    return '-'
                }
            },
        },
        computed:{
            ...mapState('MemberControl', {
                member: state => state.member,
                sorting: state => state.sorting,
                formdata: state => state.formdata,
                errors: state => state.errors,
            }),
            ...mapState('SelectControl', {
                pck: state => state.package,
            }),

            selectAll: {
                get: function () {
                    return this.member.data ? this.del_member.length == this.member.data.length : false;
                },
                set: function (value) {
                    var selected = [];

                    if (value) {
                        this.member.data.forEach(function (member) {
                            selected.push(member.id);
                        });
                    }
                    this.del_member = selected;
                }
            }
        },
        methods: {
            ...mapActions('MemberControl', ['getMember','deleteMember','exportMember','exportDetailMember']),
            ...mapActions('SelectControl', ['selectPackage']),
            ...mapMutations('MemberControl', ['ASSIGN_FORM']),
            
            _exportMember() {
                this.loading_export = true
                this.exportMember().then(response => {
                    let blob = new Blob([response], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Members-ITS Indonesia.xlsx'
                    link.click();
                    blob = null;
                    this.loading_export = false
                })
            },
            _exportDetailMember(data) {
                this.loading_export2 = true
                this.exportDetailMember(data.id).then(response => {
                    let blob = new Blob([response], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Member-'+ data.organization_name +'.xlsx'
                    link.click();
                    blob = null;
                    this.loading_export2 = false
                })
            },
            searchMember: function(){
                clearTimeout(this.timeout);
                
                var self = this;
                this.timeout = setTimeout(function () {
                    self.getMember()
                }, 500);
            },
            _getMember:function(){
                this.sorting.type = 'NEWS'
                this.getMember().then(() => {
                    if(this.member.total == 0){
                        this.no_data = true
                    }else{
                        this.no_data = false
                    }
                })
            },
            _editMember: function(data){
                this.ASSIGN_FORM(data)
                this.$router.push({ name: 'member-edit', params: { id: data.id } })
            },
            _viewMember: function(data){
                this.ASSIGN_FORM(data)
                this.$router.push({ name: 'member-view', params: { id: data.id } })
            },
            _deleteMember:function(){
                this.loading = true
                this.deleteMember(this.del_member).then(() => {
                    this.loading = false
                    this.del_member = []
                    this.$bvModal.hide('modal-Alert')
                    this.$refs.alert.showAlert('success','Successfully deleted member data')
                })
            },
            _updateUserMember:function(data){
                this.$router.push({ name: 'member-user', params: { id: data.id } })
            }
        }
    }

</script>