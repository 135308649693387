import $api from '../../utils/api.js'


const state = () => ({
    newsletter: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
    },
    sorting: {
        num:10,
        search: '',
        offset: 4,
        sort:'asc',
        placeholder:'Search Newsletter'
    },
    formdata:{
        id:'',
        first_name:'',
        last_name:'',
        email:'',
        status:'ACTIVE',
    },
    errors:[],
})

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.newsletter = payload
    },
    ASSIGN_FORM(state, payload) {
        state.formdata = {
            id:payload.id,
            first_name:payload.first_name,
            last_name:payload.last_name,
            email:payload.email,
            status:payload.status,
        }
    },
    CLEAR_FORM(state) {
        state.formdata = {
            id:'',
            first_name:'',
            last_name:'',
            email:'',
            status:'ACTIVE',
        },
        state.errors = []
    },
    CLEAR_CATEGORY(state){
        state.newsletter = {
            total: 0,
            per_page: 2,
            from: 1,
            to: 0,
            current_page: 1
        }
    },
    SET_ERRORS(state, payload) {
        state.errors = payload
    },
}

const actions = {
    getNewsletter({ commit, state }) {
        return new Promise((resolve) => {
            $api.get(`newsletter?page=${state.newsletter.current_page}&paginate=${state.sorting.num}&search=${state.sorting.search}&sort=${state.sorting.sort}`)
            .then((response) => {
                commit('ASSIGN_DATA', response.data)
                resolve(response.data)
            })
        })
    },
    exportNewsletter({ state }) {
        return new Promise((resolve) => {
            $api.get(`export-newsletter?page=${state.newsletter.current_page}&paginate=${state.sorting.num}&search=${state.sorting.search}&sort=${state.sorting.sort}`, {responseType: 'arraybuffer'})
            .then((response) => {
                resolve(response.data)
            })
        })
    },
    createNewsletter({ commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`newsletter`, payload)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    editNewsletter({ commit, state }, payload) {
        return new Promise((resolve) => {
            $api.get(`newsletter?type=${state.sorting.type}&id=${payload}`)
            .then((response) => {
                commit('ASSIGN_FORM', response.data)
                resolve(response.data)
            })
        })
    },
    updateNewsletter({ state,commit }, payload) {
        return new Promise((resolve) => {
            $api.put(`newsletter/${state.formdata.id}`, payload.formData)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    deleteNewsletter({ dispatch }, payload) {
        return new Promise((resolve) => {
            $api.delete(`newsletter/${payload}`)
            .then(() => {
                dispatch('getNewsletter').then(() => resolve())
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}