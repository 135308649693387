var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('draggable',_vm._b({staticClass:"menu-area",attrs:{"tag":"ul","group":{ name: 'g1' },"list":_vm.list,"handle":".handle","value":_vm.value},on:{"input":_vm.emitter}},'draggable',_vm.dragOptions,false),_vm._l((_vm.listMenu),function(data,index){return _c('li',{key:data.id,staticClass:"drag-gallery",class:[{ 'active' : _vm.active_gallery === data.id}]},[_c('div',{staticClass:"link",class:[{ 'active' : _vm.active_gallery === data.id}]},[_c('div',{staticClass:"link-drag handle align-items-center d-flex"},[_c('i',{staticClass:"uil uil-draggabledots"})]),_c('div',{staticClass:"link-body menu-body cp",on:{"click":function($event){$event.preventDefault();return _vm.openSlide(data.id)}}},[_c('div',{staticClass:"link-content"},[_c('div',{staticClass:"link-item pr-5"},[_c('div',[_c('strong',[_vm._v(_vm._s(data.title))])])]),_c('div',{staticClass:"link-switch d-flex",on:{"click":function($event){$event.stopPropagation();}}},[_c('a',{staticClass:"act mb-0",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteCustomMenu(index,data.id)}}},[_c('i',{staticClass:"uil uil-trash-alt"})]),_c('a',{staticClass:"act mb-0",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openSlide(data.id)}}},[_c('i',{class:[_vm.active_gallery === data.id ? 'uil uil-angle-up' : 'uil uil-angle-down']})])])])])]),_c('div',{staticClass:"gallery-wrapper form-cst",on:{"click":function($event){$event.stopPropagation();}}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Menu Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.title),expression:"data.title"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(data.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "title", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Menu Sub Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.sub_title),expression:"data.sub_title"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(data.sub_title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "sub_title", $event.target.value)}}})]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("URL Type")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(data.url_type),expression:"data.url_type"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(data, "url_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option'),_c('option',{attrs:{"value":"INTERNAL"}},[_vm._v("INTERNAL")]),_c('option',{attrs:{"value":"EXTERNAL"}},[_vm._v("EXTERNAL")])]),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),(data.url_type === 'EXTERNAL')?_c('validation-provider',{attrs:{"rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Menu Url")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.url),expression:"data.url"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(data.url)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();},"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "url", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))]),_c('small',[_vm._v("If you choose url type external, you must add menu url full. ex : https://its-indonesia.org/about-us")])])]}}],null,true)}):_vm._e(),(data.url_type === 'INTERNAL')?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Menu Url")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.url),expression:"data.url"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(data.url)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();},"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "url", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))]),_c('small',[_vm._v("If you choose url type internal, you must add menu url after website url. ex : /about-us")])])]}}],null,true)}):_vm._e(),_c('div',{staticClass:"form-group mt-3"},[_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.target),expression:"data.target"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","true-value":"_blank","false-value":"_self","id":'highlight' + data.id},domProps:{"checked":Array.isArray(data.target)?_vm._i(data.target,null)>-1:_vm._q(data.target,"_blank")},on:{"change":function($event){var $$a=data.target,$$el=$event.target,$$c=$$el.checked?("_blank"):("_self");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(data, "target", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(data, "target", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(data, "target", $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":'highlight' + data.id}},[_c('b',{staticClass:"ml-2"},[_vm._v("Open in new Tab")])])])])],1),_c('nested-test',{staticClass:"menu2",attrs:{"list":data.menu}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }