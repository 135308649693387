<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <router-link class="back-sec" :to="{name:'tag', params: { type: $route.params.type }}">
                    <i class="uil uil-arrow-left"></i>
                </router-link>
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <button v-on:click="validationForm()" type="button" class="btn btn-new"  v-bind:class="loading ? 'btn-progress' : ''">
                            Save Data
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">
                
                <ValidationObserver ref="form" class="form-cst">

                    <div class="row mt-4">
                        <div class="col-md-8">
                            <div class="card bdr-20">
                                <div class="card-body">
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Tag Name</label>
                                            <input v-model="formdata.name" type="text" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                </div>
                            </div>  

                            <div class="card bdr-20">
                                <div class="card-body">
                                    <div class="form-group">
                                        <label>Meta Keyword</label>
                                        <input v-model="formdata.meta_keywords" type="text" class="form-control" name="name">
                                    </div>
                                    <div class="form-group">
                                        <label>Meta Title</label>
                                        <input v-model="formdata.meta_title" type="text" class="form-control" name="name">
                                    </div>
                                    <div class="form-group">
                                        <label>Meta Description</label>
                                        <textarea v-model="formdata.meta_description" class="form-control" name="name"></textarea>
                                    </div>
                                    
                                </div>
                            </div>  
                        </div>
                        <div class="col-md-4">
                            <div class="card bdr-20">
                                <div class="card-body">
                                    <validation-provider v-if="!formdata.image_cover_url" rules="mimes:image/jpeg,image/png,image/jpg" v-slot="{ errors, validate }">
                                        <div class="form-group">
                                            <div class="mb-1">
                                                <label>Tag Image Cover</label>
                                            </div>
                                            <div class="btn-up d-flex align-items-center" v-on:click.prevent="$refs.inputImage.click()">
                                                <div class="upload"><i class="uil uil-image mr-1"></i> ADD PHOTO</div>
                                            </div>
                                            <input ref="inputImage" 
                                                    type="file"
                                                    class="custom-file-input d-none"
                                                    id="Thumbnail"
                                                    v-on:change="onImageUpload"
                                                    accept="image/jpeg,image/png,image/jpg" 
                                                    @input="validate" >
                        
                                            <div class="invalid-input mt-3">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>
                                    
                                    <div class="form-group" v-if="formdata.image_cover_url">   
                                        <div class="mb-1">
                                            <label>Tag Image Cover</label>
                                        </div> 
                                        <button v-on:click.prevent="removeImageUpload()" type="button" class="btn-remove-image"><i class="uil uil-times"></i> Change Photo</button> 
                                        <div class="input-group img-sh">
                                            <img :src="formdata.image_cover_url" class="w-100 img-responsive img-prf-up">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </ValidationObserver>

            </div>
        </section>

    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import { mapState, mapActions, mapMutations} from 'vuex'
    import Alert from "../../components/Alert.vue";

    export default {
        data(){
            return{
                photo_file:'',
                loading:false,
            }
        },
        mounted(){
            if(this.$route.name == 'tag-edit' && this.formdata.id == ''){
                this.editTag(this.$route.params.id)
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            Alert,
        },
        computed:{
            ...mapState('TagControl', {
                formdata: state => state.formdata,
            }),
        },
        methods:{
            ...mapActions('TagControl', ['createTag','updateTag','editTag']),
            ...mapMutations('TagControl', ['CLEAR_FORM']),
            
            onImageUpload: function(event) {
                var input = event.target;

                if (input.files && input.files[0]) {

                    var reader = new FileReader();
                    var vm = this;
                    reader.onload = function(e) {
                        vm.formdata.image_cover_url = e.target.result;
                    }
                    reader.readAsDataURL(input.files[0]);
                }
                this.photo_file = input.files[0]
            },
            removeImageUpload:function(){
                this.photo_file = ''
                this.formdata.image_cover_url = ''
            },
            validationForm: function() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.form.errors)
                                .map(([key, value]) => ({ key, value }))
                                .filter(error => error["value"].length);
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100);
                    }else{

                        var formData = new FormData();
                        formData.append('name', this.formdata.name);
                        formData.append('type', this.$route.params.type.toUpperCase());
                        formData.append('meta_title', this.formdata.meta_title ? this.formdata.meta_title : '');
                        formData.append('meta_description', this.formdata.meta_description ? this.formdata.meta_description : '');
                        formData.append('meta_keywords', this.formdata.meta_keywords ? this.formdata.meta_keywords : '');

                        if(this.photo_file){
                            formData.append('image_cover', this.photo_file);
                        }
                        this.loading = true
                        
                        if(this.$route.name == 'tag-add'){

                            this.createTag(formData).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully added tag')
                                this.photo_file = ''
                                this.$refs.form.reset();
                                this.CLEAR_FORM()
                            }) 

                        }else if(this.$route.name == 'tag-edit'){

                            formData.append("_method", "put");
                            this.updateTag({id:this.$route.params.id,formData:formData}).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully edited tag')
                                this.$refs.form.reset();
                                this.photo_file = ''
                            }) 

                        }
                       
                    }
                });
            },
        },
        beforeRouteLeave (to, from, next) {
            this.CLEAR_FORM()
            next();
        }
    }

</script>