<template>
    <div class="main-content">
        <section class="section">

            <div class="section-body mt-4">
                <div class="row">
                    <div class="col-md-3" v-for="(data,index) in reports.post" :key="index">
                        <div class="card card-statistic-2 bdr-20 p-4">
                            <div class="ic-wrapper">
                                <div class="ic">
                                    <i class="uil uil-users-alt w-100"></i>
                                </div>
                            </div>
                            <div class="cst-cnt mt-3">
                                <h5 class="mb-0 text-capitalize">{{data.type.toLowerCase()}}</h5>
                                <p class="mb-0 text-capitalize">Total {{data.type.toLowerCase()}}</p>
                            </div>
                            <div class="cst-val">
                                <h4 class="w-100 mb-0">{{data.total}}</h4>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="card card-statistic-2 bdr-20 p-4">
                            <div class="ic-wrapper">
                                <div class="ic">
                                    <i class="uil uil-users-alt w-100"></i>
                                </div>
                            </div>
                            <div class="cst-cnt mt-3">
                                <h5 class="mb-0">Page</h5>
                                <p class="mb-0">Total Page</p>
                            </div>
                            <div class="cst-val">
                                <h4 class="w-100 mb-0">{{reports.page}}</h4>
                            </div>
                        </div>
                    </div>
                     <div class="col-md-6">
                        <div class="card card-statistic-2 bdr-20 p-4">
                            <div class="ic-wrapper">
                                <div class="ic">
                                    <i class="uil uil-users-alt w-100"></i>
                                </div>
                            </div>
                            <div class="cst-cnt mt-3">
                                <h5 class="mb-0">Member</h5>
                                <p class="mb-0">Total Member</p>
                            </div>
                            <div class="cst-val">
                                <h4 class="w-100 mb-0">{{reports.member}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapActions, mapState} from 'vuex'

    export default {
        mounted(){
            this.getDashboard()
        },
        computed:{
            ...mapState('DashboardControl', {
                reports: state => state.reports,
            }),
        },
        methods: {
            ...mapActions('DashboardControl', ['getDashboard']),
        }
    }

</script>