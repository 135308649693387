<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <router-link class="back-sec" :to="{name:'events'}">
                    <i class="uil uil-arrow-left"></i>
                </router-link>
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <button v-on:click="validationForm()" type="button" class="btn btn-new"  v-bind:class="loading ? 'btn-progress' : ''">
                            Save Data
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">

                <ValidationObserver ref="form" class="form-cst">

                    <div class="row mt-4">
                            <div class="col-md-8">
                                <div class="card bdr-20">
                                    <div class="card-body">
                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Event Title</label>
                                                <input v-model="formdata.title" type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Event Status</label>
                                                <select class="form-control" v-model="formdata.status">
                                                    <option></option>
                                                    <option value="DRAFT">DRAFT</option>
                                                    <option value="PUBLISHED">PUBLISHED</option>
                                                </select>
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <div class="form-group mt-3">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="formdata.highlight" class="custom-control-input" true-value="1" false-value="0" id="highlight">
                                                <label class="custom-control-label" for="highlight"><b class="ml-2">Check this if event showing in highlight</b></label>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <label>Event Date</label>
                                                        <input v-model="formdata.post_date" type="date" class="form-control" name="name">
                                                        <div class="invalid-input">{{ errors[0] }}</div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <label>Event Time</label>
                                                        <input v-model="formdata.post_time" type="text" class="form-control" name="name">
                                                        <div class="invalid-input">{{ errors[0] }}</div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                        </div>

                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Event Location</label>
                                                <input v-model="formdata.post_location" type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider rules="url" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Event Register Link</label>
                                                <input v-model="formdata.post_register" @keydown.space.prevent type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider rules="url" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Event Download Materi Link</label>
                                                <input v-model="formdata.post_download" @keydown.space.prevent type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Event Content</label>
                                                <editor
                                                v-model="formdata.content"
                                                :api-key="api_tiny"
                                                :init="EditorOptions"
                                            />
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <div class="form-group">
                                            <label>Event Excerpt</label>
                                            <textarea v-model="formdata.short_desc" class="form-control" name="name"></textarea>
                                        </div>


                                    </div>
                                </div>

                                <div class="card bdr-20">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label>Meta Keyword</label>
                                            <input v-model="formdata.meta_keywords" type="text" class="form-control" name="name">
                                        </div>
                                        <div class="form-group">
                                            <label>Meta Title</label>
                                            <input v-model="formdata.meta_title" type="text" class="form-control" name="name">
                                        </div>
                                        <div class="form-group">
                                            <label>Meta Description</label>
                                            <textarea v-model="formdata.meta_description" class="form-control" name="name"></textarea>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card bdr-20">
                                    <div class="card-body">
                                        <validation-provider v-if="!formdata.image_cover_url" rules="mimes:image/jpeg,image/png,image/jpg" v-slot="{ errors, validate }">
                                            <div class="form-group">
                                                <div class="mb-1">
                                                    <label>Event Image Cover</label>
                                                </div>
                                                <div class="btn-up d-flex align-items-center" v-on:click.prevent="$refs.inputImage.click()">
                                                    <div class="upload"><i class="uil uil-image mr-1"></i> ADD PHOTO</div>
                                                </div>
                                                <input ref="inputImage"
                                                        type="file"
                                                        class="custom-file-input d-none"
                                                        id="Thumbnail"
                                                        v-on:change="onImageUpload"
                                                        accept="image/jpeg,image/png,image/jpg"
                                                        @input="validate" >

                                                <div class="invalid-input mt-3">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <div class="form-group" v-if="formdata.image_cover_url">
                                            <div class="mb-1">
                                                <label>Event Image Cover</label>
                                            </div>
                                            <button v-on:click.prevent="removeImageUpload()" type="button" class="btn-remove-image"><i class="uil uil-times"></i> Change Photo</button>
                                            <div class="input-group img-sh">
                                                <img :src="formdata.image_cover_url" class="w-100 img-responsive img-prf-up">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card bdr-20">
                                    <div class="card-body">
                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Event Category</label>
                                                <v-select :clearable="false" label="name" :reduce="catagory => catagory.id" v-model="formdata.category_id" :options="category"></v-select>
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="card bdr-20">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label>Event Tags</label>
                                            <v-select :clearable="false" @input="addTag" @search="getSearchTag" label="name" :reduce="filteredTags => filteredTags.id" v-model="vm_hastag" :options="filteredTags">
                                                <template v-slot:option="option">
                                                    #{{ option.name }}
                                                </template>

                                                <a href="#" slot="no-options" v-on:click.prevent="addNewTag()">Add New Hastag</a>
                                            </v-select>
                                        </div>

                                        <div class="tags">
                                            <span class="badge badge-primary mb-2 mr-2 py-2" v-for="(data,index) in formdata.tags" :key="index">
                                                {{data | tagName(tag)}}
                                                <span class="close-tag" @click="deleteTag(index)">
                                                        <i class="uil uil-times"></i>
                                                </span>
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                    </div>

                </ValidationObserver>

            </div>
        </section>

    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import vSelect from 'vue-select'
    import { mapState, mapActions, mapMutations} from 'vuex'
    import Alert from "../../components/Alert.vue";
    import options from "../../utils/editor";
    import Editor from '@tinymce/tinymce-vue'

    export default {
        data(){
            return{
                search_hastag:'',
                api_tiny:process.env.VUE_APP_TINYKEY,
                vm_hastag:'',
                photo_file:'',
                loading:false,
                EditorOptions: options.Options
            }
        },
        mounted(){
            if(this.$route.name == 'event-edit' && this.formdata.id == ''){
                this.editPost(this.$route.params.id)
            }
            this.selectCategory('EVENTS')
            this.selectTag('EVENTS').then(() => {
                this.filterTags()
            })
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            vSelect,
            Alert,
            Editor
        },
        computed:{
            ...mapState('PostControl', {
                formdata: state => state.formdata,
            }),
            ...mapState('SelectControl', {
                category: state => state.category,
                tag: state => state.tag,
            }),

            filteredTags () {
                let filterProducts = this.$store.getters['SelectControl/getFilteredTags'];
                return filterProducts
            },
        },
        filters:{
            tagName:function(val,tags){
                var name = val
                tags.forEach(tag => {
                    if(tag.id == val){
                        name = tag.name
                    }
                });
                return name
            }
        },
        methods:{
            ...mapActions('SelectControl', ['selectCategory','selectTag']),
            ...mapActions('PostControl', ['createPost','updatePost','editPost']),
            ...mapMutations('PostControl', ['CLEAR_FORM']),

            filterTags:function() {
                this.$store.dispatch('SelectControl/filterTags', {tags:this.formdata.tags} )
            },
            addTag:function(){
                this.formdata.tags.push(this.vm_hastag);
                this.vm_hastag = ''
                this.filterTags()
            },
            getSearchTag: function(val){
                this.search_hastag = val
            },
            addNewTag:function(){
                this.formdata.tags.push(this.search_hastag);
            },
            deleteTag:function(index){
                this.formdata.tags.splice(index,1);
            },
            onImageUpload: function(event) {
                var input = event.target;

                if (input.files && input.files[0]) {

                    var reader = new FileReader();
                    var vm = this;
                    reader.onload = function(e) {
                        vm.formdata.image_cover_url = e.target.result;
                    }
                    reader.readAsDataURL(input.files[0]);
                }
                this.photo_file = input.files[0]
            },
            removeImageUpload:function(){
                this.photo_file = ''
                this.formdata.image_cover_url = ''
            },
            validationForm: function() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.form.errors)
                                .map(([key, value]) => ({ key, value }))
                                .filter(error => error["value"].length);
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100);
                    }else{

                        var formData = new FormData();
                        formData.append('title', this.formdata.title);
                        formData.append('content', this.formdata.content ? this.formdata.content : '' );
                        formData.append('short_desc', this.formdata.short_desc ? this.formdata.short_desc : '');
                        formData.append('post_location', this.formdata.post_location);
                        formData.append('post_time', this.formdata.post_time);
                        formData.append('post_date', this.formdata.post_date);
                        formData.append('post_register', this.formdata.post_register ? this.formdata.post_register : '');
                        formData.append('post_download', this.formdata.post_download ? this.formdata.post_download : '');
                        formData.append('meta_title', this.formdata.meta_title ? this.formdata.meta_title : '');
                        formData.append('meta_description', this.formdata.meta_description ? this.formdata.meta_description : '');
                        formData.append('meta_keywords', this.formdata.meta_keywords ? this.formdata.meta_keywords : '');
                        formData.append('status', this.formdata.status);
                        formData.append('category_id', this.formdata.category_id);
                        formData.append('highlight', this.formdata.highlight);
                        formData.append('tags', JSON.stringify(this.formdata.tags));
                        formData.append('type', 'EVENTS');

                        if(this.photo_file){
                            formData.append('image_cover', this.photo_file);
                        }
                        this.loading = true

                        if(this.$route.name == 'event-add'){

                            this.createPost(formData).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully added event')
                                this.$refs.form.reset();
                                this.photo_file = ''
                                this.CLEAR_FORM()

                                this.selectTag('EVENTS').then(() => {
                                    this.filterTags()
                                })
                            })

                        }else if(this.$route.name == 'event-edit'){

                            formData.append("_method", "put");
                            this.updatePost({id:this.$route.params.id,formData:formData}).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully edited event')
                                this.$refs.form.reset();
                                this.photo_file = ''
                            })

                        }

                    }
                });
            },
        },
        beforeRouteLeave (to, from, next) {
            this.CLEAR_FORM()
            next();
        }
    }

</script>
