<template>
    <div class="card-header-form justify-content-center align-self-center">
        <div class="input-group">
            <input type="text" @keyup="searchdata" class="form-control" :placeholder="search.placeholder" v-model="getsearch">
            <div class="search-group-btn">
                <i class="uil uil-search"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return {
            getsearch: '',
        }
    },
    props: {
      search: {
          type: Object,
          required: true,
      }
    },
    methods: {
          searchdata: function() {
            clearTimeout(this.timeout);
            
            var self = this;
            this.timeout = setTimeout(function () {
                self.search.search = self.getsearch;
                self.$emit('searchdata');
            }, 500);

          }
        }
  }
</script>