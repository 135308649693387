<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <router-link class="back-sec" :to="{name:'menu'}">
                    <i class="uil uil-arrow-left"></i>
                </router-link>
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <button v-on:click="validationForm()" type="button" class="btn btn-new"  v-bind:class="loading ? 'btn-progress' : ''">
                            Save Data
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">

                <div class="justify-content-between row">
                    <div class="col-md-12">
                        <div class="card bdr-20">
                            <div class="card-body">
                                <ValidationObserver ref="formmenu" class="form-cst">
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Menu Name</label>
                                            <input v-model="formdata.name" type="text" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <div class="form-group mt-3">
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" v-model="formdata.menu_position" class="custom-control-input" true-value="main-menu" false-value="" id="highlight1">
                                            <label class="custom-control-label" for="highlight1"><b class="ml-2">Main Menu</b></label>
                                        </div>

                                        <div class="custom-control custom-checkbox  custom-control-inline">
                                            <input type="checkbox" v-model="formdata.menu_position" class="custom-control-input" true-value="side-menu" false-value="" id="highlight2">
                                            <label class="custom-control-label" for="highlight2"><b class="ml-2">Side Menu</b></label>
                                        </div>

                                        <div class="custom-control custom-checkbox  custom-control-inline">
                                            <input type="checkbox" v-model="formdata.menu_position" class="custom-control-input" true-value="footer-menu1" false-value="" id="highlight3">
                                            <label class="custom-control-label" for="highlight3"><b class="ml-2">Footer Menu 1</b></label>
                                        </div>

                                        <div class="custom-control custom-checkbox  custom-control-inline">
                                            <input type="checkbox" v-model="formdata.menu_position" class="custom-control-input" true-value="footer-menu2" false-value="" id="highlight4">
                                            <label class="custom-control-label" for="highlight4"><b class="ml-2">Footer Menu 2</b></label>
                                        </div>
                                    </div>
                                </ValidationObserver>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <ValidationObserver ref="formcustommenu" class="form-cst">

                            <div class="card bdr-20">
                                <div class="card-header">
                                    <h4>Add Menu List</h4>
                                </div>
                                <div class="card-body">
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>List Menu Name</label>
                                            <input v-model="customenu.title" type="text" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>URL Type</label>
                                            <select class="form-control" v-model="customenu.url_type">
                                                <option></option>
                                                <option value="INTERNAL">INTERNAL</option>
                                                <option value="EXTERNAL">EXTERNAL</option>
                                            </select>
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <validation-provider v-if="customenu.url_type === 'EXTERNAL'" rules="required|url" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Menu Url</label>
                                            <input v-model="customenu.url" @keydown.space.prevent type="text" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                            <small>If you choose url type external, you must add menu url full. ex : https://its-indonesia.org/about-us</small>
                                        </div>
                                    </validation-provider>

                                    <validation-provider v-if="customenu.url_type === 'INTERNAL'" rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Menu Url</label>
                                            <input v-model="customenu.url" @keydown.space.prevent type="text" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                            <small >If you choose url type internal, you must add menu url after website url. ex : /about-us</small>
                                        </div>
                                    </validation-provider>

                                    <div class="form-group mt-3">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" v-model="customenu.target" class="custom-control-input" true-value="_blank" false-value="_self" id="highlight">
                                            <label class="custom-control-label" for="highlight"><b class="ml-2">Open in new Tab</b></label>
                                        </div>
                                    </div>

                                    <button v-on:click="addCustomMenu()" type="button" class="btn btn-new">
                                        Add Menu
                                    </button>
                                </div>
                            </div>

                        </ValidationObserver>
                    </div>

                    <nested-test class="col-md-8" :del_menu="del_menu" v-model="listMenu" />

                </div>
            </div>
        </section>

    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import { mapState, mapActions, mapMutations} from 'vuex'
    import NestedTest  from "./nested-test.vue";
    import Alert from "../../components/Alert.vue";

    export default {
        name: "nested-with-vmodel",
        order: 16,
        data(){
            return{
                loading:false,
                customenu:{
                    id:'',
                    title:'',
                    sub_title:'',
                    url:'',
                    url_type:'INTERNAL',
                    target:'_self',
                    menu: []
                },
                del_menu:[]
            }
        },
        mounted(){
            if(this.$route.name == 'menu-edit' && this.formdata.id == ''){
                this.editMenu(this.$route.params.id)
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            NestedTest,
            Alert
        },
        computed:{
            ...mapState('MenuControl', {
                formdata: state => state.formdata,
            }),

            listMenu: {
                get() {
                    return this.formdata.menu;
                },
                set(value) {
                    this.SET_POSITION_MENU(value)
                }
            }

        },

        methods:{
            ...mapActions('MenuControl', ['createMenu','updateMenu','editMenu']),
            ...mapMutations('MenuControl', ['CLEAR_FORM','SET_POSITION_MENU']),

            addCustomMenu:function(){
                this.$refs.formcustommenu.validate().then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.formcustommenu.errors)
                                .map(([key, value]) => ({ key, value }))
                                .filter(error => error["value"].length);
                            this.$refs.formcustommenu.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100);
                    }else{

                        this.formdata.menu.push({
                            id:Math.random().toString(36).substring(7),
                            title:this.customenu.title,
                            sub_title:this.customenu.sub_title,
                            url:this.customenu.url,
                            url_type:this.customenu.url_type,
                            target:this.customenu.target,
                            menu: []
                        });

                        this.$refs.formcustommenu.reset();

                        this.customenu = {
                            id:'',
                            title:'',
                            sub_title:'',
                            url:'',
                            url_type:'INTERNAL',
                            target:'_self',
                            menu: []
                        }
                    }
                });
            },
            validationForm: function() {
                this.$refs.formmenu.validate().then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.formmenu.errors)
                                .map(([key, value]) => ({ key, value }))
                                .filter(error => error["value"].length);
                            this.$refs.formmenu.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100);
                    }else{

                        this.loading = true

                        var formData = new FormData();
                        formData.append('name', this.formdata.name);
                        formData.append('menu_position', this.formdata.menu_position);
                        formData.append('menu', JSON.stringify(this.formdata.menu));

                        if(this.$route.name == 'menu-add'){

                            this.createMenu(formData).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully added menu')
                                this.$refs.formmenu.reset();
                                this.CLEAR_FORM()
                            })

                        }else if(this.$route.name == 'menu-edit'){

                            formData.append('del_menu', this.del_menu);
                            formData.append("_method", "put");

                            this.updateMenu({id:this.$route.params.id,formData:formData}).then(() => {
                                this.editMenu(this.$route.params.id)
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully edited menu')
                                this.$refs.formmenu.reset();
                            })

                        }

                    }
                });
            },
        },
        beforeRouteLeave (to, from, next) {
            this.CLEAR_FORM()
            next();
        }
    }

</script>
