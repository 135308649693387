import $api from '../../utils/api.js'

const login = {
    namespaced: true,
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        user : JSON.parse(localStorage.getItem("user") || null),
        errors:[],
        menu:true,
      },
      mutations: {
        AUTH_SUCCESS(state, user){
          state.status = 'success'
          state.token = user.token
          state.user = user.user
        },
        AUTH_ERRORS(state, payload) {
          state.status = 'error'
          state.errors = payload
        },
        CHANGE_MENU(state, payload) {
          state.menu = payload
        },
      },
      actions: {
        login({commit}, user){
          return new Promise((resolve, reject) => {
            $api.post('login', user)
            .then(resp => {
              const user = {
                token : resp.data.token,
                user : resp.data.user,
              }
    
              localStorage.setItem('token', resp.data.token)
              localStorage.setItem('user', JSON.stringify(resp.data.user))
              $api.defaults.headers.common['Authorization'] = `Bearer ${ resp.data.token }`
              commit('AUTH_SUCCESS', user)
              resolve(resp)
            })
            .catch(error => {
              commit('AUTH_ERRORS', error.response.data)
              localStorage.removeItem('token')
              localStorage.removeItem('user')
              reject(error)
            })
          })
        },
        logout({state}){
          return new Promise((resolve) => {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            state.user = []
            state.token = ''
            delete $api.defaults.headers.common['Authorization']
            resolve()
          })
        },
      },
      getters : {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
      },
}

export default login