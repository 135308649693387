var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('alert',{ref:"alert"}),_c('section',{staticClass:"section"},[_c('div',{staticClass:"sec-cst section-header bdr-20 mt-4"},[_c('router-link',{staticClass:"back-sec",attrs:{"to":{name:'administrator'}}},[_c('i',{staticClass:"uil uil-arrow-left"})]),_c('h1',{staticClass:"mt-0"},[_vm._v(" "+_vm._s(this.$route.meta.title)+" ")]),_c('div',{staticClass:"section-header-breadcrumb"},[_c('div',{staticClass:"button-modal text-right"},[_c('button',{staticClass:"btn btn-new",class:_vm.loading ? 'btn-progress' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.validationForm()}}},[_vm._v(" Save Data ")])])])],1),_c('div',{staticClass:"section-body mt-4"},[_c('ValidationObserver',{ref:"form",staticClass:"form-cst"},[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"card bdr-20"},[_c('div',{staticClass:"card-body"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name Administrator")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.name),expression:"formdata.name"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.username),expression:"formdata.username"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "username", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])}),(!_vm.formdata.id)?_c('validation-provider',{attrs:{"name":"confirm","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"control-label",attrs:{"for":"password"}},[_vm._v("Password")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.password),expression:"formdata.password"}],staticClass:"form-control",attrs:{"id":"password","type":"password","name":"password"},domProps:{"value":(_vm.formdata.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "password", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,1369685542)}):_vm._e(),(_vm.formdata.id)?_c('validation-provider',{attrs:{"name":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"control-label",attrs:{"for":"password"}},[_vm._v("Password")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.password),expression:"formdata.password"}],staticClass:"form-control",attrs:{"id":"password","type":"password","name":"password2"},domProps:{"value":(_vm.formdata.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "password", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,1549799988)}):_vm._e(),(_vm.formdata.password !== '')?_c('validation-provider',{attrs:{"rules":"required|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"control-label",attrs:{"for":"confirm"}},[_vm._v("Confirmation Password")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.confirmation),expression:"formdata.confirmation"}],staticClass:"form-control",attrs:{"id":"confirm","type":"password","name":"confirm"},domProps:{"value":(_vm.formdata.confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "confirmation", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,3566112356)}):_vm._e()],1)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card bdr-20"},[_c('div',{staticClass:"card-body"},[(!_vm.formdata.photo_url)?_c('validation-provider',{attrs:{"rules":"mimes:image/jpeg,image/png,image/jpg"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"mb-1"},[_c('label',[_vm._v("Administrator Photo")])]),_c('div',{staticClass:"btn-up d-flex align-items-center",on:{"click":function($event){$event.preventDefault();return _vm.$refs.inputImage.click()}}},[_c('div',{staticClass:"upload"},[_c('i',{staticClass:"uil uil-image mr-1"}),_vm._v(" ADD PHOTO")])]),_c('input',{ref:"inputImage",staticClass:"custom-file-input d-none",attrs:{"type":"file","id":"Thumbnail","accept":"image/jpeg,image/png,image/jpg"},on:{"change":_vm.onImageUpload,"input":validate}}),_c('div',{staticClass:"invalid-input mt-3"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,2175181079)}):_vm._e(),(_vm.formdata.photo_url)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"mb-1"},[_c('label',[_vm._v("Administrator Photo")])]),_c('button',{staticClass:"btn-remove-image",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.removeImageUpload()}}},[_c('i',{staticClass:"uil uil-times"}),_vm._v(" Change Photo")]),_c('div',{staticClass:"input-group img-sh"},[_c('img',{staticClass:"w-100 img-responsive img-prf-up",attrs:{"src":_vm.formdata.photo_url}})])]):_vm._e()],1)])])])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }