import $api from '../../utils/api.js'


const state = () => ({
    page: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
    },
    sorting: {
        num:10,
        search: '',
        offset: 4,
        status:'',
        user:'',
        sort:'desc',
        placeholder:'Search Page'
    },
    formdata:{
        id:'',
        title:'',
        content:'',
        short_desc:'',
        status:'',
        meta_keywords:'',
        meta_description:'',
        meta_title:'',
        image_cover_url:'',
    },
    errors:[],
})

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.page = payload
    },
    ASSIGN_FORM(state, payload) {
        state.formdata = payload
    },
    CLEAR_FORM(state) {
        state.formdata = {
            id:'',
            title:'',
            content:'',
            short_desc:'',
            status:'',
            meta_keywords:'',
            meta_description:'',
            meta_title:'',
            image_cover_url:'',
        },
        state.errors = []
    },
    CLEAR_CATEGORY(state){
        state.page = {
            total: 0,
            per_page: 2,
            from: 1,
            to: 0,
            current_page: 1
        },
        state.sorting =  {
            num:10,
            search: '',
            offset: 4,
            status:'',
            user:'',
            sort:'desc',
            placeholder:'Search Page'
        }
    },
    SET_ERRORS(state, payload) {
        state.errors = payload
    },
}

const actions = {
    getPage({ commit, state }) {
        return new Promise((resolve) => {
            $api.get(`page?page=${state.page.current_page}&paginate=${state.sorting.num}&search=${state.sorting.search}&sort=${state.sorting.sort}&user_id=${state.sorting.user}&status=${state.sorting.status}`)
            .then((response) => {
                commit('ASSIGN_DATA', response.data)
                resolve(response.data)
            })
        })
    },
    createPage({ commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`page`, payload)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    editPage({ commit }, payload) {
        return new Promise((resolve) => {
            $api.get(`page?id=${payload}`)
            .then((response) => {
                commit('ASSIGN_FORM', response.data)
                resolve(response.data)
            })
        })
    },
    updatePage({ state,commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`page/${state.formdata.id}`, payload.formData)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    deletePage({ dispatch }, payload) {
        return new Promise((resolve) => {
            $api.delete(`page/${payload}`)
            .then(() => {
                dispatch('getPage').then(() => resolve())
            })
        })
    },
    
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}