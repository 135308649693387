<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <router-link class="back-sec" :to="{name:'member'}">
                    <i class="uil uil-arrow-left"></i>
                </router-link>
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <button v-on:click="validationForm()" type="button" class="btn btn-new"  v-bind:class="loading ? 'btn-progress' : ''">
                            Save Data
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">
                
                <ValidationObserver ref="form" class="form-cst">

                    <div class="row mt-4">
                        <div class="col-md-8">
                            <div class="card bdr-20">
                                <div class="card-body">

                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Name</label>
                                            <input v-model="formdata.name" type="text" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <validation-provider rules="required|email" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input v-model="formdata.email" type="text" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                     <validation-provider name="confirm" rules="min:8" v-slot="{ errors }">
                                        <div class="form-group">
                                            <div class="d-block">
                                                <label for="password" class="control-label">Password</label>
                                            </div>
                                            <input id="password" type="password" v-model="formdata.password" class="form-control" name="password">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <validation-provider rules="required|password:@confirm" v-slot="{ errors }" v-if="formdata.password !== ''">
                                        <div class="form-group">
                                            <div class="d-block">
                                                <label for="confirm" class="control-label">Confirmation Password</label>
                                            </div>
                                            <input id="confirm" type="password" v-model="formdata.confirmation" class="form-control" name="confirm">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>
                                </div>
                            </div>  
                          
                        </div>
                    </div>
                
                </ValidationObserver>

            </div>
        </section>

    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver,extend} from "vee-validate";
    import { mapActions, mapState} from 'vuex'
    import Alert from "../../components/Alert.vue";
    
    extend('password', {
        params: ['target'],
        validate(value, { target }) {
            return value === target;
        },
        message: 'Confirmation Password Not Same'
    });

    export default {
        data(){
            return{
                loading:false,
                formdata:{
                    id:'',
                    name:'',
                    email:'',
                    password:'',
                    confirmation:'',
                },
            }
        },
        created(){
            this.editUserMember(this.$route.params.id).then((res) => {
                this.formdata = {
                    id:res.id,
                    name:res.name,
                    email:res.email,
                    password:'',
                    confirmation:'',
                }
            }) 
        },
        watch:{
            errors:function(val){
                if(val.email){
                    this.$refs.alert.showAlert('warning','email login you used already exists, please using another email')
                    this.loading = false
                }
            }
        },
        computed:{
            ...mapState('MemberControl', {
                errors: state => state.errors,
            }),
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            Alert,
        },
        methods:{
            ...mapActions('MemberControl', ['editUserMember','updateUserMember']),
            
            validationForm: function() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.form.errors)
                                .map(([key, value]) => ({ key, value }))
                                .filter(error => error["value"].length);
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100);
                    }else{
                        
                        this.loading = true

                        this.updateUserMember(this.formdata).then(() => {
                            this.loading = false
                            this.$refs.alert.showAlert('success','Successfully edited member user')
                            this.$refs.form.reset();
                            this.formdata.password = ''
                            this.formdata.confirmation = ''
                        }) 
                    }
                });
            },
        },
        beforeRouteLeave (to, from, next) {
            this.formdata ={
                id:'',
                name:'',
                email:'',
                password:'',
                confirmation:'',
            },
            next();
        }
    }

</script>