var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('alert',{ref:"alert"}),_c('section',{staticClass:"section"},[_c('div',{staticClass:"sec-cst section-header bdr-20 mt-4"},[_c('router-link',{staticClass:"back-sec",attrs:{"to":{name:'member'}}},[_c('i',{staticClass:"uil uil-arrow-left"})]),_c('h1',{staticClass:"mt-0"},[_vm._v(" "+_vm._s(this.$route.meta.title)+" ")]),_c('div',{staticClass:"section-header-breadcrumb"},[_c('div',{staticClass:"button-modal text-right"},[_c('button',{staticClass:"btn btn-new",class:_vm.loading ? 'btn-progress' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.validationForm()}}},[_vm._v(" Save Data ")])])])],1),_c('div',{staticClass:"section-body mt-4"},[_c('ValidationObserver',{ref:"form",staticClass:"form-cst"},[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"card bdr-20"},[_c('div',{staticClass:"card-body"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.name),expression:"formdata.name"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.email),expression:"formdata.email"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"name":"confirm","rules":"min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"control-label",attrs:{"for":"password"}},[_vm._v("Password")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.password),expression:"formdata.password"}],staticClass:"form-control",attrs:{"id":"password","type":"password","name":"password"},domProps:{"value":(_vm.formdata.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "password", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])}),(_vm.formdata.password !== '')?_c('validation-provider',{attrs:{"rules":"required|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-block"},[_c('label',{staticClass:"control-label",attrs:{"for":"confirm"}},[_vm._v("Confirmation Password")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.confirmation),expression:"formdata.confirmation"}],staticClass:"form-control",attrs:{"id":"confirm","type":"password","name":"confirm"},domProps:{"value":(_vm.formdata.confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "confirmation", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,3566112356)}):_vm._e()],1)])])])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }