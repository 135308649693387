<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <h1 class="mt-0 py-2">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <button  v-bind:class="loading_export ? 'btn-progress' : ''" class="btn btn-new2" @click="_exportPayment()"><i class="uil uil-file-export mr-2"></i> Export Excel</button>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">
                <div class="row mt-4">
                    <div class="col-12 text-center mt-3 hv-100" v-if="no_data === null">
                        <div class="half-circle-spinner">
                            <div class="circle circle-1"></div>
                            <div class="circle circle-2"></div>
                        </div>
                    </div>
                    <div class="col-12" v-if="no_data === false">

                        <div class="card bdr-20">
                            <div class="card-body">
                                <div class="statistic-details mt-sm-4">
                                    <div class="statistic-details-item">
                                        <span class="text-muted"><span class="text-primary"><i class="uil uil-chart"></i></span> ALL STATUS </span>
                                        <div class="detail-value">{{payment | countItemByStatus('ALL')}}</div>
                                        <div class="detail-name">Total Payment List</div>
                                    </div>
                                    <div class="statistic-details-item">
                                        <span class="text-muted"><span class="text-primary"><i class="uil uil-chart"></i></span> PAID</span>
                                        <div class="detail-value">{{payment | countItemByStatus('PAID')}}</div>
                                        <div class="detail-name">Total Payment List</div>
                                    </div>
                                    <div class="statistic-details-item">
                                        <span class="text-muted"><span class="text-primary"><i class="uil uil-chart"></i></span> UNPAID</span>
                                        <div class="detail-value">{{payment | countItemByStatus('UNPAID')}}</div>
                                        <div class="detail-name">Total Payment List</div>
                                    </div>
                                    <div class="statistic-details-item">
                                        <span class="text-muted"><span class="text-primary"><i class="uil uil-chart"></i></span>EXPIRED</span>
                                        <div class="detail-value">{{payment | countItemByStatus('EXPIRED')}}</div>
                                        <div class="detail-name">Total Payment List</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card bdr-20">
                            <div class="card-header border-0 py-4 tb-card">
                                <div class="table-sorting">
                                    <div class="table-search tb-sc-payment">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group mb-0">
                                                    <input :placeholder="sorting.placeholder" @keyup="searchPayment()" v-model="sorting.search" type="text" class="form-control" >
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group mb-0">
                                                    <select class="form-control" v-model="sorting.package" @change="getPayment">
                                                        <option selected value="">Choose Package</option>
                                                        <option v-for="data in pck" :key="data.id" :value="data.id">{{data.category_name}} - {{data.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <v-select @change="getPayment" :clearable="true" placeholder="Choose Member" label="organization_name" :reduce="member => member.id" v-model="sorting.member" :options="member"></v-select>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group mb-0">
                                                    <select class="form-control" v-model="sorting.status" @change="getPayment">
                                                        <option selected value="">Choose Status</option>
                                                        <option value="PAID">PAID</option>
                                                        <option value="UNPAID">UNPAID</option>
                                                        <option value="EXPIRED">EXPIRED</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body px-0 pb-0">
                                <div class="table-responsive">
                                    <table class="table tb-payment">
                                        <thead>
                                            <tr>
                                                <th>Invoice</th>
                                                <th>External ID</th>
                                                <th>Member</th>
                                                <th>Package</th>
                                                <th>Subs. Date</th>
                                                <th>Due Date</th>
                                                <th>Amount</th>
                                                <th>Fee</th>
                                                <th>Fee Fixed</th>
                                                <th>VAT 10%</th>
                                                <th>Amount Total</th>
                                                <th>Payment Method</th>
                                                <th>Status</th>
                                                <th>Paid At</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="data in payment" :key="data.id">
                                                <td>#{{data.inv_number}}</td>
                                                <td>{{data.external_id}}</td>
                                                <td>{{data.member_name}}</td>
                                                <td>{{data.packages.category_name}} - {{data.packages.name}}</td>
                                                <td>{{data.subscribe_start  |  formatDate('MMMM DD, YYYY')}} - {{data.subscribe_end  |  formatDate('MMMM DD, YYYY')}}</td>
                                                <td>{{data.inv_due_date  |  formatDate('MMMM DD, YYYY HH:MM')}}</td>
                                                <td>{{data.amount | formatRupiah}}</td>
                                                <td>{{data.payment_fee | formatRupiah}}</td>
                                                <td>{{data.payment_fee_fixed | formatRupiah}}</td>
                                                <td>{{data.payment_vat | formatRupiah}}</td>
                                                <td>{{data.payment_total | formatRupiah}}</td>
                                                <td>{{data.payment_type}} - {{data.payment_method}}</td>
                                                <td>
                                                    <label v-if="data.status === 'PAID'" class="badge badge-success">{{data.status}}</label>
                                                    <label v-if="data.status === 'UNPAID'"  class="badge badge-warning">{{data.status}}</label>
                                                    <label v-if="data.status === 'EXPIRED'"  class="badge badge-danger">{{data.status}}</label>
                                                </td>
                                                <td>{{data.paid_at  |  formatDate('MMMM DD, YYYY HH:MM')}}</td>
                                                <td width="20px" @click.stop="">
                                                    <b-dropdown>
                                                        <template #button-content>
                                                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"> <g> <g> <g> <circle cx="256" cy="256" r="64"/> <circle cx="256" cy="448" r="64"/> <circle cx="256" cy="64" r="64"/> </g> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                                                        </template>
                                                        <b-dropdown-item  v-on:click="_exportInvioce(data)" href="#"><i class="uil uil-file-download-alt"></i> Download Invioce</b-dropdown-item>
                                                    </b-dropdown>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="text-right font-weight-bold" colspan="6">Total</th>
                                                <td class="font-weight-bold">{{payment | totalItem('amount') | formatRupiah}}</td>
                                                <td class="font-weight-bold">{{payment | totalItem('payment_fee') | formatRupiah}}</td>
                                                <td class="font-weight-bold">{{payment | totalItem('payment_fee_fixed') | formatRupiah}}</td>
                                                <td class="font-weight-bold">{{payment | totalItem('payment_vat') | formatRupiah}}</td>
                                                <td class="font-weight-bold">{{payment | totalItem('payment_total') | formatRupiah}}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        
                                        </tbody>
                                        
                                    </table>
                                    <p v-if="payment.length === 0" class="text-center no-data">Payment data not found</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" v-if="no_data === true && sorting.search === ''">
                        <div class="card bdr-20 py-5">
                            <div class="card-body card-nitem text-center py-5">
                                <img class="mb-4" width="150px" src="@/assets/img/no-data.svg" alt="">
                                <div class="nitem-desc">
                                    <h3>Payment Data Still Empty</h3>
                                    <p>On this page you can check payment member</p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </section>

        <b-modal id="modal-Alert" hide-footer hide-header centered>
            <div class="text-center pt-2 pb-3 px-3">
                <i style="font-size: 60px; color: #f8d735;" class="uil uil-exclamation-triangle"></i>
                <h3 class="mt-1">Confirmation</h3>
                <p style="font-size:16px;">Are you sure to delete this data ? data that has been deleted cannot be returned</p>
                <div class="btn-alert mt-4">
                    <button @click="_deletePayment()" v-bind:class="loading ? 'btn-progress' : ''" class="btn btn-danger mr-2">Sure</button>
                    <button @click="$bvModal.hide('modal-Alert')" class="btn btn-secondary">Cancel</button>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import { mapActions, mapState} from 'vuex'
    import Alert from "../../components/Alert.vue";
    import moment from 'moment'
    import vSelect from 'vue-select'

    export default {
        components: {
            Alert,
            vSelect
        },
        data(){
            return {
                no_data: null,
                loading:false,
                loading_export:false,
            }
        },
        mounted(){
            this._getPayment()
            this.selectPackage()
            this.selectMember()
        },
        filters: {
            countPriceByStatus:function(val,status){
                var total = 0;

                if(status == 'ALL'){
                    val.forEach(data => {
                        total = total + parseFloat(data.amount)
                    });
                }else{
                    val.forEach(data => {
                        if(data.status == status){
                            total = total + parseFloat(data.amount)
                        }
                    });
                }
            
                return total
            },
            totalItem: function(all_data,key){
                var total = 0;
                all_data.forEach(data => {
                    total = total + parseFloat(data[key])
                });
                return total
            },
            countItemByStatus:function(val,status){
                var total = 0;

                if(status == 'ALL'){
                    total = val.length
                }else{
                    val.forEach(data => {
                        if(data.status == status){
                            total = total + 1
                        }
                    });
                }
            
                return total
            },
            formatDate: function(val,format){
                if(val){
                    return moment(String(val)).format(format)
                }else{
                    return '-'
                }
            },
            formatRupiah: function(value){
                if(value){
                    let val = (value/1).toFixed(0).replace('.', ',')
                    return "Rp. " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }else{
                    return '-'
                }
               
            },
        },
        computed:{
            ...mapState('PaymentControl', {
                payment: state => state.payment,
                sorting: state => state.sorting,
                errors: state => state.errors,
            }),
            ...mapState('SelectControl', {
                pck: state => state.package,
                member: state => state.member,
            }),

        },
        methods: {
            ...mapActions('PaymentControl', ['getPayment','deletePayment','exportPayment','exportInvioce']),
            ...mapActions('SelectControl', ['selectPackage','selectMember']),
            
            _exportPayment() {
                this.loading_export = true
                this.exportPayment().then(response => {
                    let blob = new Blob([response], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Payments Member - ITS Indonesia.xlsx'
                    link.click();
                    blob = null;
                    this.loading_export = false
                })
            },
            _exportInvioce:function(data){
                this.exportInvioce(data.id).then(response => {
                    let blob = new Blob([response], {type:'application/pdf'})
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Invoice - ' + data.member_name  +' - '+ data.inv_number + '.pdf'
                    link.click();
                    blob = null;
                })
            },
            searchPayment: function(){
                clearTimeout(this.timeout);
                
                var self = this;
                this.timeout = setTimeout(function () {
                    self.getPayment()
                }, 500);
            },
            _getPayment:function(){
                this.getPayment().then(() => {
                    if(this.payment.length == 0){
                        this.no_data = true
                    }else{
                        this.no_data = false
                    }
                })
            },
        }
    }

</script>