import Vue from "vue";
import store from '../store';
import VueRouter from "vue-router";

// LAYOUT
import Header from '@/components/layouts/Header'
import Sidebar from '@/components/layouts/Sidebar'
import Footer from '@/components/layouts/Footer'

//MENU 
import NotFound from "../views/error/404.vue"
import Login from "../views/auth/Login.vue"

import Dashboard from "../views/dashboard/Dashboard.vue"

import Settings from "../views/settings/Settings.vue"
import FormSettings from "../views/settings/FormSettings.vue"

import Admin from "../views/admin/Admin.vue"
import FormAdmin from "../views/admin/FormAdmin.vue"

import Page from "../views/page/Page.vue"
import FormPage from "../views/page/FormPage.vue"

import Tag from "../views/post/Tag.vue"
import FormTag from "../views/post/FormTag.vue"

import Category from "../views/post/Category.vue"
import FormCategory from "../views/post/FormCategory.vue"

import News from "../views/post/News.vue"
import FormNews from "../views/post/FormNews.vue"

import Events from "../views/post/Event.vue"
import FormEvents from "../views/post/FormEvent.vue"

import Program from "../views/post/Program.vue"
import FormProgram from "../views/post/FormProgram.vue"

import Knowledge from "../views/post/Knowledge.vue"
import FormKnowledge from "../views/post/FormKnowledge.vue"

import Team from "../views/team/Team.vue"
import FormTeam from "../views/team/FormTeam.vue"

import Menu from "../views/menu/Menu.vue"
import FormMenu from "../views/menu/FormMenu.vue"

import PopUp from "../views/popup/Popup.vue"
import FormPopUp from "../views/popup/FormPopup.vue"

import Slider from "../views/slider/Slider.vue"
import FormSlider from "../views/slider/FormSlider.vue"

import Newsletter from "../views/newsletter/Newsletter.vue"
import FormNewsletter from "../views/newsletter/FormNewsletter.vue"
import Message from "../views/newsletter/Message.vue"

import CategoryPackage from "../views/package/Category.vue"
import FormCategoryPackage from "../views/package/FormCategory.vue"
import Package from "../views/package/Package.vue"
import FormPackage from "../views/package/FormPackage.vue"

import Member from "../views/member/Member.vue"
import FormMember from "../views/member/FormMember.vue"
import ViewMember from "../views/member/ViewMember.vue"
import FormMemberUser from "../views/member/FormMemberUser.vue"

import Payment from "../views/payment/Payment.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/login',
  },
  {
    path: "/login",
    name: "login",
    components: {content:Login},
    meta: {
      requiresAuth: false,
      title: 'Login'
    }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    components: {header:Header, sidebar:Sidebar, content:Dashboard, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Dashboard'
    }
  },
   //SETTINGS
   {
    path: "/settings",
    name: "settings",
    components: {header:Header, sidebar:Sidebar, content:Settings, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Settings'
    }
  },
  {
    path: "/settings/add",
    name: "settings-add",
    components: {header:Header, sidebar:Sidebar, content:FormSettings, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Add Settings'
    }
  },
  {
    path: "/settings/edit/:id",
    name: "settings-edit",
    components: {header:Header, sidebar:Sidebar, content:FormSettings, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Edit Settings'
    }
  },
  //ADMIN
  {
    path: "/administrator",
    name: "administrator",
    components: {header:Header, sidebar:Sidebar, content:Admin, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Administrator'
    }
  },
  {
    path: "/administrator/add",
    name: "administrator-add",
    components: {header:Header, sidebar:Sidebar, content:FormAdmin, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Add Administrator'
    }
  },
  {
    path: "/administrator/edit/:id",
    name: "administrator-edit",
    components: {header:Header, sidebar:Sidebar, content:FormAdmin, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Edit Administrator'
    }
  },
  //PAGES
  {
    path: "/pages",
    name: "pages",
    components: {header:Header, sidebar:Sidebar, content:Page, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Pages'
    }
  },
  {
    path: "/pages/add",
    name: "pages-add",
    components: {header:Header, sidebar:Sidebar, content:FormPage, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Add Pages'
    }
  },
  {
    path: "/pages/edit/:id",
    name: "pages-edit",
    components: {header:Header, sidebar:Sidebar, content:FormPage, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Edit Pages'
    }
  },
  //CATEGORY POST
  {
    path: "/category/:type",
    name: "category",
    components: {header:Header, sidebar:Sidebar, content:Category, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Category'
    }
  },
  {
    path: "/category/add/:type",
    name: "category-add",
    components: {header:Header, sidebar:Sidebar, content:FormCategory, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Add Category'
    }
  },
  {
    path: "/category/edit/:type/:id",
    name: "category-edit",
    components: {header:Header, sidebar:Sidebar, content:FormCategory, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Edit Category'
    }
  },
  //TAG POST
  {
    path: "/tag/:type",
    name: "tag",
    components: {header:Header, sidebar:Sidebar, content:Tag, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Tag'
    }
  },
  {
    path: "/tag/add/:type",
    name: "tag-add",
    components: {header:Header, sidebar:Sidebar, content:FormTag, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Add Tag'
    }
  },
  {
    path: "/tag/edit/:type/:id",
    name: "tag-edit",
    components: {header:Header, sidebar:Sidebar, content:FormTag, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Edit Tag'
    }
  },
  //NEWS 
  {
    path: "/news",
    name: "news",
    components: {header:Header, sidebar:Sidebar, content:News, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'News'
    }
  },
  {
    path: "/news/add",
    name: "news-add",
    components: {header:Header, sidebar:Sidebar, content:FormNews, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Add News'
    }
  },
  {
    path: "/news/edit/:id",
    name: "news-edit",
    components: {header:Header, sidebar:Sidebar, content:FormNews, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Edit News'
    }
  },
  //EVENTS
  {
    path: "/events",
    name: "events",
    components: {header:Header, sidebar:Sidebar, content:Events, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Events'
    }
  },
  {
    path: "/event/add",
    name: "event-add",
    components: {header:Header, sidebar:Sidebar, content:FormEvents, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Add Event'
    }
  },
  {
    path: "/event/edit/:id",
    name: "event-edit",
    components: {header:Header, sidebar:Sidebar, content:FormEvents, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Edit Event'
    }
  },
  //PROGRAM
  {
    path: "/program",
    name: "program",
    components: {header:Header, sidebar:Sidebar, content:Program, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Program'
    }
  },
  {
    path: "/program/add",
    name: "program-add",
    components: {header:Header, sidebar:Sidebar, content:FormProgram, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Add Program'
    }
  },
  {
    path: "/program/edit/:id",
    name: "program-edit",
    components: {header:Header, sidebar:Sidebar, content:FormProgram, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Edit Program'
    }
  },
  //TEAM
  {
    path: "/teams",
    name: "teams",
    components: {header:Header, sidebar:Sidebar, content:Team, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Teams'
    }
  },
  {
    path: "/teams/add",
    name: "teams-add",
    components: {header:Header, sidebar:Sidebar, content:FormTeam, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Add Team'
    }
  },
  {
    path: "/teams/edit/:id",
    name: "teams-edit",
    components: {header:Header, sidebar:Sidebar, content:FormTeam, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Edit Team'
    }
  },
  //MENU
  {
    path: "/menu",
    name: "menu",
    components: {header:Header, sidebar:Sidebar, content:Menu, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Menu'
    }
  },
  {
    path: "/menu/add",
    name: "menu-add",
    components: {header:Header, sidebar:Sidebar, content:FormMenu, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Add Menu'
    }
  },
  {
    path: "/menu/edit/:id",
    name: "menu-edit",
    components: {header:Header, sidebar:Sidebar, content:FormMenu, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Edit Menu'
    }
  },
  //POP UP
  {
    path: "/popup",
    name: "popup",
    components: {header:Header, sidebar:Sidebar, content:PopUp, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Pop Up'
    }
  },
  {
    path: "/popup/add",
    name: "popup-add",
    components: {header:Header, sidebar:Sidebar, content:FormPopUp, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Add Pop Up'
    }
  },
  {
    path: "/popup/edit/:id",
    name: "popup-edit",
    components: {header:Header, sidebar:Sidebar, content:FormPopUp, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Edit Pop Up'
    }
  },
  //SLIDE
  {
    path: "/slider",
    name: "slider",
    components: {header:Header, sidebar:Sidebar, content:Slider, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Slider'
    }
  },
  {
    path: "/slider/add",
    name: "slider-add",
    components: {header:Header, sidebar:Sidebar, content:FormSlider, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Add Slider'
    }
  },
  {
    path: "/slider/edit/:id",
    name: "slider-edit",
    components: {header:Header, sidebar:Sidebar, content:FormSlider, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Edit Slider'
    }
  },
  //NEWSLETTER
  {
    path: "/subscriber",
    name: "subscriber",
    components: {header:Header, sidebar:Sidebar, content:Newsletter, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Subscriber'
    }
  },
  {
    path: "/subscriber/add",
    name: "subscriber-add",
    components: {header:Header, sidebar:Sidebar, content:FormNewsletter, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Add Subscriber'
    }
  },
  {
    path: "/subscriber/edit/:id",
    name: "subscriber-edit",
    components: {header:Header, sidebar:Sidebar, content:FormNewsletter, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Edit Subscriber'
    }
  },
  //MESSAGE
  {
    path: "/message",
    name: "message",
    components: {header:Header, sidebar:Sidebar, content:Message, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Message'
    }
  },
  // CATEGORY PACKAGE
  {
    path: "/category-package",
    name: "category-package",
    components: {header:Header, sidebar:Sidebar, content:CategoryPackage, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Category Package'
    }
  },
  {
    path: "/category-package/add",
    name: "category-package-add",
    components: {header:Header, sidebar:Sidebar, content:FormCategoryPackage, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Add Category Package'
    }
  },
  {
    path: "/category-package/edit/:id",
    name: "category-package-edit",
    components: {header:Header, sidebar:Sidebar, content:FormCategoryPackage, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Edit Category Package'
    }
  },
  //PACKAGE
  {
    path: "/package",
    name: "package",
    components: {header:Header, sidebar:Sidebar, content:Package, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Package'
    }
  },
  {
    path: "/package/add",
    name: "package-add",
    components: {header:Header, sidebar:Sidebar, content:FormPackage, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Add Package'
    }
  },
  {
    path: "/package/edit/:id",
    name: "package-edit",
    components: {header:Header, sidebar:Sidebar, content:FormPackage, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Edit Package'
    }
  },
  //MEMBER
  {
    path: "/member",
    name: "member",
    components: {header:Header, sidebar:Sidebar, content:Member, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Member'
    }
  },
  {
    path: "/member/add",
    name: "member-add",
    components: {header:Header, sidebar:Sidebar, content:FormMember, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Add Member'
    }
  },
  {
    path: "/member/edit/:id",
    name: "member-edit",
    components: {header:Header, sidebar:Sidebar, content:FormMember, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Edit Member'
    }
  },
  {
    path: "/member/view/:id",
    name: "member-view",
    components: {header:Header, sidebar:Sidebar, content:ViewMember, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'View Member'
    }
  },
  {
    path: "/member/user/:id",
    name: "member-user",
    components: {header:Header, sidebar:Sidebar, content:FormMemberUser, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Edit User Member'
    }
  },
  //MEMBER PAYMENT
  {
    path: "/member-payment",
    name: "member-payment",
    components: {header:Header, sidebar:Sidebar, content:Payment, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Member Payment'
    }
  },
  //KNOWLEDGE CENTER 
  {
    path: "/knowledge",
    name: "knowledge",
    components: {header:Header, sidebar:Sidebar, content:Knowledge, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Knowledge Center'
    }
  },
  {
    path: "/knowledge/add",
    name: "knowledge-add",
    components: {header:Header, sidebar:Sidebar, content:FormKnowledge, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Add Knowledge Center'
    }
  },
  {
    path: "/knowledge/edit/:id",
    name: "knowledge-edit",
    components: {header:Header, sidebar:Sidebar, content:FormKnowledge, footer:Footer},
    meta: {
      requiresAuth: true,
      level:1,
      title: 'Edit Knowledge Center'
    }
  },
  {
    path: '*',
    name: '404',
    components: {header:Header, sidebar:Sidebar, content:NotFound, footer:Footer},
    meta: {
      requiresAuth: true,
      title: 'Not Found'
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " - ITS Indonesia"
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['AuthControl/isLoggedIn']) {
      next()
      return
    }
    next('/login') 

  }else {
    if (store.getters['AuthControl/isLoggedIn']) {
      next('/dashboard') 
      return
    }
    next() 
  }
})

export default router;

