<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <router-link class="back-sec" :to="{name:'settings'}">
                    <i class="uil uil-arrow-left"></i>
                </router-link>
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <button v-on:click="validationForm()" type="button" class="btn btn-new"  v-bind:class="loading ? 'btn-progress' : ''">
                            Save Data
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">
                
                <ValidationObserver ref="form" class="form-cst">

                    <div class="row mt-4">
                            <div class="col-md-8">
                                <div class="card bdr-20">
                                    <div class="card-body">
                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Setting Option Name</label>
                                                <input disabled v-model="formdata.display_name" type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Setting Option Key</label>
                                                <input disabled v-model="formdata.key" type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <template v-if="formdata.type === 'textarea'"> 
                                            <div class="form-group">
                                                <label>Settings Value</label>
                                                <editor
                                                v-model="formdata.value"
                                                :api-key="api_tiny"
                                                :init="EditorOptions"
                                            />
                                            </div>
                                        </template>

                                        <template v-if="formdata.type === 'text'"> 
                                            <div class="form-group">
                                                <label>Setting Value</label>
                                                <input v-model="formdata.value" type="text" class="form-control" name="name">
                                            </div>
                                        </template>

                                        <template v-if="formdata.type === 'url'"> 
                                            <validation-provider rules="url" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <label>Setting Value</label>
                                                    <input v-model="formdata.value" type="text" class="form-control" name="name">
                                                    <div class="invalid-input">{{ errors[0] }}</div>
                                                </div>
                                            </validation-provider>
                                        </template>

                                        <template v-if="formdata.type === 'image'"> 

                                            <validation-provider v-if="!formdata.image_url" rules="mimes:image/jpeg,image/png,image/jpg" v-slot="{ errors, validate }">
                                                <div class="form-group">
                                                    <div class="mb-1">
                                                        <label>Setting Value</label>
                                                    </div>
                                                    <div class="btn-up d-flex align-items-center" v-on:click.prevent="$refs.inputImage.click()">
                                                        <div class="upload"><i class="uil uil-image mr-1"></i> ADD PHOTO</div>
                                                    </div>
                                                    <input ref="inputImage" 
                                                            type="file"
                                                            class="custom-file-input d-none"
                                                            id="Thumbnail"
                                                            v-on:change="onImageUpload"
                                                            accept="image/jpeg,image/png,image/jpg" 
                                                            @input="validate" >
                                
                                                    <div class="invalid-input mt-3">{{ errors[0] }}</div>
                                                </div>
                                            </validation-provider>
                                            
                                            <div class="form-group" v-if="formdata.image_url">   
                                                <div class="mb-1">
                                                    <label>Setting Value</label>
                                                </div> 
                                                <button v-on:click.prevent="removeImageUpload()" type="button" class="btn-remove-image"><i class="uil uil-times"></i> Change Photo</button> 
                                                <div class="input-group img-sh">
                                                    <img :src="formdata.image_url" class="w-100 img-responsive img-prf-up">
                                                </div>
                                            </div>

                                        </template>

                                         <template v-if="formdata.type === 'video'"> 

                                            <validation-provider v-if="!formdata.image_url" rules="mimes:video/mp4" v-slot="{ errors, validate }">
                                                <div class="form-group">
                                                    <div class="mb-1">
                                                        <label>Setting Value</label>
                                                    </div>
                                                    <div class="btn-up d-flex align-items-center" v-on:click.prevent="$refs.inputImage.click()">
                                                        <div class="upload"><i class="uil uil-image mr-1"></i> ADD PHOTO</div>
                                                    </div>
                                                    <input ref="inputImage" 
                                                            type="file"
                                                            class="custom-file-input d-none"
                                                            id="Thumbnail"
                                                            v-on:change="onImageUpload"
                                                            accept="video/mp4" 
                                                            @input="validate" >
                                
                                                    <div class="invalid-input mt-3">{{ errors[0] }}</div>
                                                </div>
                                            </validation-provider>
                                            
                                            <div class="form-group" v-if="formdata.image_url">   
                                                <div class="mb-1">
                                                    <label>Setting Value</label>
                                                </div> 
                                                <button v-on:click.prevent="removeImageUpload()" type="button" class="btn-remove-image"><i class="uil uil-times"></i> Change Photo</button> 
                                                <div class="input-group img-sh">
                                                    <img :src="formdata.image_url" class="w-100 img-responsive img-prf-up">
                                                </div>
                                            </div>

                                        </template>
                                 
                                    </div>
                                </div>  
                            </div>
                            <div class="col-md-4">
                                <div class="card bdr-20">
                                    <div class="card-body">
                                        
                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Setting Group</label>
                                                <select class="form-control" v-model="formdata.group" disabled>
                                                    <option></option>
                                                    <option value="social">SOCIAL MEDIA</option>
                                                    <option value="contact">CONTACT</option>
                                                    <option value="web">WEBSITE</option>
                                                </select>
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Setting Type</label>
                                                <select class="form-control" v-model="formdata.type" disabled>
                                                    <option></option>
                                                    <option value="text">TEXT</option>
                                                    <option value="url">URL</option>
                                                    <option value="textarea">TEXTAREA</option>
                                                    <option value="image">IMAGE</option>
                                                    <option value="video">VIDEO</option>
                                                </select>
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        
                                        <div class="form-group">
                                            <label>Setting Function Description</label>
                                            <textarea disabled v-model="formdata.details" class="form-control" name="name"></textarea>
                                        </div>

                                       
                                    </div>
                                </div>
                              
                            </div>
                    </div>
                
                </ValidationObserver>

            </div>
        </section>

    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import { mapState, mapActions, mapMutations} from 'vuex'
    import Alert from "../../components/Alert.vue";
    import options from "../../utils/editor";
    import Editor from '@tinymce/tinymce-vue'

    export default {
        data(){
            return{
                api_tiny:process.env.VUE_APP_TINYKEY,
                photo_file:'',
                loading:false,
                EditorOptions: options.Options
            }
        },
        mounted(){
            if(this.$route.name == 'settings-edit' && this.formdata.id == ''){
                this.editSettings(this.$route.params.id)
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            Alert,
            Editor
        },
        computed:{
            ...mapState('SettingsControl', {
                formdata: state => state.formdata,
            }),
        },
        methods:{
            ...mapActions('SettingsControl', ['updateSettings','editSettings']),
            ...mapMutations('SettingsControl', ['CLEAR_FORM']),
            
            onImageUpload: function(event) {
                var input = event.target;

                if (input.files && input.files[0]) {

                    var reader = new FileReader();
                    var vm = this;
                    reader.onload = function(e) {
                        vm.formdata.image_url = e.target.result;
                    }
                    reader.readAsDataURL(input.files[0]);
                }
                this.photo_file = input.files[0]
            },
            removeImageUpload:function(){
                this.photo_file = ''
                this.formdata.image_url = ''
            },
            validationForm: function() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.form.errors)
                                .map(([key, value]) => ({ key, value }))
                                .filter(error => error["value"].length);
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100);
                    }else{

                        this.loading = true

                        var formData = new FormData();
                        formData.append('value', this.formdata.value);
                        formData.append('type', this.formdata.type);

                        if(this.photo_file){
                            formData.append('image', this.photo_file);
                        }
                        formData.append("_method", "put");

                        this.updateSettings({id:this.$route.params.id,formData:formData}).then(() => {
                            this.loading = false
                            this.$refs.alert.showAlert('success','Successfully edited settings')
                            this.$refs.form.reset();
                            this.photo_file = ''
                        }) 
                       
                    }
                });
            },
        },
        beforeRouteLeave (to, from, next) {
            this.CLEAR_FORM()
            next();
        }
    }

</script>