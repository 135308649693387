<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <router-link class="back-sec" :to="{name:'knowledge'}">
                    <i class="uil uil-arrow-left"></i>
                </router-link>
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <button v-on:click="validationForm()" type="button" class="btn btn-new"  v-bind:class="loading ? 'btn-progress' : ''">
                            Save Data
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">

                <ValidationObserver ref="form" class="form-cst">

                    <div class="row mt-4">
                        <div class="col-md-8">
                            <div class="card bdr-20">
                                <div class="card-body">
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Knowledge Title</label>
                                            <input v-model="formdata.title" type="text" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Knowledge Status</label>
                                            <select class="form-control" v-model="formdata.status">
                                                <option></option>
                                                <option value="DRAFT">DRAFT</option>
                                                <option value="PUBLISHED">PUBLISHED</option>
                                            </select>
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Knowledge Content</label>
                                            <editor
                                            v-model="formdata.content"
                                            :api-key="api_tiny"
                                            :init="EditorOptions"
                                        />
                                        <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <div class="form-group">
                                        <label>Knowledge Excerpt</label>
                                        <textarea v-model="formdata.short_desc" class="form-control" name="name"></textarea>
                                    </div>


                                </div>
                            </div>

                            <div class="card bdr-20">
                                <div class="card-header">
                                    <h4>Knowledge File</h4>
                                    <div class="card-header-action">
                                        <button v-on:click.prevent="$refs.inputFileKnowledge.click()" class="btn btn-primary"><i class="uil uil-file-plus-alt"></i> Add File</button>
                                        <input ref="inputFileKnowledge"
                                        type="file"
                                        class="custom-file-input d-none"
                                        id="Thumbnail"
                                        multiple
                                        v-on:change="addFileKnowledge"
                                        accept="image/jpeg,image/png,image/jpg,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,video/mp4">
                                        <button v-b-modal.modal-Link class="ml-2 btn btn-success"><i class="uil uil-link"></i> Add Link</button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <ul class="list-unstyled list-unstyled-border">
                                        <li class="media" v-for="(data,index) in formdata.files" :key="data.id">
                                            <template v-if="data.type === 'FILE'">
                                                <div class="media-icon media-kn">
                                                    <i class="uil uil-file w-100"></i>
                                                </div>
                                                <div class="media-body">
                                                    <div class="media-right">
                                                        <button @click.prevent="deleteFileKnowledge(index,data.id)" class="btn btn-danger"><i class="ic-big uil uil-trash"></i></button>
                                                    </div>
                                                    <div class="media-title">{{data.name}}</div>
                                                    <div class="text-muted text-small">{{data.size | formatSize}}</div>
                                                </div>
                                            </template>
                                            <template v-if="data.type === 'LINK'">
                                                <div class="media-icon media-kn">
                                                    <i class="uil uil-link w-100"></i>
                                                </div>
                                                <div class="media-body">
                                                    <div class="media-right">
                                                        <button @click.prevent="deleteFileKnowledge(index,data.id)" class="btn btn-danger"><i class="ic-big uil uil-trash"></i></button>
                                                    </div>
                                                    <div class="media-title">{{ $route.name === 'knowledge-edit' ? data.link_name : data.name}}</div>
                                                    <div class="text-muted text-small">{{$route.name === 'knowledge-edit' ? data.link : data.file}}</div>
                                                </div>
                                            </template>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card bdr-20">
                                <div class="card-body">
                                    <validation-provider v-if="!formdata.image_cover_url" rules="mimes:image/jpeg,image/png,image/jpg" v-slot="{ errors, validate }">
                                        <div class="form-group">
                                            <div class="mb-1">
                                                <label>Knowledge Image Cover</label>
                                            </div>
                                            <div class="btn-up d-flex align-items-center" v-on:click.prevent="$refs.inputImage.click()">
                                                <div class="upload"><i class="uil uil-image mr-1"></i> ADD PHOTO</div>
                                            </div>
                                            <input ref="inputImage"
                                                    type="file"
                                                    class="custom-file-input d-none"
                                                    id="Thumbnail"
                                                    v-on:change="onImageUpload"
                                                    accept="image/jpeg,image/png,image/jpg"
                                                    @input="validate" >

                                            <div class="invalid-input mt-3">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <div class="form-group" v-if="formdata.image_cover_url">
                                        <div class="mb-1">
                                            <label>Knowledge Image Cover</label>
                                        </div>
                                        <button v-on:click.prevent="removeImageUpload()" type="button" class="btn-remove-image"><i class="uil uil-times"></i> Change Photo</button>
                                        <div class="input-group img-sh">
                                            <img :src="formdata.image_cover_url" class="w-100 img-responsive img-prf-up">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card bdr-20">
                                <div class="card-body">
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Knowledge Category</label>
                                            <v-select :clearable="false" label="name" :reduce="catagory => catagory.id" v-model="formdata.category_id" :options="category"></v-select>
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>
                                </div>
                            </div>
                        </div>
                    </div>

                </ValidationObserver>

            </div>
        </section>

        <b-modal id="modal-Link" title="Add Link" hide-footer>
            <ValidationObserver ref="formlink" class="form-cst">
                <validation-provider rules="required" v-slot="{ errors }">
                    <div class="form-group">
                        <label>Link Title</label>
                        <input v-model="formlink.name" type="text" class="form-control" name="name">
                        <div class="invalid-input">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
                <validation-provider rules="required|url" v-slot="{ errors }">
                    <div class="form-group">
                        <label>Link</label>
                        <input v-model="formlink.url" @keydown.space.prevent type="text" class="form-control" name="name">
                        <div class="invalid-input">{{ errors[0] }}</div>
                    </div>
                </validation-provider>
            </ValidationObserver>

            <button v-on:click="addLink()" type="button" class="btn btn-new">
                Add Link
            </button>
        </b-modal>
    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import vSelect from 'vue-select'
    import { mapState, mapActions, mapMutations} from 'vuex'
    import Alert from "../../components/Alert.vue";
    import options from "../../utils/editor";
    import Editor from '@tinymce/tinymce-vue'

    export default {
        data(){
            return{
                api_tiny:process.env.VUE_APP_TINYKEY,
                photo_file:'',
                loading:false,
                EditorOptions: options.Options,
                knowledge_del:[],
                knowledge_file:[],

                formlink:{
                    name:'',
                    url:'',
                },
            }
        },
        mounted(){
            if(this.$route.name == 'knowledge-edit' && this.formdata.id == ''){
                this.editPost(this.$route.params.id)
            }
            this.selectCategory('KNOWLEDGE')
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            vSelect,
            Alert,
            Editor
        },
        filters: {
            formatSize: function(num){
                if (typeof num !== 'number' || isNaN(num)) {
                throw new TypeError('Expected a number');
                }

                var exponent;
                var unit;
                var neg = num < 0;
                var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                if (neg) {
                num = -num;
                }

                if (num < 1) {
                return (neg ? '-' : '') + num + ' B';
                }

                exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
                num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
                unit = units[exponent];

                return (neg ? '-' : '') + num + ' ' + unit;
            }
        },
        computed:{
            ...mapState('PostControl', {
                formdata: state => state.formdata,
            }),
            ...mapState('SelectControl', {
                category: state => state.category,
            }),
        },
        methods:{
            ...mapActions('SelectControl', ['selectCategory']),
            ...mapActions('PostControl', ['createPost','updatePost','editPost']),
            ...mapMutations('PostControl', ['CLEAR_FORM']),

            addLink:function(){
                if(this.$route.name == 'knowledge-edit'){
                    this.formdata.files.push({
                        id: Math.random().toString(36).substring(7),
                        link_name:this.formlink.name,
                        link:this.formlink.url,
                        type:'LINK',
                    })
                }else if(this.$route.name == 'knowledge-add'){
                    this.formdata.files.push({
                        id: Math.random().toString(36).substring(7),
                        name:this.formlink.name,
                        file:this.formlink.url,
                        type:'LINK',
                    })
                }

                this.formlink = {
                    name:'',
                    url:'',
                }
                this.$bvModal.hide('modal-Link')
            },
            onImageUpload: function(event) {
                var input = event.target;

                if (input.files && input.files[0]) {

                    var reader = new FileReader();
                    var vm = this;
                    reader.onload = function(e) {
                        vm.formdata.image_cover_url = e.target.result;
                    }
                    reader.readAsDataURL(input.files[0]);
                }
                this.photo_file = input.files[0]
            },
            removeImageUpload:function(){
                this.photo_file = ''
                this.formdata.image_cover_url = ''
            },
            deleteFileKnowledge:function(index,id){
                this.formdata.files.splice(index,1)
                this.knowledge_del.push(id)
            },
            addFileKnowledge: function(event) {
                var files = event.target.files

                if (files && files[0]) {

                    for( var i = 0; i < files.length; i++ ){

                        var reader = new FileReader();
                        var vm = this;
                        var file =  files[i];

                        reader.onload = (function(f) {
                            return function(e) {
                                vm.formdata.files.push({
                                    id: Math.random().toString(36).substring(7),
                                    file:e.target.result,
                                    name:f.name,
                                    size:f.size,
                                    type:"FILE",
                                })
                            };
                        })(file);
                        reader.readAsDataURL(file);
                        this.knowledge_file.push(files[i])
                    }

                }
            },
            validationForm: function() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.form.errors)
                                .map(([key, value]) => ({ key, value }))
                                .filter(error => error["value"].length);
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100);
                    }else{

                        var formData = new FormData();
                        formData.append('title', this.formdata.title);
                        formData.append('content', this.formdata.content ? this.formdata.content : '');
                        formData.append('short_desc', this.formdata.short_desc ? this.formdata.short_desc : '');
                        formData.append('status', this.formdata.status);
                        formData.append('category_id', this.formdata.category_id);
                        formData.append('type', 'KNOWLEDGE');
                        formData.append('links_knowledge', JSON.stringify(this.formdata.files));

                        for(let i=0; i<this.knowledge_file.length;i++){
                            formData.append('files_knowledge[]',this.knowledge_file[i]);
                        }

                        if(this.photo_file){
                            formData.append('image_cover', this.photo_file);
                        }
                        this.loading = true

                        if(this.$route.name == 'knowledge-add'){

                            this.createPost(formData).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully added knowledge')
                                this.photo_file = ''
                                this.knowledge_file = []
                                this.knowledge_del = []
                                this.$refs.form.reset();
                                this.CLEAR_FORM()
                            })

                        }else if(this.$route.name == 'knowledge-edit'){

                            formData.append("_method", "put");
                            formData.append('knowledge_del', this.knowledge_del);
                            this.updatePost({id:this.$route.params.id,formData:formData}).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully edited knowledge')
                                this.$refs.form.reset();
                                this.photo_file = ''
                                this.knowledge_del = []
                                this.knowledge_file = []
                            })

                        }

                    }
                });
            },
        },
        beforeRouteLeave (to, from, next) {
            this.CLEAR_FORM()
            next();
        }
    }

</script>
