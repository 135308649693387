<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <router-link class="back-sec" :to="{name:'teams'}">
                    <i class="uil uil-arrow-left"></i>
                </router-link>
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <button v-on:click="validationForm()" type="button" class="btn btn-new"  v-bind:class="loading ? 'btn-progress' : ''">
                            Save Data
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">
                
                <ValidationObserver ref="form" class="form-cst">

                    <div class="row mt-4">
                        <div class="col-md-8">
                            <div class="card bdr-20">
                                <div class="card-body">

                                    <validation-provider rules="required|numeric" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Position Order</label>
                                            <input v-model="formdata.order" type="number" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Name</label>
                                            <input v-model="formdata.name" type="text" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <validation-provider rules="required" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <label>Position</label>
                                                    <input v-model="formdata.position" type="text" class="form-control" name="name">
                                                    <div class="invalid-input">{{ errors[0] }}</div>
                                                </div>
                                            </validation-provider>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Other Position</label>
                                                <input v-model="formdata.other_position" type="text" class="form-control" name="name">
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <validation-provider rules="url" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <label>Facebook Link</label>
                                                    <input v-model="formdata.fb" type="text" class="form-control" name="name">
                                                    <div class="invalid-input">{{ errors[0] }}</div>
                                                </div>
                                            </validation-provider>
                                        </div>
                                        <div class="col-md-6">
                                            <validation-provider rules="url" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <label>Instagram Link</label>
                                                    <input v-model="formdata.ig" type="text" class="form-control" name="name">
                                                    <div class="invalid-input">{{ errors[0] }}</div>
                                                </div>
                                            </validation-provider>
                                        </div>
                                        <div class="col-md-6">
                                            <validation-provider rules="url" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <label>Twitter Link</label>
                                                    <input v-model="formdata.tw" type="text" class="form-control" name="name">
                                                    <div class="invalid-input">{{ errors[0] }}</div>
                                                </div>
                                            </validation-provider>
                                        </div>
                                        <div class="col-md-6">
                                            <validation-provider rules="url" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <label>LinkedIn Link</label>
                                                    <input v-model="formdata.in" type="text" class="form-control" name="name">
                                                    <div class="invalid-input">{{ errors[0] }}</div>
                                                </div>
                                            </validation-provider>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>Profile Description</label>
                                        <editor
                                            v-model="formdata.desc"
                                            :api-key="api_tiny"
                                            :init="EditorOptions"
                                        />
                                    </div>
                                
                                </div>
                            </div>  

                            <div class="card bdr-20">
                                <div class="card-header">
                                    <h4>Member Team</h4>
                                    <div class="card-header-action">
                                        <button @click.prevent="addTeam()" class="ml-2 btn btn-success"><i class="uil uil-plus"></i> Add Team</button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <ValidationObserver ref="formteam" class="form-cst">

                                        <div class="row row-bdr mx-0 align-items-center" v-for="(data,index) in formdata.team" :key="index">
                                            <div class="col-md-3">
                                                <validation-provider v-if="!data.photo_url" rules="mimes:image/jpeg,image/png,image/jpg" v-slot="{ errors, validate }">
                                                    <div class="form-group">
                                                        <div style="height:45px;" class="btn-up p-0 d-flex align-items-center" v-on:click.prevent="addTeamImage(index)">
                                                            <div class="upload">ADD PHOTO</div>
                                                        </div>
                                                        <input :ref="'inputTeamImage' + index" 
                                                                :id="'inputTeamImage' + index" 
                                                                type="file"
                                                                class="custom-file-input d-none"
                                                                v-on:change="onImageTeamUpload($event,index)"
                                                                accept="image/jpeg,image/png,image/jpg" 
                                                                @input="validate" >
                                    
                                                        <div class="invalid-input mt-3">{{ errors[0] }}</div>
                                                    </div>
                                                </validation-provider>
                                                
                                                <div class="form-group" v-if="data.photo_url">   
                                                    <button v-on:click.prevent="removeImageTeamUpload(index)" type="button" class="p-1 btn-remove-image"><i class="uil uil-times"></i> Change Photo</button> 
                                                    <div class="input-group img-sh">
                                                        <img style="width: 100% !important;height: 100px !important;object-fit: cover;" :src="data.photo_url" class="w-100 img-responsive img-prf-up">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <div class="form-group mb-0">
                                                        <input placeholder="Name" v-model="data.name" type="text" class="form-control" name="name">
                                                        <div class="invalid-input">{{ errors[0] }}</div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                            <div class="col-md-4">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <div class="mb-0 form-group">
                                                        <input placeholder="Position" v-model="data.position" type="text" class="form-control" name="name">
                                                        <div class="invalid-input">{{ errors[0] }}</div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                            <div class="col-md-1 text-center">
                                                <button class="btn btn-danger" @click="deleteTeam(index,data.id)">
                                                    <i class="uil uil-trash-alt"></i>
                                                </button>
                                            </div>
                                        </div>

                                    </ValidationObserver>
                                </div>
                            </div>
                          
                        </div>
                        <div class="col-md-4">
                            <div class="card bdr-20">
                                <div class="card-body">
                                    <validation-provider v-if="!formdata.photo_url" rules="mimes:image/jpeg,image/png,image/jpg" v-slot="{ errors, validate }">
                                        <div class="form-group">
                                            <div class="mb-1">
                                                <label>Profile Photo</label>
                                            </div>
                                            <div class="btn-up d-flex align-items-center" v-on:click.prevent="$refs.inputImage.click()">
                                                <div class="upload"><i class="uil uil-image mr-1"></i> ADD PHOTO</div>
                                            </div>
                                            <input ref="inputImage" 
                                                    type="file"
                                                    class="custom-file-input d-none"
                                                    id="Thumbnail"
                                                    v-on:change="onImageUpload"
                                                    accept="image/jpeg,image/png,image/jpg" 
                                                    @input="validate" >
                        
                                            <div class="invalid-input mt-3">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>
                                    
                                    <div class="form-group" v-if="formdata.photo_url">   
                                        <div class="mb-1">
                                            <label>Profile Photo</label>
                                        </div> 
                                        <button v-on:click.prevent="removeImageUpload()" type="button" class="btn-remove-image"><i class="uil uil-times"></i> Change Photo</button> 
                                        <div class="input-group img-sh">
                                            <img :src="formdata.photo_url" class="w-100 img-responsive img-prf-up">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                
                </ValidationObserver>

            </div>
        </section>

    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import { mapState, mapActions, mapMutations} from 'vuex'
    import Alert from "../../components/Alert.vue";
    import options from "../../utils/editor";
    import Editor from '@tinymce/tinymce-vue'

    export default {
        data(){
            return{
                api_tiny:process.env.VUE_APP_TINYKEY,
                loading:false,
                EditorOptions: options.Options,
                del_team:[],
            }
        },
        mounted(){
            if(this.$route.name == 'teams-edit' && this.formdata.id == ''){
                this.editTeam(this.$route.params.id)
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            Alert,
            Editor
        },
        computed:{
            ...mapState('TeamControl', {
                formdata: state => state.formdata,
                errors: state => state.errors,
            }),
        },
        methods:{
            ...mapActions('TeamControl', ['createTeam','updateTeam','editTeam']),
            ...mapMutations('TeamControl', ['CLEAR_FORM']),
            

            addTeam(){
                this.formdata.team.push({
                    id:Math.random().toString(36).substring(7),
                    photo_url:'',
                    photo_file:'',
                    name:'',
                    position:'',
                });
            },
            deleteTeam(index,id){
                this.del_team.push(id)
                this.formdata.team.splice(index,1)
            },
            addTeamImage:function(index){
                this.$refs['inputTeamImage' + index][0].click()
            },
            onImageTeamUpload: function(event,index) {
                var input = event.target;

                if (input.files && input.files[0]) {

                    var reader = new FileReader();
                    var vm = this;
                    reader.onload = function(e) {
                        vm.formdata.team[index].photo_url = e.target.result;
                    }
                    reader.readAsDataURL(input.files[0]);
                }
                this.formdata.team[index].photo_file = input.files[0]
            },
            removeImageTeamUpload:function(index){
                this.formdata.team[index].photo_file = ''
                this.formdata.team[index].photo_url = ''
            },




            onImageUpload: function(event) {
                var input = event.target;

                if (input.files && input.files[0]) {

                    var reader = new FileReader();
                    var vm = this;
                    reader.onload = function(e) {
                        vm.formdata.photo_url = e.target.result;
                    }
                    reader.readAsDataURL(input.files[0]);
                }
                this.photo_file = input.files[0]
            },
            removeImageUpload:function(){
                this.photo_file = ''
                this.formdata.photo_url = ''
            },
            validationForm: function() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.form.errors)
                                .map(([key, value]) => ({ key, value }))
                                .filter(error => error["value"].length);
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100);
                    }else{

                        var formData = new FormData();
                        formData.append('name', this.formdata.name);
                        formData.append('position', this.formdata.position ? this.formdata.position : '');
                        formData.append('other_position', this.formdata.other_position ? this.formdata.other_position : '');
                        formData.append('fb', this.formdata.fb ? this.formdata.fb : '');
                        formData.append('ig', this.formdata.ig ? this.formdata.ig : '');
                        formData.append('tw', this.formdata.tw ? this.formdata.tw : '');
                        formData.append('in', this.formdata.in ? this.formdata.in : '');
                        formData.append('order', this.formdata.order ? this.formdata.order : '');
                        formData.append('desc', this.formdata.desc ? this.formdata.desc : '');

                        formData.append('team', JSON.stringify(this.formdata.team));
                        
                        for(let i=0; i<this.formdata.team.length;i++){
                            var file = new File([""], "null");
                            if(this.formdata.team[i].photo_file){
                                file =  this.formdata.team[i].photo_file
                            }
                            formData.append('teamfile[]', file);
                        }
                        
                        if(this.photo_file){
                            formData.append('photo', this.photo_file);
                        }
                        this.loading = true
                        
                        if(this.$route.name == 'teams-add'){

                            this.createTeam(formData).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully added team')
                                this.photo_file = ''
                                this.$refs.form.reset();
                                this.CLEAR_FORM()
                            }) 

                        }else if(this.$route.name == 'teams-edit'){

                            formData.append('del_team', this.del_team);
                            formData.append("_method", "put");
                            this.updateTeam({id:this.$route.params.id,formData:formData}).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully edited team')
                                this.$refs.form.reset();
                                this.photo_file = ''
                            }) 

                        }
                       
                    }
                });
            },
        },
        beforeRouteLeave (to, from, next) {
            this.CLEAR_FORM()
            next();
        }
    }

</script>