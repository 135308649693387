<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <router-link class="back-sec" :to="{name:'subscriber'}">
                    <i class="uil uil-arrow-left"></i>
                </router-link>
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <button v-on:click="validationForm()" type="button" class="btn btn-new"  v-bind:class="loading ? 'btn-progress' : ''">
                            Save Data
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">
                
                <ValidationObserver ref="form" class="form-cst">

                    <div class="row mt-4">
                        <div class="col-md-8">
                            <div class="card bdr-20">
                                <div class="card-body">

                                    <div class="row">
                                        <div class="col-md-6">
                                            <validation-provider rules="required" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <label>First Name</label>
                                                    <input v-model="formdata.first_name" type="text" class="form-control" name="name">
                                                    <div class="invalid-input">{{ errors[0] }}</div>
                                                </div>
                                            </validation-provider>
                                        </div>
                                        <div class="col-md-6">
                                            <validation-provider rules="required" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <label>Last Name</label>
                                                    <input v-model="formdata.last_name" type="text" class="form-control" name="name">
                                                    <div class="invalid-input">{{ errors[0] }}</div>
                                                </div>
                                            </validation-provider>
                                        </div>
                                    </div>

                                    <validation-provider rules="required|email" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input v-model="formdata.email" type="email" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>
                                
                                </div>
                            </div>  
                          
                        </div>
                        <div class="col-md-4">
                            <div class="card bdr-20">
                                <div class="card-body">
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Subscribe Status</label>
                                            <select class="form-control" v-model="formdata.status">
                                                <option></option>
                                                <option value="ACTIVE">ACTIVE</option>
                                                <option value="INACTIVE">INACTIVE</option>
                                            </select>
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </ValidationObserver>

            </div>
        </section>

    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import { mapState, mapActions, mapMutations} from 'vuex'
    import Alert from "../../components/Alert.vue";

    export default {
        data(){
            return{
                loading:false,
            }
        },
        mounted(){
            if(this.$route.name == 'subscriber-edit' && this.formdata.id == ''){
                this.editNewsletter(this.$route.params.id)
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            Alert,
        },
        computed:{
            ...mapState('NewsletterControl', {
                formdata: state => state.formdata,
                errors: state => state.errors,
            }),
        },
        methods:{
            ...mapActions('NewsletterControl', ['createNewsletter','updateNewsletter','editNewsletter']),
            ...mapMutations('NewsletterControl', ['CLEAR_FORM']),
            
            validationForm: function() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.form.errors)
                                .map(([key, value]) => ({ key, value }))
                                .filter(error => error["value"].length);
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100);
                    }else{
                        
                        this.loading = true

                        if(this.$route.name == 'subscriber-add'){

                            this.createNewsletter(this.formdata).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully added subscriber')
                                this.photo_file = ''
                                this.$refs.form.reset();
                                this.CLEAR_FORM()
                            }) 

                        }else if(this.$route.name == 'subscriber-edit'){

                            this.updateNewsletter({id:this.$route.params.id,formData:this.formdata}).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully edited subscriber')
                                this.$refs.form.reset();
                                this.photo_file = ''
                            }) 

                        }
                       
                    }
                });
            },
        },
        beforeRouteLeave (to, from, next) {
            this.CLEAR_FORM()
            next();
        }
    }

</script>