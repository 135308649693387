import $api from '../../utils/api.js';

const SelectControl = {
    namespaced: true,
    state: {
       category:[],
       tag:[],
       package:[],
       category_package:[],
       member:[],
       business_principle:[],
       organization_type:[],
       filteredTags:[],
    },
    getters: {
        getFilteredTags: (state) => state.filteredTags,
    },
    mutations: {
        ASSIGN_CATEGORY(state, payload){
            state.category = payload
        },
        ASSIGN_TAG(state, payload){
            state.tag = payload
        },
        ASSIGN_MEMBER(state, payload){
            state.member = payload
        },
        ASSIGN_BUSINESS_PRINCIPLE(state, payload){
            state.business_principle = payload
        },
        ASSIGN_ORGANIZATION_TYPE(state, payload){
            state.organization_type = payload
        },
        ASSIGN_PACKAGE(state, payload){
            state.package = payload
        },
        ASSIGN_CATEGORY_PACKAGE(state, payload){
            state.category_package = payload
        },
        FILTERED_TAGS(state, filter) {
            state.filteredTags = state.tag.filter((tg) => {
                return !filter.tags.map((sel) => { return sel }).includes(tg.id) 
            })
        },
    },
    actions: {
        filterTags({ commit }, word) {
            commit('FILTERED_TAGS', word)
        },
        selectCategory({commit}, payload){
            return new Promise((resolve) => {
                $api.get(`select/category?type=${payload}`).then((response) => {
                    commit('ASSIGN_CATEGORY', response.data)
                    resolve(response.data)
                })
            })
        },
        selectTag({commit}, payload){
            return new Promise((resolve) => {
                $api.get(`select/tag?type=${payload}`).then((response) => {
                    commit('ASSIGN_TAG', response.data)
                    resolve(response.data)
                })
            })
        },
        selectPackage({commit}){
            return new Promise((resolve) => {
                $api.get(`select/package`).then((response) => {
                    commit('ASSIGN_PACKAGE', response.data)
                    resolve(response.data)
                })
            })
        },
        selectCategoryPackage({commit}){
            return new Promise((resolve) => {
                $api.get(`select/categorypackage`).then((response) => {
                    commit('ASSIGN_CATEGORY_PACKAGE', response.data)
                    resolve(response.data)
                })
            })
        },
        selectMember({commit}){
            return new Promise((resolve) => {
                $api.get(`select/member`).then((response) => {
                    commit('ASSIGN_MEMBER', response.data)
                    resolve(response.data)
                })
            })
        },
        selectBusinessPrinciple({commit}){
            return new Promise((resolve) => {
                $api.get(`select/business-principle`).then((response) => {
                    commit('ASSIGN_BUSINESS_PRINCIPLE', response.data)
                    resolve(response.data)
                })
            })
        },
        selectOrganizationType({commit}){
            return new Promise((resolve) => {
                $api.get(`select/organization-type`).then((response) => {
                    commit('ASSIGN_ORGANIZATION_TYPE', response.data)
                    resolve(response.data)
                })
            })
        },
    }
}

export default SelectControl