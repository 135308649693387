<template>
  <div class="paginate-block d-inline-flex">
      <div class="p1 d-flex align-items-center">
        <div class="form-group ft-entry mr-4">
            <label class="mb-0">Sort data</label>
            <select class="form-control" v-model="sort" @change.prevent="changeSort(sort)" style="width:65px;">
                <option value="asc">ASC</option>
                <option value="desc">DESC</option>
            </select>
        </div>
        <div class="form-group ft-entry mr-4">
            <label class="mb-0">Data per Page</label>
            <select class="form-control" v-model="num" @change.prevent="changeShow(num)">
                <option>10</option>
                <option>20</option>
                <option>50</option>
                <option>100</option>
            </select>
        </div>
        <div class="show mr-4 mb-0 d-inline-block form-group">
          <label class="font-weight-bold mb-0">{{pagination.from}} - {{pagination.to}} of {{pagination.total}}</label>
        </div>
      </div>
      <div class="p1 mr-3 d-flex align-items-center">
       
        <nav class="d-inline-block">
          <ul class="pagination mb-0">
            <li v-if="pagination.current_page > 1" class="page-item">
              <a href="javascript:void(0)" aria-label="Previous" v-on:click.prevent="changePage(pagination.current_page - 1)" class="page-link">
                <i class="uil uil-angle-left"></i>
              </a>
            </li>
            <li v-for="page in pagesNumber" class="page-item" :class="{'active': page == pagination.current_page}" v-bind:key="page">
              <a href="javascript:void(0)" v-on:click.prevent="changePage(page)" class="page-link">{{ page }}</a>
            </li>
            <li v-if="pagination.current_page < pagination.last_page" class="page-item">
              <a href="javascript:void(0)" aria-label="Next" v-on:click.prevent="changePage(pagination.current_page + 1)" class="page-link">
                <i class="uil uil-angle-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
  </div>
</template>

<script>

  export default{
    data(){
        return {
            num: 10,
            sort: 'desc',
        }
    },
    props: {
      pagination: {
          type: Object,
          required: true
      },
      sorting: {
          type: Object,
          required: true,
      },
    },
    computed: {
      pagesNumber() {
        if (!this.pagination.to) {
          return [];
        }
        let from = this.pagination.current_page - this.sorting.offset;
        if (from < 1) {
          from = 1;
        }
        let to = from + (this.sorting.offset * 2);
        if (to >= this.pagination.last_page) {
          to = this.pagination.last_page;
        }
        let pagesArray = [];
        for (let page = from; page <= to; page++) {
          pagesArray.push(page);
        }
          return pagesArray;
      }
    },
    methods : {
      changeSort(sort) {
        this.sorting.sort = sort;
        this.$emit('paginate');
      },
      changeShow(num) {
        this.sorting.num = num;
        this.$emit('paginate');
      },
      changePage(page) {
        this.pagination.current_page = page;
        this.$emit('paginate');
      }
    }
  }
</script>