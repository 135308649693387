import $api from '../../utils/api.js'

const state = () => ({
    reports:{},
})

const mutations = {
    ASSIGN_REPORTS(state, payload) {
        state.reports = payload
    },
}

const actions = {
    getDashboard({ commit }) {
        return new Promise((resolve) => {
            $api.get(`dashboard`)
            .then((response) => {
                commit('ASSIGN_REPORTS', response.data)
                resolve(response.data)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}