var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('alert',{ref:"alert"}),_c('section',{staticClass:"section"},[_c('div',{staticClass:"sec-cst section-header bdr-20 mt-4"},[_c('router-link',{staticClass:"back-sec",attrs:{"to":{name:'teams'}}},[_c('i',{staticClass:"uil uil-arrow-left"})]),_c('h1',{staticClass:"mt-0"},[_vm._v(" "+_vm._s(this.$route.meta.title)+" ")]),_c('div',{staticClass:"section-header-breadcrumb"},[_c('div',{staticClass:"button-modal text-right"},[_c('button',{staticClass:"btn btn-new",class:_vm.loading ? 'btn-progress' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.validationForm()}}},[_vm._v(" Save Data ")])])])],1),_c('div',{staticClass:"section-body mt-4"},[_c('ValidationObserver',{ref:"form",staticClass:"form-cst"},[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"card bdr-20"},[_c('div',{staticClass:"card-body"},[_c('validation-provider',{attrs:{"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Position Order")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.order),expression:"formdata.order"}],staticClass:"form-control",attrs:{"type":"number","name":"name"},domProps:{"value":(_vm.formdata.order)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "order", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.name),expression:"formdata.name"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Position")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.position),expression:"formdata.position"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.position)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "position", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Other Position")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.other_position),expression:"formdata.other_position"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.other_position)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "other_position", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Facebook Link")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.fb),expression:"formdata.fb"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.fb)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "fb", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Instagram Link")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.ig),expression:"formdata.ig"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.ig)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "ig", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Twitter Link")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.tw),expression:"formdata.tw"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.tw)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "tw", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("LinkedIn Link")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.in),expression:"formdata.in"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.in)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "in", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Profile Description")]),_c('editor',{attrs:{"api-key":_vm.api_tiny,"init":_vm.EditorOptions},model:{value:(_vm.formdata.desc),callback:function ($$v) {_vm.$set(_vm.formdata, "desc", $$v)},expression:"formdata.desc"}})],1)],1)]),_c('div',{staticClass:"card bdr-20"},[_c('div',{staticClass:"card-header"},[_c('h4',[_vm._v("Member Team")]),_c('div',{staticClass:"card-header-action"},[_c('button',{staticClass:"ml-2 btn btn-success",on:{"click":function($event){$event.preventDefault();return _vm.addTeam()}}},[_c('i',{staticClass:"uil uil-plus"}),_vm._v(" Add Team")])])]),_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{ref:"formteam",staticClass:"form-cst"},_vm._l((_vm.formdata.team),function(data,index){return _c('div',{key:index,staticClass:"row row-bdr mx-0 align-items-center"},[_c('div',{staticClass:"col-md-3"},[(!data.photo_url)?_c('validation-provider',{attrs:{"rules":"mimes:image/jpeg,image/png,image/jpg"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"btn-up p-0 d-flex align-items-center",staticStyle:{"height":"45px"},on:{"click":function($event){$event.preventDefault();return _vm.addTeamImage(index)}}},[_c('div',{staticClass:"upload"},[_vm._v("ADD PHOTO")])]),_c('input',{ref:'inputTeamImage' + index,refInFor:true,staticClass:"custom-file-input d-none",attrs:{"id":'inputTeamImage' + index,"type":"file","accept":"image/jpeg,image/png,image/jpg"},on:{"change":function($event){return _vm.onImageTeamUpload($event,index)},"input":validate}}),_c('div',{staticClass:"invalid-input mt-3"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}):_vm._e(),(data.photo_url)?_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"p-1 btn-remove-image",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.removeImageTeamUpload(index)}}},[_c('i',{staticClass:"uil uil-times"}),_vm._v(" Change Photo")]),_c('div',{staticClass:"input-group img-sh"},[_c('img',{staticClass:"w-100 img-responsive img-prf-up",staticStyle:{"width":"100% !important","height":"100px !important","object-fit":"cover"},attrs:{"src":data.photo_url}})])]):_vm._e()],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group mb-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.name),expression:"data.name"}],staticClass:"form-control",attrs:{"placeholder":"Name","type":"text","name":"name"},domProps:{"value":(data.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mb-0 form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.position),expression:"data.position"}],staticClass:"form-control",attrs:{"placeholder":"Position","type":"text","name":"name"},domProps:{"value":(data.position)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "position", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"col-md-1 text-center"},[_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.deleteTeam(index,data.id)}}},[_c('i',{staticClass:"uil uil-trash-alt"})])])])}),0)],1)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card bdr-20"},[_c('div',{staticClass:"card-body"},[(!_vm.formdata.photo_url)?_c('validation-provider',{attrs:{"rules":"mimes:image/jpeg,image/png,image/jpg"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"mb-1"},[_c('label',[_vm._v("Profile Photo")])]),_c('div',{staticClass:"btn-up d-flex align-items-center",on:{"click":function($event){$event.preventDefault();return _vm.$refs.inputImage.click()}}},[_c('div',{staticClass:"upload"},[_c('i',{staticClass:"uil uil-image mr-1"}),_vm._v(" ADD PHOTO")])]),_c('input',{ref:"inputImage",staticClass:"custom-file-input d-none",attrs:{"type":"file","id":"Thumbnail","accept":"image/jpeg,image/png,image/jpg"},on:{"change":_vm.onImageUpload,"input":validate}}),_c('div',{staticClass:"invalid-input mt-3"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,541749191)}):_vm._e(),(_vm.formdata.photo_url)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"mb-1"},[_c('label',[_vm._v("Profile Photo")])]),_c('button',{staticClass:"btn-remove-image",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.removeImageUpload()}}},[_c('i',{staticClass:"uil uil-times"}),_vm._v(" Change Photo")]),_c('div',{staticClass:"input-group img-sh"},[_c('img',{staticClass:"w-100 img-responsive img-prf-up",attrs:{"src":_vm.formdata.photo_url}})])]):_vm._e()],1)])])])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }