<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <router-link :to="{name:'pages-add'}"  class="btn btn-new"><i class="uil uil-plus mr-2"></i> Add Data</router-link>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">
                <div class="row mt-4">
                    <div class="col-12 text-center mt-3 hv-100" v-if="no_data === null">
                        <div class="half-circle-spinner">
                            <div class="circle circle-1"></div>
                            <div class="circle circle-2"></div>
                        </div>
                    </div>
                    <div class="col-12" v-if="no_data === false">
                        <div class="card bdr-20">
                            <div class="card-header border-0 py-4 tb-card">
                                <div class="table-sorting">
                                    <div class="table-search float-left">
                                        <vue-search 
                                            :search="sorting"
                                            @searchdata="getPage()">
                                        </vue-search>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body px-0 pb-0">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th width="20px">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="all_id" v-model="selectAll">
                                                        <label class="custom-control-label" for="all_id"></label>
                                                    </div>
                                                </th>
                                                <th>Page Title</th>
                                                <th>Author Name</th>
                                                <th>Status</th>
                                                <th>Created Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="tr-ac" v-for="data in page.data" :key="data.id" v-on:click="_editPage(data)">
                                                <td width="20px" v-on:click.stop=""> 
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" v-model="del_page" :value="data.id" :id="data.id">
                                                        <label class="custom-control-label" :for="data.id"></label>
                                                    </div>
                                                </td>
                                                <td width="350px">
                                                    <div  class="d-flex align-items-center">
                                                        <div>
                                                            <img :src="data.image_cover_url" alt="profile" class="my-3 mr-4 bdr-100 img-tb">
                                                        </div>
                                                        <div>
                                                            <div v-on:click.stop="" class="font-weight-bold"><a target="_blank" :href="$options.filters.pageLink(data)">{{data.title}}</a></div>
                                                            <div class="tx-g">{{data.category_name}}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{data.author}}</td>
                                                <td>
                                                    <label v-if="data.status === 'PUBLISHED'" class="badge badge-success">{{data.status}}</label>
                                                    <label v-if="data.status === 'DRAFT'"  class="badge badge-warning">{{data.status}}</label>
                                                </td>
                                                <td>{{data.created_at |  formatDate('MMMM DD, YYYY')}}</td>
                                            </tr>
                                        
                                        </tbody>
                                        
                                    </table>
                                    <p v-if="page.total === 0" class="text-center no-data">Page data not found</p>
                                </div>
                            </div>
                            <div class="card-footer text-right">
                                <vue-pagination :pagination="page"
                                                :sorting="sorting"
                                                @paginate="getPage()">
                                </vue-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" v-if="no_data === true && sorting.search === ''">
                        <div class="card bdr-20 py-5">
                            <div class="card-body card-nitem text-center py-5">
                                <img class="mb-4" width="150px" src="@/assets/img/no-data.svg" alt="">
                                <div class="nitem-desc">
                                    <h3>Page Data Still Empty</h3>
                                    <p>Please create your page data on this page</p>
                                    <div class="tabel-action">
                                        <router-link :to="{name:'pages-add'}"  class="btn btn-new"><i class="uil uil-plus mr-2"></i> Add Data</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-action" v-if="del_page.length !== 0">
                <div class="card-body">
                    <div class="float-left text-white mt-1">
                        <span v-on:click.prevent="del_page = []" class="times"><i class="uil uil-times"></i></span>
                        <span><span class="badge">{{del_page.length}}</span> data selected</span>
                    </div>
                    <div class="float-right">
                        <div v-b-modal.modal-Alert class="btn btn-danger">
                            <i class="uil uil-times"></i> Delete
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <b-modal id="modal-Alert" hide-footer hide-header centered>
            <div class="text-center pt-2 pb-3 px-3">
                <i style="font-size: 60px; color: #f8d735;" class="uil uil-exclamation-triangle"></i>
                <h3 class="mt-1">Confirmation</h3>
                <p style="font-size:16px;">Are you sure to delete this data ? data that has been deleted cannot be returned</p>
                <div class="btn-alert mt-4">
                    <button @click="_deletePage()" v-bind:class="loading ? 'btn-progress' : ''" class="btn btn-danger mr-2">Sure</button>
                    <button @click="$bvModal.hide('modal-Alert')" class="btn btn-secondary">Cancel</button>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations} from 'vuex'
    import VuePagination from "../../components/Pagination.vue";
    import VueSearch from "../../components/Search.vue";
    import Alert from "../../components/Alert.vue";
    import moment from 'moment'

    export default {
        components: {
            VuePagination,
            VueSearch,
            Alert
        },
        data(){
            return {
                no_data: null,
                loading:false,
                del_page:[],
            }
        },
        mounted(){
            this._getPage()
        },
        filters: {
            formatDate: function(val,format){
                if(val){
                    return moment(String(val)).format(format)
                }else{
                    return '-'
                }
            },
            pageLink: function(value) {
                var frameURL;
                if(process.env.NODE_ENV == 'development'){
                    frameURL = 'http://localhost:3000/'
                }else if(process.env.NODE_ENV == 'production'){
                    frameURL = process.env.VUE_APP_WEBURL
                }

                if(value.slug === 'home'){
                    return frameURL
                }
                return frameURL + value.slug
            },
        },
        computed:{
            ...mapState('PageControl', {
                page: state => state.page,
                sorting: state => state.sorting,
                formdata: state => state.formdata,
                errors: state => state.errors,
            }),

            selectAll: {
                get: function () {
                    return this.page.data ? this.del_page.length == this.page.data.length : false;
                },
                set: function (value) {
                    var selected = [];

                    if (value) {
                        this.page.data.forEach(function (page) {
                            selected.push(page.id);
                        });
                    }
                    this.del_page = selected;
                }
            }
        },
        methods: {
            ...mapActions('PageControl', ['getPage','deletePage']),
            ...mapMutations('PageControl', ['ASSIGN_FORM']),
            
            _getPage:function(){
                this.sorting.type = 'NEWS'
                this.getPage().then(() => {
                    if(this.page.total == 0){
                        this.no_data = true
                    }else{
                        this.no_data = false
                    }
                })
            },
            _editPage: function(data){
                this.ASSIGN_FORM(data)
                this.$router.push({ name: 'pages-edit', params: { id: data.id } })
            },
            _deletePage:function(){
                this.loading = true
                this.deletePage(this.del_page).then(() => {
                    this.loading = false
                    this.getPage();
                    this.del_page = []
                    this.$bvModal.hide('modal-Alert')
                    this.$refs.alert.showAlert('success','Successfully deleted page data')
                })
            },
        }
    }

</script>