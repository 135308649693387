<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <router-link class="back-sec" :to="{name:'package'}">
                    <i class="uil uil-arrow-left"></i>
                </router-link>
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <button v-on:click="validationForm()" type="button" class="btn btn-new"  v-bind:class="loading ? 'btn-progress' : ''">
                            Save Data
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">

                <ValidationObserver ref="form" class="form-cst">

                    <div class="row mt-4">
                        <div class="col-md-8">
                            <div class="card bdr-20">
                                <div class="card-body">

                                    <validation-provider rules="required|numeric" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Position Order</label>
                                            <input v-model="formdata.order" type="number" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Package Name</label>
                                            <input v-model="formdata.name" type="text" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <validation-provider rules="required|numeric" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <label>Package Price</label>
                                                    <input v-model="formdata.price" type="number" class="form-control" name="name">
                                                    <div class="invalid-input">{{ errors[0] }}</div>
                                                </div>
                                            </validation-provider>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Package Price Tag</label>
                                                <input v-model="formdata.price_tag" type="text" class="form-control" name="name">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>Package Description</label>
                                        <editor
                                        v-model="formdata.desc"
                                        :api-key="api_tiny"
                                        :init="EditorOptions"
                                    />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-4">
                            <div class="card bdr-20">
                                <div class="card-body">
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Event Category</label>
                                            <v-select :clearable="false" label="name" :reduce="catagory => catagory.id" v-model="formdata.category_id" :options="category"></v-select>
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <div class="form-group mt-3">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" v-model="formdata.is_active" class="custom-control-input" true-value="1" false-value="0" id="is_active">
                                            <label class="custom-control-label" for="is_active">
                                                <b class="ml-2">Package Active Status</b>
                                            </label>
                                        </div>
                                        <small>Check this package if showing on member registration on website</small>
                                    </div>

                                    <div class="form-group mt-3">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" v-model="formdata.is_show" class="custom-control-input" true-value="1" false-value="0" id="is_show">
                                            <label class="custom-control-label" for="is_show"><b class="ml-2">Package Showing Status</b></label>
                                        </div>
                                        <small>Check this if package if showing on website</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </ValidationObserver>

            </div>
        </section>

    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import { mapState, mapActions, mapMutations} from 'vuex'
    import Alert from "../../components/Alert.vue";
    import options from "../../utils/editor";
    import Editor from '@tinymce/tinymce-vue'
    import vSelect from 'vue-select'

    export default {
        data(){
            return{
                loading:false,
                api_tiny:process.env.VUE_APP_TINYKEY,
                EditorOptions: options.Options

            }
        },
        mounted(){
            if(this.$route.name == 'package-edit' && this.formdata.id == ''){
                this.editPackage(this.$route.params.id)
            }
            this.selectCategoryPackage()
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            Alert,
            Editor,
            vSelect
        },
        computed:{
            ...mapState('PackageControl', {
                formdata: state => state.formdata,
                errors: state => state.errors,
            }),
            ...mapState('SelectControl', {
                category: state => state.category_package,
            }),
        },
        methods:{
            ...mapActions('PackageControl', ['createPackage','updatePackage','editPackage']),
            ...mapActions('SelectControl', ['selectCategoryPackage']),
            ...mapMutations('PackageControl', ['CLEAR_FORM']),

            validationForm: function() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.form.errors)
                                .map(([key, value]) => ({ key, value }))
                                .filter(error => error["value"].length);
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100);
                    }else{

                        this.loading = true

                        if(this.$route.name == 'package-add'){

                            this.createPackage(this.formdata).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully added package')
                                this.photo_file = ''
                                this.$refs.form.reset();
                                this.CLEAR_FORM()
                            })

                        }else if(this.$route.name == 'package-edit'){

                            this.updatePackage({id:this.$route.params.id,formData:this.formdata}).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully edited package')
                                this.$refs.form.reset();
                                this.photo_file = ''
                            })

                        }

                    }
                });
            },
        },
        beforeRouteLeave (to, from, next) {
            this.CLEAR_FORM()
            next();
        }
    }

</script>
