<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <router-link class="back-sec" :to="{name:'member'}">
                    <i class="uil uil-arrow-left"></i>
                </router-link>
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <button v-if="active_step !== 1" v-on:click="active_step -= 1" type="button" class="btn btn-new2 mr-2" >
                            <i class="uil uil-angle-left"></i> Back Step
                        </button>
                        <button v-if="active_step !== 3" v-on:click="nextStep()" type="button" class="btn btn-new">
                            Next Step <i class="uil uil-angle-right"></i>
                        </button>
                        <button v-if="active_step === 3" v-on:click="nextStep()" type="button" class="btn btn-new"  v-bind:class="loading ? 'btn-progress' : ''">
                            Save Data <i class="uil uil-angle-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4" v-if="loading_page === true">
                <div class="row">
                    <div class="col-12 text-center mt-3 hv-100">
                        <div class="half-circle-spinner">
                            <div class="circle circle-1"></div>
                            <div class="circle circle-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4" v-if="loading_page === false">

                <div class="wizard-steps">
                    <div v-for="data in member_step"
                        :key="data.id"
                        :class="active_step === data.id ? 'wizard-step-active' : ''"
                        class="wizard-step"
                    >
                        <div class="wizard-step-icon">
                            <i :class="data.icon"></i>
                        </div>
                        <div class="wizard-step-label">
                            {{data.step}}
                        </div>
                    </div>
                </div>

                <div v-show="active_step === 1">

                    <ValidationObserver ref="formstep1" class="form-cst">

                        <div class="row mt-4">
                            <div class="col-md-12">
                                <div class="card bdr-20">
                                    <div class="card-header">
                                        <h4>Basic Information</h4>
                                    </div>
                                    <div class="card-body">
                                        <validation-provider v-if="!formdata.logo_url" rules="required|mimes:image/jpeg,image/png,image/jpg" v-slot="{ errors, validate }">
                                            <div class="form-group">
                                                <div class="mb-1">
                                                    <label>Organization Logo (*)</label>
                                                </div>
                                                <div class="btn-up d-flex align-items-center" v-on:click.prevent="$refs.inputImage.click()">
                                                    <div class="upload"><i class="uil uil-image mr-1"></i> ADD PHOTO</div>
                                                </div>
                                                <input ref="inputImage"
                                                        type="file"
                                                        class="custom-file-input d-none"
                                                        id="Thumbnail"
                                                        v-on:change="onImageUpload"
                                                        accept="image/jpeg,image/png,image/jpg"
                                                        @input="validate" >

                                                <div class="invalid-input mt-3">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <div class="form-group" v-if="formdata.logo_url">
                                            <div class="mb-1">
                                                <label>Organization Logo (*)</label>
                                            </div>
                                            <button v-on:click.prevent="removeImageUpload()" type="button" class="btn-remove-image"><i class="uil uil-times"></i> Change Photo</button>
                                            <div class="input-group img-sh">
                                                <img :src="formdata.logo_url" class="w-100 img-responsive img-prf-up">
                                            </div>
                                        </div>

                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Organization Name (*)</label>
                                                <input v-model="formdata.organization_name" type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Business Address (*)</label>
                                                <input v-model="formdata.business_address" type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <label>Business Phone (*)</label>
                                                        <input v-model="formdata.business_phone" type="text" class="form-control" name="name">
                                                        <div class="invalid-input">{{ errors[0] }}</div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                            <div class="col-md-6">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <label>Business Fax (*)</label>
                                                        <input v-model="formdata.business_fax" type="text" class="form-control" name="name">
                                                        <div class="invalid-input">{{ errors[0] }}</div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                        </div>

                                        <validation-provider rules="required|email" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Business Email (*)</label>
                                                <input v-model="formdata.business_email" type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <div class="form-group">
                                            <label>Tax ID</label>
                                            <input v-model="formdata.tax_id" type="text" class="form-control" name="name">
                                        </div>

                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Join Date (*)</label>
                                                <input v-model="formdata.join_date" type="date" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider class="mb-3" rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>File Attachment Type (*)</label>
                                                <select v-model="formdata.tax_file_type" class="form-control">
                                                    <option selected value=""></option>
                                                    <option value="NPWP Corporate">NPWP Corporate</option>
                                                    <option value="NPWP University">NPWP University</option>
                                                    <option value="NPWP Personal">NPWP Personal</option>
                                                    <option value="Student Card">Student Card</option>
                                                </select>
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider v-if="!formdata.tax_url" rules="required|mimes:image/jpeg,image/png,image/jpg" v-slot="{ errors, validate }">
                                            <div class="form-group">
                                                <div class="mb-1">
                                                    <label>Attach Your {{formdata.tax_file_type}} Here (*)</label>
                                                </div>
                                                <div class="btn-up d-flex align-items-center" v-on:click.prevent="$refs.inputTaxFile.click()">
                                                    <div class="upload"><i class="uil uil-image mr-1"></i> ADD PHOTO</div>
                                                </div>
                                                <input ref="inputTaxFile"
                                                        type="file"
                                                        class="custom-file-input d-none"
                                                        id="Thumbnail"
                                                        v-on:change="onTaxFileUpload"
                                                        accept="image/jpeg,image/png,image/jpg"
                                                        @input="validate" >

                                                <div class="invalid-input mt-3">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <div class="form-group" v-if="formdata.tax_url">
                                            <div class="mb-1">
                                                <label>Attach Your File Here (*)</label>
                                            </div>
                                            <button v-on:click.prevent="removeTaxFileUpload()" type="button" class="btn-remove-image"><i class="uil uil-times"></i> Change Photo</button>
                                            <div class="input-group img-sh">
                                                <img :src="formdata.tax_url" class="w-100 img-responsive img-prf-up">
                                            </div>
                                        </div>

                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <label>Business Principle (*)</label>
                                            <div class="form-group">
                                                <div v-for="(data,index) in business_principle" :key="index" class="mb-2 custom-control custom-radio custom-control-inline">
                                                    <input v-model="formdata.business_principle" type="radio" class="custom-control-input" :value="data.label" :id="data.label">
                                                    <label class="custom-control-label" :for="data.label">{{data.label}}</label>
                                                </div>
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>


                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <label>Type of Organization (*)</label>
                                            <div class="form-group">
                                                <div v-for="(data,index) in organization_type" :key="index" class="mb-2 custom-control custom-radio custom-control-inline">
                                                    <input type="radio" v-model="formdata.organization_type" class="custom-control-input" :value="data.label" :id="data.label">
                                                    <label class="custom-control-label" :for="data.label">{{data.label}}</label>
                                                </div>
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider v-if="formdata.organization_type === 'Other'" rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Other Type Organization (*)</label>
                                                <input v-model="formdata.organization_type_custom" type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <div class="form-group">
                                            <label>Organization Description</label>
                                            <editor
                                            v-model="formdata.desc"
                                            :api-key="api_tiny"
                                            :init="EditorOptions"
                                        />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card bdr-20">
                                    <div class="card-header">
                                        <h4>Contact Information Association</h4>
                                    </div>
                                    <div class="card-body">

                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Name (*)</label>
                                                <input v-model="formdata.pic_name" type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Address (*)</label>
                                                <input v-model="formdata.pic_address" type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <label>Phone (*)</label>
                                                        <input v-model="formdata.pic_phone" type="text" class="form-control" name="name">
                                                        <div class="invalid-input">{{ errors[0] }}</div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                            <div class="col-md-12">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <label>Fax (*)</label>
                                                        <input v-model="formdata.pic_fax" type="text" class="form-control" name="name">
                                                        <div class="invalid-input">{{ errors[0] }}</div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                            <div class="col-md-12">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <label>Mobile (*)</label>
                                                        <input v-model="formdata.pic_mobile" type="text" class="form-control" name="name">
                                                        <div class="invalid-input">{{ errors[0] }}</div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                            <div class="col-md-12">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <label>Email (*)</label>
                                                        <input v-model="formdata.pic_email" type="text" class="form-control" name="name">
                                                        <div class="invalid-input">{{ errors[0] }}</div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="card bdr-20">
                                    <div class="card-header">
                                        <h4>Social Media</h4>
                                    </div>
                                    <div class="card-body">
                                        <validation-provider rules="url" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Website</label>
                                                <input v-model="formdata.website" @keydown.space.prevent type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>
                                        <validation-provider rules="url" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Facebook</label>
                                                <input v-model="formdata.fb" @keydown.space.prevent type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>
                                        <validation-provider rules="url" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Instagram</label>
                                                <input v-model="formdata.ig" @keydown.space.prevent type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>
                                        <validation-provider rules="url" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Youtube</label>
                                                <input v-model="formdata.yt" @keydown.space.prevent type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>
                                        <validation-provider rules="url" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Twitter</label>
                                                <input v-model="formdata.tw" @keydown.space.prevent type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>
                                        <validation-provider rules="url" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Linkedin</label>
                                                <input v-model="formdata.in" @keydown.space.prevent type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ValidationObserver>

                </div>

                <div v-show="active_step === 2">

                    <div class="row mt-4">
                        <div class="col-md-12">
                            <div class="card bdr-20">
                                <div class="card-header">
                                    <h4>Representatives Data</h4>
                                    <div class="card-header-action">
                                        <a href="#" v-on:click.prevent="addRepresentatives()" class="btn btn-new"><i class="uil uil-plus"></i> Add New</a>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <ValidationObserver ref="formstep2" class="form-cst">

                                        <div class="row row-bdr" v-for="(data,index) in formdata.representatives" :key="index">
                                            <div class="col-md-2">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <input placeholder="First Name" v-model="data.first_name" type="text" class="form-control" name="name">
                                                        <div class="invalid-input">{{ errors[0] }}</div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                            <div class="col-md-2">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <input placeholder="Last Name" v-model="data.last_name" type="text" class="form-control" name="name">
                                                        <div class="invalid-input">{{ errors[0] }}</div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <input placeholder="Position" v-model="data.position" type="text" class="form-control" name="name">
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <input placeholder="Phone" v-model="data.phone" type="text" class="form-control" name="name">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <validation-provider rules="email" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <input placeholder="Email" v-model="data.email" type="text" class="form-control" name="name">
                                                        <div class="invalid-input">{{ errors[0] }}</div>
                                                    </div>
                                                </validation-provider>
                                            </div>
                                            <div class="col-md-1 text-center">
                                                <button class="btn btn-danger" @click="deleteRepresentatives(index,data.id)">
                                                    <i class="uil uil-trash-alt"></i>
                                                </button>
                                            </div>
                                        </div>

                                    </ValidationObserver>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-show="active_step === 3">

                    <ValidationObserver ref="formstep3" class="form-cst">

                        <div class="row mt-4">
                            <div class="col-md-6">
                                <div class="card bdr-20">
                                    <div class="card-header">
                                        <h4>Package Member</h4>
                                    </div>
                                    <div class="card-body">
                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Other Package</label>
                                                <select class="form-control" v-model="formdata.package_id">
                                                    <option></option>
                                                    <option v-for="data in pck" :key="data.id" :value="data.id">{{data.category_name}} - {{data.name}}</option>
                                                </select>
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Subscribe Date</label>
                                                <input v-model="formdata.subscribe_date" type="date" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider rules="required" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Member Status</label>
                                                <select class="form-control" v-model="formdata.status">
                                                    <option></option>
                                                    <option value="ACTIVE">ACTIVE</option>
                                                    <option value="EXPIRED">EXPIRED</option>
                                                </select>
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <div class="form-group mt-3">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="formdata.is_show" class="custom-control-input" true-value="1" false-value="0" id="highlight9">
                                                <label class="custom-control-label" for="highlight9"><b class="ml-2">Member Showing Status</b></label>
                                            </div>
                                            <small>Check this if member showing on website</small>
                                        </div>
                                        <!-- <div class="form-group mt-3" v-if="this.$route.name == 'member-add'">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" v-model="formdata.is_confirm" class="custom-control-input" true-value="1" false-value="0" id="highlight8">
                                                <label class="custom-control-label" for="highlight8"><b class="ml-2">Email Confirmation</b></label>
                                            </div>
                                            <small>Check if you want send registration confirmation to member</small>
                                        </div> -->

                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6" v-if="this.$route.name == 'member-add'">
                                <div class="card bdr-20">
                                    <div class="card-header">
                                        <h4>User Option</h4>
                                    </div>
                                    <div class="card-body">
                                        <validation-provider rules="required|email" v-slot="{ errors }">
                                            <div class="form-group">
                                                <label>Email Login</label>
                                                <input v-model="formdata.email" type="text" class="form-control" name="name">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                            <!-- <div class="form-group mt-3">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" @change="formdata.password = ''" v-model="formdata.is_cpass" class="custom-control-input" true-value="1" false-value="0" id="cpass">
                                                    <label class="custom-control-label" for="cpass"><b class="ml-2">Custom Password Login</b></label>
                                                </div>
                                                <small>Check if you want to make custom password for this user</small>
                                            </div> -->

                                        <validation-provider name="confirm" rules="required|min:8" v-slot="{ errors }">
                                            <div class="form-group">
                                                <div class="d-block">
                                                    <label for="password" class="control-label">Password</label>
                                                </div>
                                                <input id="password" type="password" v-model="formdata.password" class="form-control" name="password">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>

                                        <validation-provider rules="required|password:@confirm" v-slot="{ errors }" v-if="formdata.password !== ''">
                                            <div class="form-group">
                                                <div class="d-block">
                                                    <label for="confirm" class="control-label">Confirmation Password</label>
                                                </div>
                                                <input id="confirm" type="password" v-model="formdata.confirmation" class="form-control" name="confirm">
                                                <div class="invalid-input">{{ errors[0] }}</div>
                                            </div>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ValidationObserver>
                </div>

            </div>
        </section>

    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
    import { mapState, mapActions, mapMutations} from 'vuex'
    import Alert from "../../components/Alert.vue";
    import options from "../../utils/editor";
    import Editor from '@tinymce/tinymce-vue'

    extend('password', {
        params: ['target'],
        validate(value, { target }) {
            return value === target;
        },
        message: 'Confirmation Password Not Same'
    });

    export default {
        data(){
            return{
                api_tiny:process.env.VUE_APP_TINYKEY,
                photo_file:'',
                tax_file:'',
                loading:false,
                loading_page:false,
                del_representatives:[],
                EditorOptions: options.Options,
                active_step:1,
                member_step:[
                    {id:1,step:'Member Information',icon:'uil uil-postcard'},
                    {id:2,step:'Representatives',icon:'uil uil-users-alt'},
                    {id:3,step:'Other Information',icon:'uil uil-package'}
                ]
            }
        },
        mounted(){
            if(this.$route.name == 'member-edit' && this.formdata.id == ''){
                this.loading_page = true
                this.editMember(this.$route.params.id).then(() => {
                    this.loading_page = false
                })
            }
            this.selectPackage()
            this.selectBusinessPrinciple()
            this.selectOrganizationType().then(() => {
                this.checkOther()
            })
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            Alert,
            Editor
        },
        watch:{
            errors:function(val){
                if(val.email){
                    this.$refs.alert.showAlert('warning','email login you used already exists, please using another email')
                    this.loading = false
                }
            }
        },
        computed:{
            ...mapState('MemberControl', {
                formdata: state => state.formdata,
                errors: state => state.errors,
            }),
            ...mapState('SelectControl', {
                pck: state => state.package,
                business_principle: state => state.business_principle,
                organization_type: state => state.organization_type,
            }),
        },
        methods:{
            ...mapActions('MemberControl', ['createMember','updateMember','editMember']),
            ...mapActions('SelectControl', ['selectPackage','selectBusinessPrinciple','selectOrganizationType']),
            ...mapMutations('MemberControl', ['CLEAR_FORM']),

            checkOther: function(){
                if(this.$route.name == 'member-edit'){
                    var organization_type = this.formdata.organization_type
                    if(this.organization_type.some(data => data.label === organization_type) == false){
                        this.formdata.organization_type = 'Other'
                    }
                }
            },
            onImageUpload: function(event) {
                var input = event.target;

                if (input.files && input.files[0]) {

                    var reader = new FileReader();
                    var vm = this;
                    reader.onload = function(e) {
                        vm.formdata.logo_url = e.target.result;
                    }
                    reader.readAsDataURL(input.files[0]);
                }
                this.photo_file = input.files[0]
            },
            removeImageUpload:function(){
                this.photo_file = ''
                this.formdata.logo_url = ''
            },
            onTaxFileUpload: function(event) {
                var input = event.target;

                if (input.files && input.files[0]) {

                    var reader = new FileReader();
                    var vm = this;
                    reader.onload = function(e) {
                        vm.formdata.tax_url = e.target.result;
                    }
                    reader.readAsDataURL(input.files[0]);
                }
                this.tax_file = input.files[0]
            },
            removeTaxFileUpload:function(){
                this.tax_file = ''
                this.formdata.tax_url = ''
            },
            addRepresentatives:function(){
                this.formdata.representatives.push({
                    id: Math.random().toString(36).substring(7),
                    first_name:'',
                    last_name:'',
                    position:'',
                    phone:'',
                    email:'',
                });
            },
            deleteRepresentatives:function(index,id){
                this.formdata.representatives.splice(index,1)
                this.del_representatives.push(id)
            },
            nextStep: function(){
                if(this.active_step == 1){

                    this.$refs.formstep1.validate().then(success => {
                        if (!success) {
                            setTimeout(() => {
                                const errors = Object.entries(this.$refs.formstep1.errors)
                                    .map(([key, value]) => ({ key, value }))
                                    .filter(error => error["value"].length);
                                this.$refs.formstep1.refs[errors[0]["key"]].$el.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center"
                                });
                            }, 100);
                        }else{
                            this.active_step = 2
                        }
                    });

                }else if(this.active_step == 2){

                    this.$refs.formstep2.validate().then(success => {
                        if (!success) {
                            setTimeout(() => {
                                const errors = Object.entries(this.$refs.formstep2.errors)
                                    .map(([key, value]) => ({ key, value }))
                                    .filter(error => error["value"].length);
                                this.$refs.formstep2.refs[errors[0]["key"]].$el.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center"
                                });
                            }, 100);
                        }else{
                            this.active_step = 3
                        }
                    });

                }else if(this.active_step == 3){

                    this.$refs.formstep3.validate().then(success => {
                        if (!success) {
                            setTimeout(() => {
                                const errors = Object.entries(this.$refs.formstep3.errors)
                                    .map(([key, value]) => ({ key, value }))
                                    .filter(error => error["value"].length);
                                this.$refs.formstep3.refs[errors[0]["key"]].$el.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center"
                                });
                            }, 100);
                        }else{

                            this.loading = true

                            var formData = new FormData();
                            formData.append('formdata', JSON.stringify(this.formdata));

                            if(this.photo_file){
                                formData.append('logo', this.photo_file);
                            }
                            if(this.tax_file){
                                formData.append('tax_file', this.tax_file);
                            }

                            if(this.$route.name == 'member-add'){
                                formData.append('email', this.formdata.email);

                                this.createMember(formData).then(() => {
                                    this.loading = false
                                    this.$refs.alert.showAlert('success','Successfully added member')
                                    this.photo_file = ''
                                    this.tax_file = ''
                                    this.$refs.formstep1.reset();
                                    this.$refs.formstep3.reset();
                                    this.active_step = 1
                                    this.CLEAR_FORM()
                                })

                            }else if(this.$route.name == 'member-edit'){

                                formData.append('del_representatives', this.del_representatives);
                                formData.append("_method", "put");
                                this.updateMember({id:this.$route.params.id,formData:formData}).then(() => {
                                    this.loading = false
                                    this.$refs.alert.showAlert('success','Successfully edited member')
                                    this.del_representatives = []
                                    this.active_step = 1
                                })

                            }


                        }
                    });
                }
            },
        },
        beforeRouteLeave (to, from, next) {
            this.CLEAR_FORM()
            next();
        }
    }

</script>
