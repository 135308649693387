import $api from '../utils/api.js'

export const Options = {
    height: 500,
    menubar: 'format tools table insert',
    menu : {
      insert: { title: 'Insert', items: 'link media image' },
      format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | removeformat' },
      table: { title: 'Table', items: 'inserttable tableprops deletetable | cell row column' }
    },
    plugins: [
    'advlist autolink lists link image charmap print preview hr anchor pagebreak',
    'searchreplace wordcount visualblocks visualchars code fullscreen',
    'insertdatetime media nonbreaking save table contextmenu directionality',
    'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc'
    ],
    // imagetools_cors_hosts: ['localhost'],
    // imagetools_credentials_hosts: ['localhost'],
    toolbar: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
    file_picker_types: 'image',
    images_upload_handler: function (blobInfo, success, failure) {
        let formData = new FormData();
        formData.append('vfile', blobInfo.blob());
        $api.post('/media', formData)
            .then(function (res) {
                success(res.data.result.file_url);
            })
            .catch(function (err) {
                failure('HTTP Error: ' + err.message);
            });
    }
}

export const Options2 = {
    height: 400,
    menubar: 'format tools table insert',
    menu : {
      insert: { title: 'Insert', items: 'link media image' },
      format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | removeformat' },
      table: { title: 'Table', items: 'inserttable tableprops deletetable | cell row column' }
    },
    plugins: [
    'advlist autolink lists link image charmap print preview hr anchor pagebreak',
    'searchreplace wordcount visualblocks visualchars code fullscreen',
    'insertdatetime media nonbreaking save table contextmenu directionality',
    'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc'
    ],
    toolbar: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
    file_picker_types: 'image',
    images_upload_handler: function (blobInfo, success, failure) {
        let formData = new FormData();
        formData.append('vfile', blobInfo.blob());
        $api.post('/media', formData)
            .then(function (res) {
                success(res.data.result.file_url);
            })
            .catch(function (err) {
                failure('HTTP Error: ' + err.message);
            });
    }
}

export default {
    Options,
}