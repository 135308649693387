import $api from '../../utils/api.js'


const state = () => ({
    package: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
    },
    sorting: {
        num:10,
        search: '',
        offset: 4,
        sort:'asc',
        placeholder:'Search Package'
    },
    formdata:{
        id:'',
        category_id:'',
        name:'',
        price:'',
        discount_price:'',
        price_tag:'',
        desc:'',
        is_active:'1',
        order:'',
        is_show:'1',
    },
    errors:[],
})

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.package = payload
    },
    ASSIGN_FORM(state, payload) {
        state.formdata = payload
    },
    CLEAR_FORM(state) {
        state.formdata = {
            id:'',
            category_id:'',
            name:'',
            price:'',
            discount_price:'',
            price_tag:'',
            desc:'',
            is_active:'1',
            order:'',
            is_show:'1',
        },
        state.errors = []
    },
    CLEAR_CATEGORY(state){
        state.package = {
            total: 0,
            per_page: 2,
            from: 1,
            to: 0,
            current_page: 1
        }
    },
    SET_ERRORS(state, payload) {
        state.errors = payload
    },
}

const actions = {
    getPackage({ commit, state }) {
        return new Promise((resolve) => {
            $api.get(`package?page=${state.package.current_page}&paginate=${state.sorting.num}&search=${state.sorting.search}&sort=${state.sorting.sort}`)
            .then((response) => {
                commit('ASSIGN_DATA', response.data)
                resolve(response.data)
            })
        })
    },
    createPackage({ commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`package`, payload)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    editPackage({ commit, state }, payload) {
        return new Promise((resolve) => {
            $api.get(`package?type=${state.sorting.type}&id=${payload}`)
            .then((response) => {
                commit('ASSIGN_FORM', response.data)
                resolve(response.data)
            })
        })
    },
    updatePackage({ state,commit }, payload) {
        return new Promise((resolve) => {
            $api.put(`package/${state.formdata.id}`, payload.formData)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    deletePackage({ dispatch }, payload) {
        return new Promise((resolve) => {
            $api.delete(`package/${payload}`)
            .then(() => {
                dispatch('getPackage').then(() => resolve())
            })
        })
    },
    
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}