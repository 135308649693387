import axios from 'axios';
import store from '@/store/index.js'

var apiUrl;

if(process.env.NODE_ENV == 'development'){

    apiUrl = 'http://127.0.0.1:8000/api/v1/admin'
    
}else if(process.env.NODE_ENV == 'production'){
    
    apiUrl = 'https://api.its-indonesia.org/api/v1/admin'
}

const $api = axios.create({
    baseURL: apiUrl,
    headers: {
        Authorization: localStorage.getItem('token') != 'null' ? 'Bearer ' + localStorage.getItem('token'):'',
        'Content-Type': 'application/json',
        'Accept' : 'application/json',
    }
});

$api.interceptors.request.use (
    function (config) {
        const token = store.state.AuthControl.token
        if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    function (error) {
        return Promise.reject (error);
    }
);

export default $api;