import $api from '../../utils/api.js'


const state = () => ({
    team: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
    },
    sorting: {
        num:10,
        search: '',
        offset: 4,
        sort:'asc',
        placeholder:'Search Team'
    },
    formdata:{
        id:'',
        name:'',
        position:'',
        other_position:'',
        desc:'',
        ig:'',
        fb:'',
        tw:'',
        in:'',
        order:'',
        photo_url:'',
        team:[]
    },
    errors:[],
})

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.team = payload
    },
    ASSIGN_FORM(state, payload) {
        state.formdata = {
            id:payload.id,
            name:payload.name,
            position:payload.position,
            other_position:payload.other_position,
            desc:payload.desc,
            ig:payload.ig,
            fb:payload.fb,
            tw:payload.tw,
            in:payload.in,
            order:payload.order,
            photo_url:payload.photo_url,
            team:payload.team,
        }
    },
    CLEAR_FORM(state) {
        state.formdata = {
            id:'',
            name:'',
            position:'',
            other_position:'',
            desc:'',
            ig:'',
            fb:'',
            tw:'',
            in:'',
            order:'',
            photo_url:'',
            team:[],
        },
        state.errors = []
    },
    CLEAR_CATEGORY(state){
        state.team = {
            total: 0,
            per_page: 2,
            from: 1,
            to: 0,
            current_page: 1
        }
    },
    SET_ERRORS(state, payload) {
        state.errors = payload
    },
}

const actions = {
    getTeam({ commit, state }) {
        return new Promise((resolve) => {
            $api.get(`team?page=${state.team.current_page}&paginate=${state.sorting.num}&search=${state.sorting.search}&sort=${state.sorting.sort}`)
            .then((response) => {
                commit('ASSIGN_DATA', response.data)
                resolve(response.data)
            })
        })
    },
    createTeam({ commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`team`, payload)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    editTeam({ commit, state }, payload) {
        return new Promise((resolve) => {
            $api.get(`team?type=${state.sorting.type}&id=${payload}`)
            .then((response) => {
                commit('ASSIGN_FORM', response.data)
                resolve(response.data)
            })
        })
    },
    updateTeam({ state,commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`team/${state.formdata.id}`, payload.formData)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    deleteTeam({ dispatch }, payload) {
        return new Promise((resolve) => {
            $api.delete(`team/${payload}`)
            .then(() => {
                dispatch('getTeam').then(() => resolve())
            })
        })
    },
    
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}