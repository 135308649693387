import { extend, localize } from "vee-validate";
import { min, required, email, numeric, mimes, max, min_value, confirmed,regex } from "vee-validate/dist/rules";

// import en from "vee-validate/dist/locale/en.json";

// Install rules
extend("required", required);
extend("min", min);
extend("email", email);
extend("numeric", numeric);
extend("mimes", mimes);
extend("max", max);
extend("min", min);
extend("regex", regex);
extend("min_value", min_value);
extend("confirmed", confirmed);
extend('url', {
  validate: (str) => {
      var pattern = new RegExp('^(http|https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
  },
  message: 'This is not a valid URL'
})

localize({
  en: {
    messages: {
      required: 'Please complete data',
      numeric: 'Please enter data in numeric format',
      mimes: 'Your file format you uploaded is wrong',
      max: 'Maximum characters you must input is {length}',
      min: 'Minimum characters you must input is {length}',
      email: 'Please enter a valid email format',
      min_value: 'Please enter a value of more than 1',
    }
  }
});