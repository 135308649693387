import $api from '../../utils/api.js'


const state = () => ({
    menu: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
    },
    sorting: {
        num:10,
        search: '',
        offset: 4,
        sort:'asc',
        placeholder:'Search Menu'
    },
    formdata:{
        id:'',
        name:'',
        menu_position:'',
        menu:[],
    },
    errors:[],
})

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.menu = payload
    },
    ASSIGN_FORM(state, payload) {
        state.formdata = payload
    },
    SET_POSITION_MENU(state, payload) {
        state.formdata.menu = payload
    },
    CLEAR_FORM(state) {
        state.formdata = {
            id:'',
            name:'',
            menu_position:'',
            menu:[],
        },
        state.errors = []
    },
    CLEAR_POPUP(state){
        state.menu = {
            total: 0,
            per_page: 2,
            from: 1,
            to: 0,
            current_page: 1
        }
    },
    SET_ERRORS(state, payload) {
        state.errors = payload
    },
}

const actions = {
    getMenu({ commit, state }) {
        return new Promise((resolve) => {
            $api.get(`navigation?page=${state.menu.current_page}&paginate=${state.sorting.num}&search=${state.sorting.search}&sort=${state.sorting.sort}`)
            .then((response) => {
                commit('ASSIGN_DATA', response.data)
                resolve(response.data)
            })
        })
    },
    createMenu({ commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`navigation`, payload)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    editMenu({ commit }, payload) {
        return new Promise((resolve) => {
            $api.get(`navigation?id=${payload}`)
            .then((response) => {
                commit('ASSIGN_FORM', response.data)
                resolve(response.data)
            })
        })
    },
    updateMenu({ state,commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`navigation/${state.formdata.id}`, payload.formData)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    deleteMenu({ dispatch }, payload) {
        return new Promise((resolve) => {
            $api.delete(`navigation/${payload}`)
            .then(() => {
                dispatch('getMenu').then(() => resolve())
            })
        })
    },
    
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}