import $api from '../../utils/api.js'


const state = () => ({
    slider: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
    },
    sorting: {
        num:10,
        search: '',
        offset: 4,
        sort:'asc',
        placeholder:'Search Slider'
    },
    formdata:{
        id:'',
        name:'',
        auto:'0',
        is_home_slide:'0',
        is_board_slide:'0',
        imageslider:[],
    },
    errors:[],
})

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.slider = payload
    },
    ASSIGN_FORM(state, payload) {
        state.formdata = payload
    },
    SET_POSITION_IMAGESLIDER(state, payload) {
        state.formdata.imageslider = payload
    },
    CLEAR_FORM(state) {
        state.formdata = {
            id:'',
            name:'',
            auto:'0',
            is_home_slide:'0',
            is_board_slide:'0',
            imageslider:[],
        },
        state.errors = []
    },
    CLEAR_POPUP(state){
        state.slider = {
            total: 0,
            per_page: 2,
            from: 1,
            to: 0,
            current_page: 1
        }
    },
    SET_ERRORS(state, payload) {
        state.errors = payload
    },
}

const actions = {
    getSlider({ commit, state }) {
        return new Promise((resolve) => {
            $api.get(`slider?page=${state.slider.current_page}&paginate=${state.sorting.num}&search=${state.sorting.search}&sort=${state.sorting.sort}`)
            .then((response) => {
                commit('ASSIGN_DATA', response.data)
                resolve(response.data)
            })
        })
    },
    createSlider({ commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`slider`, payload)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    editSlider({ commit, state }, payload) {
        return new Promise((resolve) => {
            $api.get(`slider?type=${state.sorting.type}&id=${payload}`)
            .then((response) => {
                commit('ASSIGN_FORM', response.data)
                resolve(response.data)
            })
        })
    },
    updateSlider({ state,commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`slider/${state.formdata.id}`, payload.formData)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    deleteSlider({ dispatch }, payload) {
        return new Promise((resolve) => {
            $api.delete(`slider/${payload}`)
            .then(() => {
                dispatch('getSlider').then(() => resolve())
            })
        })
    },
    
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}