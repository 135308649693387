import $api from '../../utils/api.js'


const state = () => ({
    post: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
    },
    sorting: {
        num:10,
        search: '',
        offset: 4,
        type:'',
        status:'',
        category:'',
        user:'',
        sort:'desc',
        placeholder:'Search Post'
    },
    formdata:{
        id:'',
        title:'',
        content:'',
        highlight:0,
        short_desc:'',
        status:'',
        post_date:'',
        post_time:'',
        post_location:'',
        post_download:'',
        post_register:'',
        meta_keywords:'',
        meta_description:'',
        meta_title:'',
        category_id:'',
        image_cover_url:'',
        tags:[],
        files:[],
    },
    errors:[],
})

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.post = payload
    },
    ASSIGN_FORM(state, payload) {
        state.formdata = payload
    },
    CLEAR_FORM(state) {
        state.formdata = {
            id:'',
            title:'',
            content:'',
            short_desc:'',
            highlight:0,
            status:'',
            post_date:'',
            post_time:'',
            post_location:'',
            post_register:'',
            post_download:'',
            meta_keywords:'',
            meta_description:'',
            meta_title:'',
            category_id:'',
            image_cover_url:'',
            tags:[],
            files:[],
        },
        state.errors = []
    },
    CLEAR_CATEGORY(state){
        state.post = {
            total: 0,
            per_page: 2,
            from: 1,
            to: 0,
            current_page: 1
        },
        state.sorting =  {
            num:10,
            search: '',
            offset: 4,
            type:'',
            status:'',
            category:'',
            user:'',
            sort:'desc',
            placeholder:'Search Post'
        }
    },
    SET_ERRORS(state, payload) {
        state.errors = payload
    },
}

const actions = {
    getPost({ commit, state }) {
        return new Promise((resolve) => {
            $api.get(`post?type=${state.sorting.type}&page=${state.post.current_page}&paginate=${state.sorting.num}&search=${state.sorting.search}&sort=${state.sorting.sort}&category=${state.sorting.category}&user_id=${state.sorting.user}&status=${state.sorting.status}`)
            .then((response) => {
                commit('ASSIGN_DATA', response.data)
                resolve(response.data)
            })
        })
    },
    createPost({ commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`post`, payload)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    editPost({ commit, state }, payload) {
        return new Promise((resolve) => {
            $api.get(`post?type=${state.sorting.type}&id=${payload}`)
            .then((response) => {
                commit('ASSIGN_FORM', response.data)
                resolve(response.data)
            })
        })
    },
    updatePost({ state,commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`post/${state.formdata.id}`, payload.formData)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    deletePost({ dispatch }, payload) {
        return new Promise((resolve) => {
            $api.delete(`post/${payload}`)
            .then(() => {
                dispatch('getPost').then(() => resolve())
            })
        })
    },

}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
