var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('alert',{ref:"alert"}),_c('section',{staticClass:"section"},[_c('div',{staticClass:"sec-cst section-header bdr-20 mt-4"},[_c('router-link',{staticClass:"back-sec",attrs:{"to":{name:'popup'}}},[_c('i',{staticClass:"uil uil-arrow-left"})]),_c('h1',{staticClass:"mt-0"},[_vm._v(" "+_vm._s(this.$route.meta.title)+" ")]),_c('div',{staticClass:"section-header-breadcrumb"},[_c('div',{staticClass:"button-modal text-right"},[_c('button',{staticClass:"btn btn-new",class:_vm.loading ? 'btn-progress' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.validationForm()}}},[_vm._v(" Save Data ")])])])],1),_c('div',{staticClass:"section-body mt-4"},[_c('ValidationObserver',{ref:"form",staticClass:"form-cst"},[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"card bdr-20"},[_c('div',{staticClass:"card-body"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Pop Up Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.name),expression:"formdata.name"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Pop Up Status")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.status),expression:"formdata.status"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formdata, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option'),_c('option',{attrs:{"value":"1"}},[_vm._v("ACTIVE")]),_c('option',{attrs:{"value":"0"}},[_vm._v("DEACTIVE")])]),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Page Url to Showing Pop Up")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.page_slug),expression:"formdata.page_slug"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.page_slug)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "page_slug", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))]),_c('small',[_vm._v("add url slug on website you want to showing this pop up. ex: /home")])])]}}])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Button Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.btn_name1),expression:"formdata.btn_name1"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.btn_name1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "btn_name1", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Popup Link")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.btn_url1),expression:"formdata.btn_url1"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.btn_url1)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "btn_url1", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Pop Up Description")]),_c('editor',{attrs:{"api-key":_vm.api_tiny,"init":_vm.EditorOptions},model:{value:(_vm.formdata.desc),callback:function ($$v) {_vm.$set(_vm.formdata, "desc", $$v)},expression:"formdata.desc"}})],1)],1)])]),_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"card bdr-20"},[_c('div',{staticClass:"card-body"},[(!_vm.formdata.image_cover_url)?_c('validation-provider',{attrs:{"rules":"mimes:image/jpeg,image/png,image/jpg"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"mb-1"},[_c('label',[_vm._v("Popup Photo")])]),_c('div',{staticClass:"btn-up d-flex align-items-center",on:{"click":function($event){$event.preventDefault();return _vm.$refs.inputImage.click()}}},[_c('div',{staticClass:"upload"},[_c('i',{staticClass:"uil uil-image mr-1"}),_vm._v(" ADD PHOTO")])]),_c('input',{ref:"inputImage",staticClass:"custom-file-input d-none",attrs:{"type":"file","id":"Thumbnail","accept":"image/jpeg,image/png,image/jpg"},on:{"change":_vm.onImageUpload,"input":validate}}),_c('div',{staticClass:"invalid-input mt-3"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,2988928614)}):_vm._e(),(_vm.formdata.image_cover_url)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"mb-1"},[_c('label',[_vm._v("Popup Photo")])]),_c('button',{staticClass:"btn-remove-image",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.removeImageUpload()}}},[_c('i',{staticClass:"uil uil-times"}),_vm._v(" Change Photo")]),_c('div',{staticClass:"input-group img-sh"},[_c('img',{staticClass:"w-100 img-responsive img-prf-up",attrs:{"src":_vm.formdata.image_cover_url}})])]):_vm._e()],1)])])])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }