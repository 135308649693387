<template>
    <div class="main-sidebar pb-5">
        <div class="sidebar-fb"></div>
        <aside id="sidebar-wrapper">
            <div class="sidebar-brand">
                <img src="@/assets/img/logo.png" alt="logo" width="100" class="mb-3 mt-2">
                <div v-on:click="menuOptions()" class="nv-ac2 mr-3">
                    <a href="#" data-toggle="sidebar" class="nav-link nav-link-lg p-0 w-100"><i class="uil uil-times"></i></a>
                </div>
            </div>
            <div class="sidebar-brand sidebar-brand-sm">
                <img src="@/assets/img/logo.png" alt="logo" width="100" class="mb-3 mt-2">
            </div>
            <ul class="sidebar-menu mt-5">
                <li class="menu-header">Menu Web Builder</li>
                <li>
                    <router-link :to="{name:'dashboard'}" class="nav-link" ><i class="uil uil-dashboard"></i> <span>Dashboard</span></router-link>
                </li>
                <li>
                    <router-link :to="{name:'settings'}" class="nav-link" ><i class="uil uil-setting"></i> <span>Settings</span></router-link>
                </li>
                <li>
                    <router-link :to="{name:'administrator'}"  class="nav-link"><i class="uil uil-user-circle"></i> <span>Administrator</span></router-link>
                </li>
                <li>
                    <router-link :to="{name:'pages'}"  class="nav-link"><i class="uil uil-layers"></i> <span>Pages</span></router-link>
                </li>
                <li class="nav-item dropdown" @click="dropdownOptions(1)">
                    <a href="#" class="nav-link has-dropdown"><i class="uil uil-layers"></i><span>News</span></a>
                    <ul class="dropdown-menu" :class="dropdown === 1 ? 'd-block' : ''">
                        <li><router-link :to="{name:'news'}"  class="nav-link">News</router-link></li>
                        <li><router-link :to="{name:'category', params: { type: 'news' }}"  class="nav-link">Categories</router-link></li>
                        <li><router-link :to="{name:'tag', params: { type: 'news' }}"  class="nav-link">Tags</router-link></li>
                    </ul>
                </li>
                <li class="nav-item dropdown" @click="dropdownOptions(2)">
                    <a href="#" class="nav-link has-dropdown"><i class="uil uil-layers"></i><span>Events</span></a>
                    <ul class="dropdown-menu" :class="dropdown === 2 ? 'd-block' : ''">
                        <li><router-link :to="{name:'events'}"  class="nav-link">Event</router-link></li>
                        <li><router-link :to="{name:'category', params: { type: 'events' }}"  class="nav-link">Categories</router-link></li>
                        <li><router-link :to="{name:'tag', params: { type: 'events' }}"  class="nav-link">Tags</router-link></li>
                    </ul>
                </li>
                <li>
                    <router-link :to="{name:'program'}"  class="nav-link"><i class="uil uil-layers"></i> <span>Program</span></router-link>
                </li>
                <li>
                    <router-link :to="{name:'teams'}"  class="nav-link"><i class="uil uil-user-circle"></i> <span>Teams</span></router-link>
                </li>
                <li>
                    <router-link :to="{name:'menu'}"  class="nav-link"><i class="uil uil-bars"></i> <span>Menus</span></router-link>
                </li>

                <li>
                    <router-link :to="{name:'popup'}"  class="nav-link"><i class="uil uil-comment-alt-notes"></i> <span>Pop Up</span></router-link>
                </li>

                <li>
                    <router-link :to="{name:'slider'}"  class="nav-link"><i class="uil uil-meeting-board"></i> <span>Slide</span></router-link>
                </li>

                <li>
                    <router-link :to="{name:'subscriber'}"  class="nav-link"><i class="uil uil-envelopes"></i> <span>Subscriber</span></router-link>
                </li>
                <li>
                    <router-link :to="{name:'message'}"  class="nav-link"><i class="uil uil-envelopes"></i> <span>Message</span></router-link>
                </li>

                <li class="menu-header">Menu Membership</li>

                 <li class="nav-item dropdown" @click="dropdownOptions(5)">
                    <a href="#" class="nav-link has-dropdown"><i class="uil uil-package"></i><span>Packages Member</span></a>
                    <ul class="dropdown-menu" :class="dropdown === 5 ? 'd-block' : ''">
                            <li><router-link :to="{name:'category-package'}"  class="nav-link">Category</router-link></li>
                        <li><router-link :to="{name:'package'}"  class="nav-link">Packages</router-link></li>
                    </ul>
                </li>

                <li class="nav-item dropdown" @click="dropdownOptions(3)">
                    <a href="#" class="nav-link has-dropdown"><i class="uil uil-users-alt"></i><span>Members</span></a>
                    <ul class="dropdown-menu" :class="dropdown === 3 ? 'd-block' : ''">
                            <li><router-link :to="{name:'member'}"  class="nav-link">Members Directory</router-link></li>
                        <li><router-link :to="{name:'member-payment'}"  class="nav-link">Members Payment</router-link></li>
                    </ul>
                </li>
              
                <li class="nav-item dropdown" @click="dropdownOptions(4)">
                    <a href="#" class="nav-link has-dropdown"><i class="uil uil-books"></i><span>Knowledge Center</span></a>
                    <ul class="dropdown-menu" :class="dropdown === 4 ? 'd-block' : ''">
                        <li><router-link :to="{name:'category', params: { type: 'knowledge' }}"  class="nav-link">Categories</router-link></li>
                        <li><router-link :to="{name:'knowledge'}"  class="nav-link">Knowledge</router-link></li>
                    </ul>
                </li>
            </ul>
        </aside>
    </div>
</template>

<script>
    import { mapState,mapMutations } from 'vuex'

    export default {
        data(){
            return {
                dropdown :null,
            }
        },
        computed:{
            ...mapState('AuthControl', {
                menu: state => state.menu,
            }),
        },
        methods: {
            ...mapMutations('AuthControl', ['CHANGE_MENU']),
            
            dropdownOptions: function(val){
                if(val == this.dropdown){
                    this.dropdown = null
                }else{
                    this.dropdown = val
                }
            },
            menuOptions: function(){
                if(this.menu == true){
                    this.CHANGE_MENU(false)
                }else{
                    this.CHANGE_MENU(true)
                }
            },
        }
    }
</script>