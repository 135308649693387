<template>
        <div class="main-content">
            <section class="section">
                <div class="sec-cst section-header bdr-20 mt-4">
                    <h1 class="mt-0 py-2">
                        {{this.$route.meta.title}}
                    </h1>
                </div>
                <div class="section-body mt-4">
                    <div class="row mt-4">
                        <div class="col-12 text-center mt-3 hv-100" v-if="no_data === null">
                            <div class="half-circle-spinner">
                                <div class="circle circle-1"></div>
                                <div class="circle circle-2"></div>
                            </div>
                        </div>
                        <div class="col-12" v-if="no_data === false">
                            <div class="card bdr-20">
                                <div class="card-header border-0 pt-4 pb-0 tb-card">
                                    <div class="table-sorting">
                                        <div class="table-search">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group mb-0">
                                                        <input :placeholder="sorting.placeholder" @keyup="searchSettings()" v-model="sorting.search" type="text" class="form-control" >
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <select class="form-control" @change="getSettings" v-model="sorting.group">
                                                            <option value="" selected>Choose Settings Group</option>
                                                            <option value="social">SOCIAL MEDIA</option>
                                                            <option value="contact">CONTACT</option>
                                                            <option value="web">WEBSITE</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <select class="form-control" @change="getSettings"  v-model="sorting.type">
                                                            <option value="" selected>Choose Settings Type</option>
                                                            <option value="text">TEXT</option>
                                                            <option value="url">URL</option>
                                                            <option value="textarea">TEXTAREA</option>
                                                            <option value="image">IMAGE</option>
                                                            <option value="video">VIDEO</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body px-0 pb-0">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Settings Option</th>
                                                    <th>Key</th>
                                                    <th>Type</th>
                                                    <th>Group</th>
                                                    <th>Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="tr-ac" v-for="data in settings" :key="data.id" v-on:click="_editSettings(data)">
                                                    <td width="200px">{{data.display_name}}</td>
                                                    <td>{{data.key}}</td>
                                                    <td class="text-uppercase">{{data.type}}</td>
                                                    <td class="text-uppercase">{{data.group}}</td>
                                                    <td>{{data.details}}</td>
                                                </tr>
                                            
                                            </tbody>
                                            
                                        </table>
                                        <p v-if="settings.length === 0" class="text-center no-data">Settings data not found</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-if="no_data === true && sorting.search === ''">
                            <div class="card bdr-20 py-5">
                                <div class="card-body card-nitem text-center py-5">
                                    <img class="mb-4" width="150px" src="@/assets/img/no-data.svg" alt="">
                                    <div class="nitem-desc">
                                        <h3>Settings Data Still Empty</h3>
                                        <p>On this page you can configure setting item</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </template>
    
    <script>
        import { mapActions, mapState, mapMutations} from 'vuex'
    
        export default {
            data(){
                return {
                    no_data: null,
                    loading:false,
                }
            },
            mounted(){
                this.getSettings().then(() => {
                    if(this.settings.total == 0){
                        this.no_data = true
                    }else{
                        this.no_data = false
                    }
                })
            },
            computed:{
                ...mapState('SettingsControl', {
                    settings: state => state.settings,
                    sorting: state => state.sorting,
                }),
            },
            methods: {
                ...mapActions('SettingsControl', ['getSettings','deleteSettings']),
                ...mapMutations('SettingsControl', ['ASSIGN_FORM']),
                
                searchSettings: function(){
                    clearTimeout(this.timeout);
                    
                    var self = this;
                    this.timeout = setTimeout(function () {
                        self.getSettings()
                    }, 500);
                },
                _editSettings: function(data){
                    this.ASSIGN_FORM(data)
                    this.$router.push({ name: 'settings-edit', params: { id: data.id } })
                },
            }
        }
    
    </script>