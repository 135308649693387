import $api from '../../utils/api.js'


const state = () => ({
    message: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
    },
    sorting: {
        num:10,
        search: '',
        offset: 4,
        sort:'asc',
        placeholder:'Search Message'
    },
    errors:[],
})

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.message = payload
    },
    CLEAR_CATEGORY(state){
        state.message = {
            total: 0,
            per_page: 2,
            from: 1,
            to: 0,
            current_page: 1
        }
    },
}

const actions = {
    getMessage({ commit, state }) {
        return new Promise((resolve) => {
            $api.get(`message?page=${state.message.current_page}&paginate=${state.sorting.num}&search=${state.sorting.search}&sort=${state.sorting.sort}`)
            .then((response) => {
                commit('ASSIGN_DATA', response.data)
                resolve(response.data)
            })
        })
    },
    exportMessage({ state }) {
        return new Promise((resolve) => {
            $api.get(`export-message?page=${state.message.current_page}&paginate=${state.sorting.num}&search=${state.sorting.search}&sort=${state.sorting.sort}`, {responseType: 'arraybuffer'})
            .then((response) => {
                resolve(response.data)
            })
        })
    },
    changeStatus({ dispatch }, payload) {
        return new Promise((resolve) => {
            $api.put(`message/${payload.ids}`, {status:payload.status})
            .then(() => {
                dispatch('getMessage').then(() => resolve())
            })
        })
    },
    
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}