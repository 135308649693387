<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <router-link class="back-sec" :to="{name:'slider'}">
                    <i class="uil uil-arrow-left"></i>
                </router-link>
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <button v-on:click="validationForm()" type="button" class="btn btn-new"  v-bind:class="loading ? 'btn-progress' : ''">
                            Save Data
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">

                <ValidationObserver ref="form" class="form-cst">

                    <div class="row mt-4">
                        <div class="col-md-4">
                            <div class="card bdr-20">
                                <div class="card-body">
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Slider Name</label>
                                            <input v-model="formdata.name" type="text" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <div class="form-group mt-3">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" v-model="formdata.auto" class="custom-control-input" true-value="1" false-value="0" id="highlight">
                                            <label class="custom-control-label" for="highlight"><b class="ml-2">Slide auto play</b></label>
                                        </div>
                                    </div>

                                    <div class="form-group mt-3">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" v-model="formdata.is_home_slide" class="custom-control-input" true-value="1" false-value="0" id="highlight2">
                                            <label class="custom-control-label" for="highlight2"><b class="ml-2">Slide Home</b></label>
                                        </div>
                                    </div>

                                    <div class="form-group mt-3">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" v-model="formdata.is_board_slide" class="custom-control-input" true-value="1" false-value="0" id="highlight3">
                                            <label class="custom-control-label" for="highlight3"><b class="ml-2">Slide Board Comissioner</b></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">

                            <draggable
                                v-bind="dragOptions"
                                v-model="ImageSlder"
                                handle=".handle"
                                @start="drag = true"
                                @end="drag = false"
                                >

                                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                                    <div v-for="(data,index) in ImageSlder" @click="openSlide(data.id)" :class="[{ 'active' : active_gallery === data.id}]" class="mb-3 drag-gallery" :key="data.id" >
                                        <div class="link" :class="[{ 'active' : active_gallery === data.id}]">
                                            <div v-on:click.stop="" class="link-drag handle align-items-center d-flex">
                                                <i class="uil uil-draggabledots"></i>
                                            </div>
                                            <div class="link-body cp">
                                                <div class="link-content">
                                                    <div class="link-item pr-5">
                                                        <div><strong>Slide {{index + 1}}</strong></div>
                                                    </div>
                                                    <div class="link-switch d-flex" v-on:click.stop="">
                                                        <a href="#" v-on:click.prevent="deleteSlide(index,data.id)" class="act mb-0">
                                                            <i class="uil uil-trash-alt"></i>
                                                        </a>
                                                        <a href="#" v-on:click.prevent="openSlide(data.id)" class="act mb-0">
                                                            <i :class="[active_gallery === data.id ? 'uil uil-angle-up' : 'uil uil-angle-down']"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="gallery-wrapper" v-on:click.stop="">
                                            <validation-provider v-if="!data.image_url" rules="required|mimes:image/jpeg,image/png,image/jpg" v-slot="{ errors, validate }">
                                                <div class="form-group">
                                                    <div class="mb-1">
                                                        <label>Slide Image</label>
                                                    </div>
                                                    <div class="btn-up d-flex align-items-center" v-on:click.prevent="inputImage(index)">
                                                        <div class="upload"><i class="uil uil-image mr-1"></i> ADD PHOTO</div>
                                                    </div>
                                                    <input :ref="'inputImage' + index"
                                                            :id="'inputImage' + index"
                                                            type="file"
                                                            class="custom-file-input d-none"
                                                            v-on:change="onImageUpload($event,index)"
                                                            accept="image/jpeg,image/png,image/jpg"
                                                            @input="validate" >

                                                    <div class="invalid-input mt-3">{{ errors[0] }}</div>
                                                </div>
                                            </validation-provider>

                                            <div class="form-group" v-if="data.image_url">
                                                <div class="mb-1">
                                                    <label>Event Image Cover</label>
                                                </div>
                                                <button v-on:click.prevent="removeImageUpload(index)" type="button" class="btn-remove-image"><i class="uil uil-times"></i> Change Photo</button>
                                                <div class="input-group img-sh">
                                                    <img :src="data.image_url" class="w-100 img-responsive img-prf-up">
                                                </div>
                                            </div>

                                            <div class="row align-items-center">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label>Slide Title</label>
                                                        <input v-model="data.title" type="text" class="form-control" name="name">
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label>Slide Sub Title</label>
                                                        <input v-model="data.sub_title" type="text" class="form-control" name="name">
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Button 1 Name</label>
                                                        <input v-model="data.btn1" type="text" class="form-control" name="name">
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <validation-provider rules="url" v-slot="{ errors }">
                                                        <div class="form-group">
                                                            <label>Button 1 Link</label>
                                                            <input v-model="data.link1" @keydown.space.prevent type="text" class="form-control" name="name">
                                                            <div class="invalid-input">{{ errors[0] }}</div>
                                                        </div>
                                                    </validation-provider>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group mb-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" v-model="data.link1_target" class="custom-control-input" true-value="_blank" false-value="_self" :id="'target1' + index">
                                                            <label class="custom-control-label" :for="'target1' + index"><b class="ml-2">New Tab</b></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Buton 2 Name</label>
                                                        <input v-model="data.btn2" type="text" class="form-control" name="name">
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <validation-provider rules="url" v-slot="{ errors }">
                                                        <div class="form-group">
                                                            <label>Button 2 Link</label>
                                                            <input v-model="data.link2" @keydown.space.prevent type="text" class="form-control" name="name">
                                                            <div class="invalid-input">{{ errors[0] }}</div>
                                                        </div>
                                                    </validation-provider>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group mb-0">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" v-model="data.link2_target" class="custom-control-input" true-value="_blank" false-value="_self" :id="'target2' + index">
                                                            <label class="custom-control-label" :for="'target2' + index"><b class="ml-2">New Tab</b></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label>Slide Description</label>
                                                        <textarea v-model="data.desc" class="form-control"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group mt-3">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" v-model="data.is_night" class="custom-control-input" true-value="1" false-value="0" :id="'night' + index">
                                                            <label class="custom-control-label" :for="'night' + index"><b class="ml-2">Night Slide</b></label>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </transition-group>

                            </draggable>

                            <button v-on:click="addSlideImage()" type="button" class="btn btn-new">
                                Add Slide
                            </button>
                        </div>

                    </div>

                </ValidationObserver>

            </div>
        </section>

    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import { mapState, mapActions, mapMutations} from 'vuex'
    import Alert from "../../components/Alert.vue";
    import draggable from 'vuedraggable'

    export default {
        data(){
            return{
                loading:false,
                drag: false,
                active_gallery:'',
                del_slide:[],
            }
        },
        mounted(){
            if(this.$route.name == 'slider-edit' && this.formdata.id == ''){
                this.editSlider(this.$route.params.id)
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            Alert,
            draggable
        },
        computed:{
            ...mapState('SliderControl', {
                formdata: state => state.formdata,
                errors: state => state.errors,
            }),

            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
            ImageSlder: {
                get() {
                    return this.formdata.imageslider
                },
                set(value) {
                    this.SET_POSITION_IMAGESLIDER(value)
                }
            }
        },
        methods:{
            ...mapActions('SliderControl', ['createSlider','updateSlider','editSlider','updatePositionImageSlider']),
            ...mapMutations('SliderControl', ['CLEAR_FORM','SET_POSITION_IMAGESLIDER']),

            inputImage:function(index){
                this.$refs['inputImage' + index][0].click()
            },
            openSlide: function(id){
                if(id == this.active_gallery){
                    this.active_gallery = ''
                }else{
                    this.active_gallery = id
                }
            },
            addSlideImage:function(){
                var slidecount = this.formdata.imageslider.length + 1
                this.formdata.imageslider.push({
                    id: Math.random().toString(36).substring(7),
                    title:'Slide ' + slidecount,
                    sub_title:'',
                    btn1:'',
                    btn2:'',
                    link1:'',
                    link2:'',
                    link1_target:'_self',
                    link2_target:'_self',
                    image_url:'',
                    image_file:'',
                    desc:'',
                    is_night:'0',
                });
            },
            onImageUpload: function(event,index) {
                var input = event.target;

                if (input.files && input.files[0]) {

                    var reader = new FileReader();
                    var vm = this;
                    reader.onload = function(e) {
                        vm.formdata.imageslider[index].image_url = e.target.result;
                    }
                    reader.readAsDataURL(input.files[0]);
                }
                this.formdata.imageslider[index].image_file = input.files[0]
            },
            removeImageUpload:function(index){
                this.formdata.imageslider[index].image_file = ''
                this.formdata.imageslider[index].image_url = ''
            },
            deleteSlide:function(index,id){
                this.formdata.imageslider.splice(index,1)
                this.del_slide.push(id)
            },
            validationForm: function() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.form.errors)
                                .map(([key, value]) => ({ key, value }))
                                .filter(error => error["value"].length);
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100);
                    }else{

                        var formData = new FormData();
                        formData.append('name', this.formdata.name);
                        formData.append('auto', this.formdata.auto);
                        formData.append('is_board_slide', this.formdata.is_board_slide);
                        formData.append('is_home_slide', this.formdata.is_home_slide);

                        formData.append('imageslider', JSON.stringify(this.formdata.imageslider));

                        for(let i=0; i<this.formdata.imageslider.length;i++){
                            var file = new File([""], "null");
                            if(this.formdata.imageslider[i].image_file){
                                file =  this.formdata.imageslider[i].image_file
                            }
                            formData.append('imagefile[]', file);
                        }

                        this.loading = true

                        if(this.$route.name == 'slider-add'){

                            this.createSlider(formData).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully added slider')
                                this.$refs.form.reset();
                                this.CLEAR_FORM()
                            })

                        }else if(this.$route.name == 'slider-edit'){

                            formData.append('del_slide', this.del_slide);
                            formData.append("_method", "put");
                            this.updateSlider({id:this.$route.params.id,formData:formData}).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully edited slider')
                                this.$refs.form.reset();
                                this.del_slide = []
                            })

                        }

                    }
                });
            },
        },
        beforeRouteLeave (to, from, next) {
            this.CLEAR_FORM()
            next();
        }
    }

</script>
