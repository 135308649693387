<template>
    <div class="main-content">

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <router-link class="back-sec" :to="{name:'member'}">
                    <i class="uil uil-arrow-left"></i>
                </router-link>
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
            </div>
            <div class="section-body mt-4">

                <div class="wizard-steps wizard-steps-p">
                    <div v-for="data in member_step"
                        :key="data.id"
                        :class="active_step === data.id ? 'wizard-step-active' : ''"
                        class="wizard-step"
                        @click="active_step = data.id"
                    >
                        <div class="wizard-step-icon">
                            <i :class="data.icon"></i>
                        </div>
                        <div class="wizard-step-label">
                            {{data.step}}
                        </div>
                    </div>
                </div>

                <template v-if="active_step === 1">
     
                    <div class="row mt-4">
                        <div class="col-md-6">
                            <div class="card bdr-20">
                                <div class="card-header">
                                    <h4>Basic Information</h4>
                                </div>
                                <div class="card-body">
                                    <table class="table-info-m w-100">
                                        <tr>
                                            <td>
                                                <div class="form-group" v-if="formdata.logo_url">   
                                                    <div class="mb-1">
                                                        <label>Organization Logo</label>
                                                    </div> 
                                                    <div class="input-group img-sh">
                                                        <img :src="formdata.logo_url" class="w-100 img-responsive img-prf-up">
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Organization Name</td>
                                            <td>:</td>
                                            <td>{{formdata.organization_name}}</td>
                                        </tr>
                                        <tr>
                                            <td>Business Address</td>
                                            <td>:</td>
                                            <td>{{formdata.business_address}}</td>
                                        </tr>
                                        <tr>
                                            <td>Business Phone</td>
                                            <td>:</td>
                                            <td>{{formdata.business_phone}}</td>
                                        </tr>
                                        <tr>
                                            <td>Business Fax</td>
                                            <td>:</td>
                                            <td>{{formdata.business_fax}}</td>
                                        </tr>
                                        <tr>
                                            <td>Business Email</td>
                                            <td>:</td>
                                            <td>{{formdata.business_email}}</td>
                                        </tr>
                                        <tr>
                                            <td>Tax ID</td>
                                            <td>:</td>
                                            <td>{{formdata.tax_id ? formdata.tax_id : '-' }}</td>
                                        </tr>
                                       
                                        <tr>
                                            <td>Join Date</td>
                                            <td>:</td>
                                            <td>{{formdata.join_date}}</td>
                                        </tr>
                                        <tr>
                                            <td>Business Principle</td>
                                            <td>:</td>
                                            <td>{{formdata.business_principle}}</td>
                                        </tr>
                                        <tr>
                                            <td>Type of Organization</td>
                                            <td>:</td>
                                            <td>{{formdata.organization_type}}</td>
                                        </tr>
                                        <tr>
                                            <td>File Attachment Type</td>
                                            <td>:</td>
                                            <td>{{formdata.tax_file_type ? formdata.tax_file_type : '-' }}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="form-group" v-if="formdata.logo_url">   
                                                    <div class="mb-1">
                                                        <label>Photo {{formdata.tax_file_type}}</label>
                                                    </div> 
                                                    <div class="input-group img-sh">
                                                        <img :src="formdata.tax_url" class="w-100 img-responsive img-prf-up">
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>  
                        </div>
                        <div class="col-md-6">
                            <div class="card bdr-20">
                                <div class="card-header">
                                    <h4>Contact Information Association</h4>
                                </div>
                                <div class="card-body">
                                     <table class="table-info-m w-100">
                                        <tr>
                                            <td>Name</td>
                                            <td>:</td>
                                            <td>{{formdata.pic_name}}</td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>:</td>
                                            <td>{{formdata.pic_address}}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td>:</td>
                                            <td>{{formdata.pic_phone}}</td>
                                        </tr>
                                        <tr>
                                            <td>Fax</td>
                                            <td>:</td>
                                            <td>{{formdata.pic_fax}}</td>
                                        </tr>
                                        <tr>
                                            <td>Mobile</td>
                                            <td>:</td>
                                            <td>{{formdata.pic_mobile}}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>:</td>
                                            <td>{{formdata.pic_email}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            
                            <div class="card bdr-20">
                                <div class="card-header">
                                    <h4>Social Media</h4>
                                </div>
                                <div class="card-body">
                                     <table class="table-info-m w-100">
                                        <tr>
                                            <td width="100px">Website</td>
                                            <td width="10px">:</td>
                                            <td><a :href="formdata.website ? formdata.website : '#'">{{formdata.website ? formdata.website : '-'}}</a></td>
                                        </tr>
                                        <tr>
                                            <td>Instagram</td>
                                            <td>:</td>
                                            <td><a :href="formdata.ig ? formdata.ig : '#'">{{formdata.ig ? formdata.ig : '-'}}</a></td>
                                        </tr>
                                        <tr>
                                            <td>Facebook</td>
                                            <td>:</td>
                                            <td><a :href="formdata.fb ? formdata.fb : '#'">{{formdata.fb ? formdata.fb : '-'}}</a></td>
                                        </tr>
                                        <tr>
                                            <td>Youtube</td>
                                            <td>:</td>
                                            <td><a :href="formdata.yt ? formdata.yt : '#'">{{formdata.yt ? formdata.yt : '-'}}</a></td>
                                        </tr>
                                        <tr>
                                            <td>Twitter</td>
                                            <td>:</td>
                                            <td><a :href="formdata.tw ? formdata.tw : '#'">{{formdata.tw ? formdata.tw : '-'}}</a></td>
                                        </tr>
                                        <tr>
                                            <td>Linkedin</td>
                                            <td>:</td>
                                            <td><a :href="formdata.in ? formdata.in : '#'">{{formdata.in ? formdata.in : '-'}}</a></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                             <div class="card bdr-20">
                                <div class="card-header">
                                    <h4>Description Member</h4>
                                </div>
                                <div class="card-body">
                                    <div v-html="formdata.desc"></div>  
                                </div>
                            </div>
                        </div>
                    </div>

                </template>

                <template v-if="active_step === 2">
                    <div class="row">
                        <div class="col-md-12">
                             <div class="card bdr-20">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                    <th>Position</th>
                                                    <th>Phone</th>
                                                    <th>Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="data in formdata.representatives" :key="data.id">
                                                    <td>{{data.first_name}}</td>
                                                    <td>{{data.last_name}}</td>
                                                    <td>{{data.position}}</td>
                                                    <td>{{data.phone}}</td>
                                                    <td>{{data.email}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="active_step === 3">
                    
                    <div class="row mt-4">
                        <div class="col-md-6">
                            <div class="card bdr-20">
                                <div class="card-header">
                                    <h4>Package Information</h4>
                                </div>
                                <div class="card-body">
                                     <table class="table-info-m w-100">
                                        <tr>
                                            <td>Package Name</td>
                                            <td>:</td>
                                            <td>{{formdata.packages.category_name}} - {{formdata.package_name}}</td>
                                        </tr>
                                        <tr>
                                            <td>Subscribe Date</td>
                                            <td>:</td>
                                            <td>{{formdata.subscribe_date}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="card bdr-20">
                                <div class="card-header">
                                    <h4>Other Information</h4>
                                </div>
                                <div class="card-body">
                                     <table class="table-info-m w-100">
                                        <tr>
                                            <td>Member Status</td>
                                            <td>:</td>
                                            <td>
                                                <label v-if="formdata.status === 'ACTIVE'" class="badge badge-success">{{formdata.status}}</label>
                                                <label v-if="formdata.status === 'INACTIVE'"  class="badge badge-warning">{{formdata.status}}</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Member Showing in Website</td>
                                            <td>:</td>
                                            <td>
                                                <label v-if="formdata.is_show === '1'" class="badge badge-success">YES</label>
                                                <label v-if="formdata.is_show === '0'"  class="badge badge-warning">NO</label>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>

                </template>

                <template v-if="active_step === 4">
                    <div class="row">
                        <div class="col-md-12">
                             <div class="card bdr-20">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Invoice</th>
                                                    <th>External ID</th>
                                                    <th>Member</th>
                                                    <th>Package</th>
                                                    <th>Subs. Date</th>
                                                    <th>Due Date</th>
                                                    <th>Amount</th>
                                                    <th>Fee</th>
                                                    <th>Fee Fixed</th>
                                                    <th>VAT 10%</th>
                                                    <th>Amount Total</th>
                                                    <th>Payment Method</th>
                                                    <th>Status</th>
                                                    <th>Paid At</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="data in formdata.payments" :key="data.id">
                                                    <td>#{{data.inv_number}}</td>
                                                    <td>{{data.external_id}}</td>
                                                    <td>{{data.member_name}}</td>
                                                    <td>{{data.packages.category_name}} - {{data.packages.name}}</td>
                                                    <td>{{data.subscribe_start  |  formatDate('MMMM DD, YYYY')}} - {{data.subscribe_end  |  formatDate('MMMM DD, YYYY')}}</td>
                                                    <td>{{data.inv_due_date  |  formatDate('MMMM DD, YYYY HH:MM')}}</td>
                                                    <td>{{data.amount | formatRupiah}}</td>
                                                    <td>{{data.payment_fee | formatRupiah}}</td>
                                                    <td>{{data.payment_fee_fixed | formatRupiah}}</td>
                                                    <td>{{data.payment_vat | formatRupiah}}</td>
                                                    <td>{{data.payment_total | formatRupiah}}</td>
                                                    <td>{{data.payment_type}} - {{data.payment_method}}</td>
                                                    <td>
                                                        <label v-if="data.status === 'PAID'" class="badge badge-success">{{data.status}}</label>
                                                        <label v-if="data.status === 'UNPAID'"  class="badge badge-warning">{{data.status}}</label>
                                                        <label v-if="data.status === 'EXPIRED'"  class="badge badge-danger">{{data.status}}</label>
                                                    </td>
                                                    <td>{{data.paid_at  |  formatDate('MMMM DD, YYYY HH:MM')}}</td>
                                                    <td width="20px" @click.stop="">
                                                        <b-dropdown>
                                                            <template #button-content>
                                                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"> <g> <g> <g> <circle cx="256" cy="256" r="64"/> <circle cx="256" cy="448" r="64"/> <circle cx="256" cy="64" r="64"/> </g> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                                                            </template>
                                                            <b-dropdown-item  v-on:click="_exportInvioce(data)" href="#"><i class="uil uil-file-download-alt"></i> Download Invioce</b-dropdown-item>
                                                        </b-dropdown>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="text-right font-weight-bold" colspan="6">Total</th>
                                                    <td class="font-weight-bold">{{formdata.payments | totalItem('amount') | formatRupiah}}</td>
                                                    <td class="font-weight-bold">{{formdata.payments | totalItem('payment_fee') | formatRupiah}}</td>
                                                    <td class="font-weight-bold">{{formdata.payments | totalItem('payment_fee_fixed') | formatRupiah}}</td>
                                                    <td class="font-weight-bold">{{formdata.payments | totalItem('payment_vat') | formatRupiah}}</td>
                                                    <td class="font-weight-bold">{{formdata.payments | totalItem('payment_total') | formatRupiah}}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </section>

    </div>
</template>

<script>
    import { mapState, mapActions, mapMutations} from 'vuex'
    import moment from 'moment'

    export default {
        data(){
            return{
                active_step:1,
                member_step:[
                    {id:1,step:'Member Information',icon:'uil uil-postcard'},
                    {id:2,step:'Representatives',icon:'uil uil-users-alt'},
                    {id:3,step:'Package Information',icon:'uil uil-package'},
                    {id:4,step:'Payments',icon:'uil uil-receipt'},
                ]
            }
        },
        mounted(){
            if(this.$route.name == 'member-view' && this.formdata.id == ''){
                this.editMember(this.$route.params.id)
            }
            this.selectOrganizationType().then(() => {
                this.checkOther()
            })
        },
        filters: {
            formatDate: function(val,format){
                if(val){
                    return moment(String(val)).format(format)
                }else{
                    return '-'
                }
            },
            totalItem: function(all_data,key){
                var total = 0;
                all_data.forEach(data => {
                    total = total + parseFloat(data[key])
                });
                return total
            },
            formatRupiah: function(value){
                if(value){
                    let val = (value/1).toFixed(0).replace('.', ',')
                    return "Rp. " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }else{
                    return '-'
                }
               
            },
        },
        computed:{
            ...mapState('MemberControl', {
                formdata: state => state.formdata,
            }),
            ...mapState('SelectControl', {
                organization_type: state => state.organization_type,
            }),
        },
        methods:{
            ...mapActions('MemberControl', ['editMember']),
            ...mapActions('PaymentControl', ['exportInvioce']),
            ...mapActions('SelectControl', ['selectOrganizationType']),
            ...mapMutations('MemberControl', ['CLEAR_FORM']),

            checkOther: function(){
                var organization_type = this.formdata.organization_type

                if(this.organization_type.some(data => data.label === organization_type) == false){
                    this.formdata.organization_type = 'Other'
                }
            },
            _exportInvioce:function(data){
                this.exportInvioce(data.id).then(response => {
                    let blob = new Blob([response], {type:'application/pdf'})
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'Invoice - ' + data.member_name  +' - '+ data.inv_number + '.pdf'
                    link.click();
                    blob = null;
                })
            },
        },
        beforeRouteLeave (to, from, next) {
            this.CLEAR_FORM()
            next();
        }
    }

</script>