<template>
  <div id="app">
    <div class="main-wrapper" :class="{'main-menu-hide': menu == false}">
      <div class="navbar-bg"></div>
      <router-view name="header"/>
      <router-view name="sidebar"/>
      <router-view name="content"/>
      <router-view name="footer" />
    </div>
  </div>
</template>

<script>
  import { mapState} from 'vuex'

  export default {
    name: 'App',
    computed : {
      ...mapState('AuthControl', {
          menu: state => state.menu,
      }),
    },
  }
</script>
