import $api from '../../utils/api.js'


const state = () => ({
    popup: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
    },
    sorting: {
        num:10,
        search: '',
        offset: 4,
        sort:'asc',
        placeholder:'Search Popup'
    },
    formdata:{
        id:'',
        name:'',
        desc:'',
        btn_name1:'',
        btn_name2:'',
        btn_url1:'',
        btn_url2:'',
        status:'',
        page_slug:'',
        image_cover_url:'',
    },
    errors:[],
})

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.popup = payload
    },
    ASSIGN_FORM(state, payload) {
        state.formdata = payload
    },
    CLEAR_FORM(state) {
        state.formdata = {
            id:'',
            name:'',
            desc:'',
            btn_name1:'',
            btn_name2:'',
            btn_url1:'',
            btn_url2:'',
            status:'',
            page_slug:'',
            image_cover_url:'',
        },
        state.errors = []
    },
    CLEAR_POPUP(state){
        state.popup = {
            total: 0,
            per_page: 2,
            from: 1,
            to: 0,
            current_page: 1
        }
    },
    SET_ERRORS(state, payload) {
        state.errors = payload
    },
}

const actions = {
    getPopup({ commit, state }) {
        return new Promise((resolve) => {
            $api.get(`popup?page=${state.popup.current_page}&paginate=${state.sorting.num}&search=${state.sorting.search}&sort=${state.sorting.sort}`)
            .then((response) => {
                commit('ASSIGN_DATA', response.data)
                resolve(response.data)
            })
        })
    },
    createPopup({ commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`popup`, payload)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    editPopup({ commit, state }, payload) {
        return new Promise((resolve) => {
            $api.get(`popup?type=${state.sorting.type}&id=${payload}`)
            .then((response) => {
                commit('ASSIGN_FORM', response.data)
                resolve(response.data)
            })
        })
    },
    updatePopup({ state,commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`popup/${state.formdata.id}`, payload.formData)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    deletePopup({ dispatch }, payload) {
        return new Promise((resolve) => {
            $api.delete(`popup/${payload}`)
            .then(() => {
                dispatch('getPopup').then(() => resolve())
            })
        })
    },
    
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}