<template>
    <section class="section">
        <alert ref="alert"></alert>

        <div class="d-flex flex-wrap align-items-stretch">
            <div class="col-lg-6 col-12 order-1 min-vh-100 bg-login position-relative overlay-gradient-bottom"  v-bind:style="{ backgroundImage: 'url(' + require(`@/assets/img/bg-login2.jpg`) + ')' }">
                <div class="absolute-bottom-left index-2">
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12 min-vh-100 order-2 bg-white px-5">
                <div class="p-5 m-5 text-center">
                    <img src="@/assets/img/logo.png" alt="logo" width="150" class="mb-3 mt-2">
                    <h4 class="text-dark font-weight-bold mb-5">Web Builder System</h4>

                    <ValidationObserver ref="form" class="form-cst text-left">
                        
                        <validation-provider rules="required" v-slot="{ errors }">                
                            <div class="form-group">
                                <label for="username">Username</label>
                                <input id="username" v-model="username" type="text" class="form-control" name="username" tabindex="1" required autofocus>
                                <div class="invalid-input">{{ errors[0] }}</div>
                            </div>
                        </validation-provider>

                        <validation-provider rules="required" v-slot="{ errors }">
                            <div class="form-group">
                                <label for="password" class="control-label">Password</label>
                                <input id="password" v-model="password" type="password" class="form-control" name="password" tabindex="2" required>
                                <div class="invalid-input">{{ errors[0] }}</div>
                            </div>
                        </validation-provider>

                        <div class="form-group">
                            <button type="button" @click.prevent="_login()" v-bind:class="loading ? 'btn-progress' : ''" class="btn btn-block btn-primary btn-lg btn-icon icon-right" tabindex="4">
                            Login
                            </button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import Alert from "@/components/Alert.vue";

    export default {
        data(){
            return {
                username : "",
                password : "",
                loading:false,
            }
        },
        components: {
            Alert,
            ValidationProvider,
            ValidationObserver,
        },
        watch:{
            errors:function(val){
                if(val.errors == 'l-failed'){
                    this.loading = false
                    this.$refs.alert.showAlert('warning','Your username & password not found')
                }else if(val.errors == 'l-wrong'){
                    this.loading = false
                    this.$refs.alert.showAlert('warning','Your username & password are wrong')
                }
            }
        },
        computed:{
            ...mapState('AuthControl', {
                errors: state => state.errors
            }),
        },
        methods: {
            ...mapActions('AuthControl', ['login']),

            _login: function(){
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.form.errors)
                                .map(([key, value]) => ({ key, value }))
                                .filter(error => error["value"].length);
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100);
                    }else{

                        this.loading = true
                        this.login({username:this.username,password:this.password}).then(() => {
                            this.loading = false
                            this.$router.push({name : 'dashboard'})
                        })
                        .catch((e) => {
                            console.error(e)
                            this.loading = false
                        })
                        
                    }
                })
            }
        }
    }
</script>