var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('alert',{ref:"alert"}),_c('section',{staticClass:"section"},[_c('div',{staticClass:"sec-cst section-header bdr-20 mt-4"},[_c('router-link',{staticClass:"back-sec",attrs:{"to":{name:'settings'}}},[_c('i',{staticClass:"uil uil-arrow-left"})]),_c('h1',{staticClass:"mt-0"},[_vm._v(" "+_vm._s(this.$route.meta.title)+" ")]),_c('div',{staticClass:"section-header-breadcrumb"},[_c('div',{staticClass:"button-modal text-right"},[_c('button',{staticClass:"btn btn-new",class:_vm.loading ? 'btn-progress' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.validationForm()}}},[_vm._v(" Save Data ")])])])],1),_c('div',{staticClass:"section-body mt-4"},[_c('ValidationObserver',{ref:"form",staticClass:"form-cst"},[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"card bdr-20"},[_c('div',{staticClass:"card-body"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Setting Option Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.display_name),expression:"formdata.display_name"}],staticClass:"form-control",attrs:{"disabled":"","type":"text","name":"name"},domProps:{"value":(_vm.formdata.display_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "display_name", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Setting Option Key")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.key),expression:"formdata.key"}],staticClass:"form-control",attrs:{"disabled":"","type":"text","name":"name"},domProps:{"value":(_vm.formdata.key)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "key", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])}),(_vm.formdata.type === 'textarea')?[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Settings Value")]),_c('editor',{attrs:{"api-key":_vm.api_tiny,"init":_vm.EditorOptions},model:{value:(_vm.formdata.value),callback:function ($$v) {_vm.$set(_vm.formdata, "value", $$v)},expression:"formdata.value"}})],1)]:_vm._e(),(_vm.formdata.type === 'text')?[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Setting Value")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.value),expression:"formdata.value"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "value", $event.target.value)}}})])]:_vm._e(),(_vm.formdata.type === 'url')?[_c('validation-provider',{attrs:{"rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Setting Value")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.value),expression:"formdata.value"}],staticClass:"form-control",attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formdata.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "value", $event.target.value)}}}),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,1857091924)})]:_vm._e(),(_vm.formdata.type === 'image')?[(!_vm.formdata.image_url)?_c('validation-provider',{attrs:{"rules":"mimes:image/jpeg,image/png,image/jpg"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"mb-1"},[_c('label',[_vm._v("Setting Value")])]),_c('div',{staticClass:"btn-up d-flex align-items-center",on:{"click":function($event){$event.preventDefault();return _vm.$refs.inputImage.click()}}},[_c('div',{staticClass:"upload"},[_c('i',{staticClass:"uil uil-image mr-1"}),_vm._v(" ADD PHOTO")])]),_c('input',{ref:"inputImage",staticClass:"custom-file-input d-none",attrs:{"type":"file","id":"Thumbnail","accept":"image/jpeg,image/png,image/jpg"},on:{"change":_vm.onImageUpload,"input":validate}}),_c('div',{staticClass:"invalid-input mt-3"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,1762760573)}):_vm._e(),(_vm.formdata.image_url)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"mb-1"},[_c('label',[_vm._v("Setting Value")])]),_c('button',{staticClass:"btn-remove-image",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.removeImageUpload()}}},[_c('i',{staticClass:"uil uil-times"}),_vm._v(" Change Photo")]),_c('div',{staticClass:"input-group img-sh"},[_c('img',{staticClass:"w-100 img-responsive img-prf-up",attrs:{"src":_vm.formdata.image_url}})])]):_vm._e()]:_vm._e(),(_vm.formdata.type === 'video')?[(!_vm.formdata.image_url)?_c('validation-provider',{attrs:{"rules":"mimes:video/mp4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"mb-1"},[_c('label',[_vm._v("Setting Value")])]),_c('div',{staticClass:"btn-up d-flex align-items-center",on:{"click":function($event){$event.preventDefault();return _vm.$refs.inputImage.click()}}},[_c('div',{staticClass:"upload"},[_c('i',{staticClass:"uil uil-image mr-1"}),_vm._v(" ADD PHOTO")])]),_c('input',{ref:"inputImage",staticClass:"custom-file-input d-none",attrs:{"type":"file","id":"Thumbnail","accept":"video/mp4"},on:{"change":_vm.onImageUpload,"input":validate}}),_c('div',{staticClass:"invalid-input mt-3"},[_vm._v(_vm._s(errors[0]))])])]}}],null,false,969908030)}):_vm._e(),(_vm.formdata.image_url)?_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"mb-1"},[_c('label',[_vm._v("Setting Value")])]),_c('button',{staticClass:"btn-remove-image",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.removeImageUpload()}}},[_c('i',{staticClass:"uil uil-times"}),_vm._v(" Change Photo")]),_c('div',{staticClass:"input-group img-sh"},[_c('img',{staticClass:"w-100 img-responsive img-prf-up",attrs:{"src":_vm.formdata.image_url}})])]):_vm._e()]:_vm._e()],2)])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card bdr-20"},[_c('div',{staticClass:"card-body"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Setting Group")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.group),expression:"formdata.group"}],staticClass:"form-control",attrs:{"disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formdata, "group", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option'),_c('option',{attrs:{"value":"social"}},[_vm._v("SOCIAL MEDIA")]),_c('option',{attrs:{"value":"contact"}},[_vm._v("CONTACT")]),_c('option',{attrs:{"value":"web"}},[_vm._v("WEBSITE")])]),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Setting Type")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.type),expression:"formdata.type"}],staticClass:"form-control",attrs:{"disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formdata, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option'),_c('option',{attrs:{"value":"text"}},[_vm._v("TEXT")]),_c('option',{attrs:{"value":"url"}},[_vm._v("URL")]),_c('option',{attrs:{"value":"textarea"}},[_vm._v("TEXTAREA")]),_c('option',{attrs:{"value":"image"}},[_vm._v("IMAGE")]),_c('option',{attrs:{"value":"video"}},[_vm._v("VIDEO")])]),_c('div',{staticClass:"invalid-input"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Setting Function Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formdata.details),expression:"formdata.details"}],staticClass:"form-control",attrs:{"disabled":"","name":"name"},domProps:{"value":(_vm.formdata.details)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formdata, "details", $event.target.value)}}})])],1)])])])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }