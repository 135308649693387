<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <h1 class="mt-0 py-2">
                    {{this.$route.meta.title}}
                </h1>

            </div>
            <div class="section-body mt-4">
                <div class="row mt-4">
                    <div class="col-12 text-center mt-3 hv-100" v-if="no_data === null">
                        <div class="half-circle-spinner">
                            <div class="circle circle-1"></div>
                            <div class="circle circle-2"></div>
                        </div>
                    </div>
                    <div class="col-12" v-if="no_data === false">
                        <div class="card bdr-20">
                            <div class="card-header border-0 py-4 tb-card">
                                <div class="table-sorting">
                                    <div class="table-search float-left">
                                        <vue-search
                                            :search="sorting"
                                            @searchdata="getMessage()">
                                        </vue-search>
                                    </div>
                                </div>
                                <div class="button-modal text-right">
                                    <button  v-bind:class="loading_export ? 'btn-progress' : ''" class="btn btn-new2" @click="_exportMessage()"><i class="uil uil-file-export mr-2"></i> Export Excel</button>
                                </div>
                            </div>
                            <div class="card-body px-0 pb-0">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th width="20px">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="all_id" v-model="selectAll">
                                                        <label class="custom-control-label" for="all_id"></label>
                                                    </div>
                                                </th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Subject</th>
                                                <th>Message</th>
                                                <th>Status</th>
                                                <th>Created Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="tr-ac" v-for="data in message.data" :key="data.id" v-on:click="editMessage(data)">
                                                <td width="20px" v-on:click.stop="">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" v-model="del_message" :value="data.id" :id="data.id">
                                                        <label class="custom-control-label" :for="data.id"></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>{{data.first_name}} / {{data.last_name}}</div>
                                                </td>
                                                <td>{{data.email}}</td>
                                                <td>{{data.subject}}</td>
                                                <td>{{data.message}}</td>
                                                <td>
                                                    <label v-if="data.is_read === '1'" class="badge badge-success">READ</label>
                                                    <label v-if="data.is_read === '0'"  class="badge badge-warning">NO READ</label>
                                                </td>
                                                <td>{{data.created_at |  formatDate('MMMM DD, YYYY')}}</td>
                                            </tr>

                                        </tbody>

                                    </table>
                                    <p v-if="message.total === 0" class="text-center no-data">Message data not found</p>
                                </div>
                            </div>
                            <div class="card-footer text-right">
                                <vue-pagination :pagination="message"
                                                :sorting="sorting"
                                                @paginate="getMessage()">
                                </vue-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" v-if="no_data === true && sorting.search === ''">
                        <div class="card bdr-20 py-5">
                            <div class="card-body card-nitem text-center py-5">
                                <img class="mb-4" width="150px" src="@/assets/img/no-data.svg" alt="">
                                <div class="nitem-desc">
                                    <h3>Message Data Still Empty</h3>
                                    <p>On this page you can read all message from contact form in website</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-action" v-if="del_message.length !== 0">
                <div class="card-body">
                    <div class="float-left text-white mt-1">
                        <span v-on:click.prevent="del_message = []" class="times"><i class="uil uil-times"></i></span>
                        <span><span class="badge">{{del_message.length}}</span> data selected</span>
                    </div>
                    <div class="float-right">
                        <div v-b-modal.modal-Status class="btn btn-grey">
                            <i class="uil uil-pencil"></i> Change Status
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <b-modal id="modal-Message" title="Message" hide-footer centered>
            <table class="table-info-m w-100">
                <tr>
                    <td width="200px">Status</td>
                    <td width="10px">:</td>
                    <td>
                        <label v-if="show_message.is_read === '1'" class="badge badge-success">READ</label>
                        <label v-if="show_message.is_read === '0'"  class="badge badge-warning">NO READ</label>
                    </td>
                </tr>
                <tr>
                    <td width="200px">First Name</td>
                    <td width="10px">:</td>
                    <td>{{show_message.first_name ? show_message.first_name : '-'}}</td>
                </tr>
                <tr>
                    <td>Last Name</td>
                    <td>:</td>
                    <td>{{show_message.last_name ? show_message.last_name : '-'}}</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>:</td>
                    <td>{{show_message.email ? show_message.email : '-'}}</td>
                </tr>
                <tr>
                    <td>Message Subject</td>
                    <td>:</td>
                    <td>{{show_message.subject ? show_message.subject : '-'}}</td>
                </tr>
                <tr>
                    <td>Message</td>
                    <td>:</td>
                </tr>
                <tr>
                    <td>{{show_message.message ? show_message.message : '-'}}</td>
                </tr>
            </table>
        </b-modal>

        <b-modal id="modal-Status" hide-footer hide-header centered>
            <label>Message read status</label>
            <div class="form-group mb-0">
                <select class="form-control" v-model="status">
                    <option selected value="">Choose Status</option>
                    <option value="1">READ</option>
                    <option value="0">NOT READ</option>
                </select>
            </div>

            <div class="button-modal text-right mt-4">
                <button v-on:click="_changeStatus()" type="button" class="btn btn-new"  v-bind:class="loading ? 'btn-progress' : ''">
                    Change Status
                </button>
            </div>

        </b-modal>

    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations} from 'vuex'
    import VuePagination from "../../components/Pagination.vue";
    import VueSearch from "../../components/Search.vue";
    import Alert from "../../components/Alert.vue";
    import moment from 'moment'

    export default {
        components: {
            VuePagination,
            VueSearch,
            Alert
        },
        data(){
            return {
                no_data: null,
                loading:false,
                loading_export:false,
                status:'',
                del_message:[],
                show_message:{},
            }
        },
        mounted(){
            this.getMessage().then(() => {
                if(this.message.total == 0){
                    this.no_data = true
                }else{
                    this.no_data = false
                }
            })
        },
        filters: {
            formatDate: function(val,format){
                if(val){
                    return moment(String(val)).format(format)
                }else{
                    return '-'
                }
            },
        },
        computed:{
            ...mapState('MessageControl', {
                message: state => state.message,
                sorting: state => state.sorting,
                errors: state => state.errors,
            }),

            selectAll: {
                get: function () {
                    return this.message.data ? this.del_message.length == this.message.data.length : false;
                },
                set: function (value) {
                    var selected = [];

                    if (value) {
                        this.message.data.forEach(function (message) {
                            selected.push(message.id);
                        });
                    }
                    this.del_message = selected;
                }
            }
        },
        methods: {
            ...mapActions('MessageControl', ['getMessage','changeStatus','exportMessage']),
            ...mapMutations('MessageControl', ['ASSIGN_FORM']),

            editMessage: function(data){
                this.show_message = data
                this.$bvModal.show('modal-Message')
            },
            _changeStatus:function(){
                this.loading = true
                var form = {ids: this.del_message, status: this.status}
                this.changeStatus(form).then(() => {
                    this.loading = false
                    this.del_message = []
                    this.status = ''
                    this.$bvModal.hide('modal-Status')
                    this.$refs.alert.showAlert('success','Successfully update status message')
                })
            },
            _exportMessage() {
                this.loading_export = true
                this.exportMessage().then(response => {
                    let blob = new Blob([response], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'message.xlsx'
                    link.click();
                    blob = null;
                    this.loading_export = false
                })
            },
        }
    }

</script>
