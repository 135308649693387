<template>
    <div class="nav-wrapper">
        <div class="navbar-bg"></div>
        <nav class="navbar navbar-expand-lg main-navbar">
            <form class="form-inline mr-auto">
                <ul class="navbar-nav mr-3 d-flex align-items-center">
                    <li v-on:click="menuOptions()" class="nv-ac mr-3"><a href="#" data-toggle="sidebar" class="nav-link nav-link-lg"><i class="uil uil-bars"></i></a></li>
                </ul>
            </form>
            <ul class="navbar-nav navbar-right">
            <li class="dropdown">
                <a href="#" @click.prevent="dropdownActive" data-toggle="dropdown" class="nav-link dropdown-toggle nav-link-lg nav-link-user">
                    <img alt="image" :src="user.photo_url" class="rounded-circle mr-2">
                    <div class="d-sm-none d-lg-inline-block">{{user.name}}</div>
                </a>
                <div class="dropdown-menu dropdown-menu-right" :class="dropdown === true ? 'show' : ''">
                    <a href="#" @click.prevent="_logout()" class="dropdown-item has-icon text-danger">
                        <i class="uil uil-sign-out-alt"></i> Log Out
                    </a>
                </div>
            </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    import { mapState,mapMutations,mapActions } from 'vuex'

    export default {
        data(){
            return{
                window_width:0,
                dropdown:false
            }
        },
        created(){
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        watch:{
            window_width:function(val){
                if(val < 769){
                    this.CHANGE_MENU(false)
                }
            },
        },
        computed:{
            ...mapState('AuthControl', {
                user: state => state.user,
                menu: state => state.menu,
            }),
        },
        methods: {
            ...mapActions('AuthControl', ['logout']),
            ...mapMutations('AuthControl', ['CHANGE_MENU']),
            
            _logout: function () {
                this.logout().then(() => {
                    this.$router.push('/login')
                })
            },
            dropdownActive:function(){
                if(this.dropdown == false){
                    this.dropdown = true
                }else{
                    this.dropdown = false
                }
            },
            handleResize() {
                this.window_width = window.innerWidth;
            },
            menuOptions: function(){
                if(this.menu == true){
                    this.CHANGE_MENU(false)
                }else{
                    this.CHANGE_MENU(true)
                }
            }
        }
    }

</script>