import $api from '../../utils/api.js'


const state = () => ({
    admins: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
    },
    sorting: {
        num:10,
        search: '',
        offset: 4,
        sort:'desc',
        placeholder:'Search Administrator'
    },
    formdata:{
        id:'',
        name:'',
        photo_url:'',
        username:'',
        password:'',
        confirmation:'',
    },
    errors:[],
})

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.admins = payload
    },
    ASSIGN_FORM(state, payload) {
        state.formdata = {
            id:payload.id,
            name:payload.name,
            photo_url:payload.photo_url,
            username:payload.username,
            password:'',
            confirmation:'',
        }
    },
    CLEAR_FORM(state) {
        state.formdata = {
            id:'',
            name:'',
            photo_url:'',
            username:'',
            password:'',
            confirmation:'',
        },
        state.errors = []
    },
    CLEAR_ADMINS(state){
        state.admins = {
            total: 0,
            per_page: 2,
            from: 1,
            to: 0,
            current_page: 1
        }
    },
    SET_ERRORS(state, payload) {
        state.errors = payload
    },
}

const actions = {
    getAdmins({ commit, state }) {
        return new Promise((resolve) => {
            $api.get(`user?page=${state.admins.current_page}&paginate=${state.sorting.num}&search=${state.sorting.search}&sort=${state.sorting.sort}`)
            .then((response) => {
                commit('ASSIGN_DATA', response.data)
                resolve(response.data)
            })
        })
    },
    editAdmins({ commit }, payload) {
        return new Promise((resolve) => {
            $api.get(`user?id=${payload}`)
            .then((response) => {
                commit('ASSIGN_FORM', response.data)
                resolve(response.data)
            })
        })
    },
    createAdmins({ commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`user`, payload)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    updateAdmins({ commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`user/${payload.id}`, payload.formData)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    deleteAdmins({ dispatch }, payload) {
        return new Promise((resolve) => {
            $api.delete(`user/${payload}`)
            .then(() => {
                dispatch('getAdmins').then(() => resolve())
            })
        })
    },
    
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}