<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <router-link class="back-sec" :to="{name:'popup'}">
                    <i class="uil uil-arrow-left"></i>
                </router-link>
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <button v-on:click="validationForm()" type="button" class="btn btn-new"  v-bind:class="loading ? 'btn-progress' : ''">
                            Save Data
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">
                
                <ValidationObserver ref="form" class="form-cst">

                    <div class="row mt-4">
                        <div class="col-md-7">
                            <div class="card bdr-20">
                                <div class="card-body">

                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Pop Up Name</label>
                                            <input v-model="formdata.name" type="text" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Pop Up Status</label>
                                            <select class="form-control" v-model="formdata.status">
                                                <option></option>
                                                <option value="1">ACTIVE</option>
                                                <option value="0">DEACTIVE</option>
                                            </select>
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Page Url to Showing Pop Up</label>
                                            <input v-model="formdata.page_slug" @keydown.space.prevent type="text" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                            <small>add url slug on website you want to showing this pop up. ex: /home</small>
                                        </div>
                                    </validation-provider>

                                    <div class="row">

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Button Name</label>
                                                <input v-model="formdata.btn_name1" type="text" class="form-control" name="name">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <validation-provider rules="url" v-slot="{ errors }">
                                                <div class="form-group">
                                                    <label>Popup Link</label>
                                                    <input v-model="formdata.btn_url1" @keydown.space.prevent type="text" class="form-control" name="name">
                                                    <div class="invalid-input">{{ errors[0] }}</div>
                                                </div>
                                            </validation-provider>
                                        </div>
                                    </div>


                                    <div class="form-group">
                                        <label>Pop Up Description</label>
                                        <editor
                                            v-model="formdata.desc"
                                            :api-key="api_tiny"
                                            :init="EditorOptions"
                                        />
                                    </div>
                                
                                </div>
                            </div>  
                          
                        </div>
                        <div class="col-md-5">
                            <div class="card bdr-20">
                                <div class="card-body">
                                    <validation-provider v-if="!formdata.image_cover_url" rules="mimes:image/jpeg,image/png,image/jpg" v-slot="{ errors, validate }">
                                        <div class="form-group">
                                            <div class="mb-1">
                                                <label>Popup Photo</label>
                                            </div>
                                            <div class="btn-up d-flex align-items-center" v-on:click.prevent="$refs.inputImage.click()">
                                                <div class="upload"><i class="uil uil-image mr-1"></i> ADD PHOTO</div>
                                            </div>
                                            <input ref="inputImage" 
                                                    type="file"
                                                    class="custom-file-input d-none"
                                                    id="Thumbnail"
                                                    v-on:change="onImageUpload"
                                                    accept="image/jpeg,image/png,image/jpg" 
                                                    @input="validate" >
                        
                                            <div class="invalid-input mt-3">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>
                                    
                                    <div class="form-group" v-if="formdata.image_cover_url">   
                                        <div class="mb-1">
                                            <label>Popup Photo</label>
                                        </div> 
                                        <button v-on:click.prevent="removeImageUpload()" type="button" class="btn-remove-image"><i class="uil uil-times"></i> Change Photo</button> 
                                        <div class="input-group img-sh">
                                            <img :src="formdata.image_cover_url" class="w-100 img-responsive img-prf-up">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </ValidationObserver>

            </div>
        </section>

    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import { mapState, mapActions, mapMutations} from 'vuex'
    import Alert from "../../components/Alert.vue";
    import options from "../../utils/editor";
    import Editor from '@tinymce/tinymce-vue'

    export default {
        data(){
            return{
                api_tiny:process.env.VUE_APP_TINYKEY,
                photo_file:'',
                loading:false,
                EditorOptions: options.Options
            }
        },
        mounted(){
            if(this.$route.name == 'popup-edit' && this.formdata.id == ''){
                this.editPopup(this.$route.params.id)
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            Alert,
            Editor
        },
        computed:{
            ...mapState('PopupControl', {
                formdata: state => state.formdata,
                errors: state => state.errors,
            }),
        },
        methods:{
            ...mapActions('PopupControl', ['createPopup','updatePopup','editPopup']),
            ...mapMutations('PopupControl', ['CLEAR_FORM']),
            
            onImageUpload: function(event) {
                var input = event.target;

                if (input.files && input.files[0]) {

                    var reader = new FileReader();
                    var vm = this;
                    reader.onload = function(e) {
                        vm.formdata.image_cover_url = e.target.result;
                    }
                    reader.readAsDataURL(input.files[0]);
                }
                this.photo_file = input.files[0]
            },
            removeImageUpload:function(){
                this.photo_file = ''
                this.formdata.image_cover_url = ''
            },
            validationForm: function() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.form.errors)
                                .map(([key, value]) => ({ key, value }))
                                .filter(error => error["value"].length);
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100);
                    }else{

                        var formData = new FormData();
                        formData.append('name', this.formdata.name);
                        formData.append('desc', this.formdata.desc ? this.formdata.desc : '');
                        formData.append('status', this.formdata.status);
                        formData.append('page_slug', this.formdata.page_slug);
                        formData.append('btn_name1', this.formdata.btn_name1 ? this.formdata.btn_name1 : '');
                        formData.append('btn_name2', this.formdata.btn_name2 ? this.formdata.btn_name2 : '');
                        formData.append('btn_url1', this.formdata.btn_url1 ? this.formdata.btn_url1 : '');
                        formData.append('btn_url2', this.formdata.btn_url2 ? this.formdata.btn_url2 : '');
                        
                        if(this.photo_file){
                            formData.append('image_cover', this.photo_file);
                        }
                        this.loading = true
                        
                        if(this.$route.name == 'popup-add'){

                            this.createPopup(formData).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully added popup')
                                this.photo_file = ''
                                this.$refs.form.reset();
                                this.CLEAR_FORM()
                            }) 

                        }else if(this.$route.name == 'popup-edit'){

                            formData.append("_method", "put");
                            this.updatePopup({id:this.$route.params.id,formData:formData}).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully edited popup')
                                this.$refs.form.reset();
                                this.photo_file = ''
                            }) 

                        }
                       
                    }
                });
            },
        },
        beforeRouteLeave (to, from, next) {
            this.CLEAR_FORM()
            next();
        }
    }

</script>