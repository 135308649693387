<template>
    <div class="main-content">
        <alert ref="alert"></alert>

        <section class="section">
            <div class="sec-cst section-header bdr-20 mt-4">
                <router-link class="back-sec" :to="{name:'category-package'}">
                    <i class="uil uil-arrow-left"></i>
                </router-link>
                <h1 class="mt-0">
                    {{this.$route.meta.title}}
                </h1>
                <div class="section-header-breadcrumb">
                    <div class="button-modal text-right">
                        <button v-on:click="validationForm()" type="button" class="btn btn-new"  v-bind:class="loading ? 'btn-progress' : ''">
                            Save Data
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-body mt-4">
                
                <ValidationObserver ref="form" class="form-cst">

                    <div class="row mt-4">
                        <div class="col-md-8">
                            <div class="card bdr-20">
                                <div class="card-body">

                                    <validation-provider rules="required|numeric" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Position Order</label>
                                            <input v-model="formdata.order" type="number" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>

                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label>Category Name</label>
                                            <input v-model="formdata.name" type="text" class="form-control" name="name">
                                            <div class="invalid-input">{{ errors[0] }}</div>
                                        </div>
                                    </validation-provider>
                                </div>
                            </div>  
                          
                        </div>
                        <div class="col-md-4">
                            <div class="card bdr-20">
                                <div class="card-body">
                                    <div class="form-group mt-3">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" v-model="formdata.is_active" class="custom-control-input" true-value="1" false-value="0" id="is_active">
                                            <label class="custom-control-label" for="is_active">
                                                <b class="ml-2">Category Active Status</b>
                                            </label>
                                        </div>
                                        <small>Check this if package in this category showing on member registration on website</small>
                                    </div>

                                    <div class="form-group mt-3">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" v-model="formdata.is_show" class="custom-control-input" true-value="1" false-value="0" id="is_show">
                                            <label class="custom-control-label" for="is_show"><b class="ml-2">Category Showing Status</b></label>
                                        </div>
                                        <small>Check this if member in this category showing on website</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </ValidationObserver>

            </div>
        </section>

    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from "vee-validate";
    import { mapState, mapActions, mapMutations} from 'vuex'
    import Alert from "../../components/Alert.vue";

    export default {
        data(){
            return{
                loading:false,
            }
        },
        mounted(){
            if(this.$route.name == 'category-package-edit' && this.formdata.id == ''){
                this.editCategory(this.$route.params.id)
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            Alert,
        },
        computed:{
            ...mapState('CategoryPackageControl', {
                formdata: state => state.formdata,
                errors: state => state.errors,
            }),
        },
        methods:{
            ...mapActions('CategoryPackageControl', ['createCategory','updateCategory','editCategory']),
            ...mapMutations('CategoryPackageControl', ['CLEAR_FORM']),
            
            validationForm: function() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        setTimeout(() => {
                            const errors = Object.entries(this.$refs.form.errors)
                                .map(([key, value]) => ({ key, value }))
                                .filter(error => error["value"].length);
                            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            });
                        }, 100);
                    }else{
                        
                        this.loading = true

                        if(this.$route.name == 'category-package-add'){

                            this.createCategory(this.formdata).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully added category')
                                this.$refs.form.reset();
                                this.CLEAR_FORM()
                            }) 

                        }else if(this.$route.name == 'category-package-edit'){

                            this.updateCategory({id:this.$route.params.id,formData:this.formdata}).then(() => {
                                this.loading = false
                                this.$refs.alert.showAlert('success','Successfully edited category')
                                this.$refs.form.reset();
                            }) 

                        }
                       
                    }
                });
            },
        },
        beforeRouteLeave (to, from, next) {
            this.CLEAR_FORM()
            next();
        }
    }

</script>