<template>
    <draggable
        class="menu-area"
        tag="ul"
        v-bind="dragOptions"
        :group="{ name: 'g1' }"
        :list="list"
        handle=".handle"
        :value="value"
        @input="emitter"
    >

        <li v-for="(data,index) in listMenu" class="drag-gallery" :key="data.id" :class="[{ 'active' : active_gallery === data.id}]">
            <div class="link" :class="[{ 'active' : active_gallery === data.id}]">
                <div class="link-drag handle align-items-center d-flex">
                    <i class="uil uil-draggabledots"></i>
                </div>
                <div class="link-body menu-body cp" v-on:click.prevent="openSlide(data.id)">
                    <div class="link-content">
                        <div class="link-item pr-5">
                            <div><strong>{{data.title}}</strong></div>
                        </div>
                        <div class="link-switch d-flex" v-on:click.stop="">
                            <a href="#" v-on:click.prevent="deleteCustomMenu(index,data.id)" class="act mb-0">
                                <i class="uil uil-trash-alt"></i>
                            </a>
                            <a href="#" v-on:click.prevent="openSlide(data.id)" class="act mb-0">
                                <i :class="[active_gallery === data.id ? 'uil uil-angle-up' : 'uil uil-angle-down']"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="gallery-wrapper form-cst" v-on:click.stop="">
                <validation-provider rules="required" v-slot="{ errors }">
                    <div class="form-group">
                        <label>Menu Title</label>
                        <input v-model="data.title" type="text" class="form-control" name="name">
                        <div class="invalid-input">{{ errors[0] }}</div>
                    </div>
                </validation-provider>

                <div class="form-group">
                    <label>Menu Sub Title</label>
                    <input v-model="data.sub_title" type="text" class="form-control" name="name">
                </div>

                <validation-provider rules="required" v-slot="{ errors }">
                    <div class="form-group">
                        <label>URL Type</label>
                        <select class="form-control" v-model="data.url_type">
                            <option></option>
                            <option value="INTERNAL">INTERNAL</option>
                            <option value="EXTERNAL">EXTERNAL</option>
                        </select>
                        <div class="invalid-input">{{ errors[0] }}</div>
                    </div>
                </validation-provider>

                <validation-provider v-if="data.url_type === 'EXTERNAL'" rules="required|url" v-slot="{ errors }">
                    <div class="form-group">
                        <label>Menu Url</label>
                        <input v-model="data.url" @keydown.space.prevent type="text" class="form-control" name="name">
                        <div class="invalid-input">{{ errors[0] }}</div>
                        <small>If you choose url type external, you must add menu url full. ex : https://its-indonesia.org/about-us</small>
                    </div>
                </validation-provider>

                <validation-provider v-if="data.url_type === 'INTERNAL'" rules="required" v-slot="{ errors }">
                    <div class="form-group">
                        <label>Menu Url</label>
                        <input v-model="data.url" @keydown.space.prevent type="text" class="form-control" name="name">
                        <div class="invalid-input">{{ errors[0] }}</div>
                        <small >If you choose url type internal, you must add menu url after website url. ex : /about-us</small>
                    </div>
                </validation-provider>

                <div class="form-group mt-3">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" v-model="data.target" class="custom-control-input" true-value="_blank" false-value="_self" :id="'highlight' + data.id">
                        <label class="custom-control-label" :for="'highlight' + data.id"><b class="ml-2">Open in new Tab</b></label>
                    </div>
                </div>
            </div>
            <nested-test class="menu2" :list="data.menu"/>
        </li>

    </draggable>
</template>

<script>
    import draggable from "vuedraggable";
    import {ValidationProvider} from "vee-validate";

    export default {
        name: "nested-test",
        data(){
            return{
                active_gallery:'',
            }
        },
        props: {
            value: {
                required: false,
                type: Array,
                default:null,
            },
            list: {
                required: false,
                type: Array,
                default:null,
            },
            del_menu:null,
        },
        components: {
            draggable,
            ValidationProvider
        },
        computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
            listMenu() {
                return this.value ? this.value : this.list;
            },
        },
        methods: {
            emitter(value) {
                this.$emit("input", value);
            },
            openSlide: function(id){
                console.log(id)
                if(this.active_gallery == id){
                    this.active_gallery = ''
                }else{
                    this.active_gallery = id
                }
            },
            deleteCustomMenu: function(index,id){
                if(this.list){
                    this.list.splice(index,1)
                }else{
                    this.value.splice(index,1)
                }
                this.del_menu.push(id)
            },
        },


    };
</script>
<style scoped>
    .menu-area {
        padding: 10px 15px 10px 20px;
        list-style: none;
    }
    .menu-area .menu-area {
        padding: 10px 0px 10px 30px;
    }
    .menu-area .menu-area .menu-area{
        padding: 0px !important;
        display: none;
    }
    .menu-area .menu-area .link{
        margin-bottom: 10px;
    }
    .menu-area .menu2.menu-area .drag-gallery.active .link {
        margin-bottom: 0px;
    }
    .drag-gallery.active .menu2 .gallery-wrapper {
        display: none;
    }
    .drag-gallery .menu2 .drag-gallery.active .gallery-wrapper {
        display: block;
        margin-bottom: 10px;
    }
    .menu-area .menu-area li:last-child .link{
        /* margin-bottom: 0px; */
    }
</style>
