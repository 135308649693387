import $api from '../../utils/api.js'

const state = () => ({
    member: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
    },
    sorting: {
        num:10,
        search: '',
        offset: 4,
        status:'',
        package:'',
        sort:'desc',
        placeholder:'Search Member'
    },
    formdata:{
        id:'',
        organization_name:'',
        business_address:'',
        business_phone:'',
        business_fax:'',
        business_email:'',
        business_principle:'',
        tax_id:'',
        pic_name:'',
        pic_address:'',
        pic_phone:'',
        pic_fax:'',
        pic_mobile:'',
        pic_email:'',
        organization_type:'',
        organization_type_custom:'',
        join_date:'',
        logo_url:'',
        desc:'',
        website:'',
        fb:'',
        ig:'',
        yt:'',
        tw:'',
        in:'',
        representatives:[],
        payments:[],
        package_id:'',
        subscribe_date:'',
        is_show:'1',
        is_confirm:'1',
        is_cpass:'0',
        status:'',
        email:'',
        password:'',
        confirmation:'',
        tax_file_type:'',
        tax_url:'',
    },
    errors:[],
})

const mutations = {
    ASSIGN_DATA(state, payload) {
        state.member = payload
    },
    ASSIGN_FORM(state, payload) {
        state.formdata = {
            id:payload.id,
            organization_name:payload.organization_name,
            business_address:payload.business_address,
            business_phone:payload.business_phone,
            business_fax:payload.business_fax,
            business_email:payload.business_email,
            business_principle:payload.business_principle,
            tax_id:payload.tax_id,
            pic_name:payload.pic_name,
            pic_address:payload.pic_address,
            pic_phone:payload.pic_phone,
            pic_fax:payload.pic_fax,
            pic_mobile:payload.pic_mobile,
            pic_email:payload.pic_email,
            organization_type:payload.organization_type,
            organization_type_custom:payload.organization_type,
            logo_url:payload.logo_url,
            desc:payload.desc,
            website:payload.website,
            fb:payload.fb,
            ig:payload.ig,
            yt:payload.yt,
            tw:payload.tw,
            in:payload.in,
            representatives:payload.representatives,
            payments:payload.payments,
            package_id:payload.package_id,
            packages:payload.packages,
            package_name:payload.package_name,
            subscribe_date:payload.subscribe_date,
            is_show:payload.is_show,
            is_confirm:payload.is_confirm,
            join_date:payload.join_date,
            status:payload.status,
            tax_file_type:payload.tax_file_type,
            tax_url:payload.tax_url
        }
    },
    CLEAR_FORM(state) {
        state.formdata = {
            id:'',
            organization_name:'',
            business_address:'',
            business_phone:'',
            business_fax:'',
            business_email:'',
            business_principle:'',
            tax_id:'',
            pic_name:'',
            pic_address:'',
            pic_phone:'',
            pic_fax:'',
            pic_mobile:'',
            pic_email:'',
            organization_type:'',
            organization_type_custom:'',
            join_date:'',
            logo_url:'',
            desc:'',
            website:'',
            fb:'',
            ig:'',
            yt:'',
            tw:'',
            in:'',
            representatives:[],
            payments:[],
            package_id:'',
            subscribe_date:'',
            is_show:'1',
            is_confirm:'1',
            is_cpass:'0',
            status:'',
            email:'',
            password:'',
            confirmation:'',
            tax_file_type:'',
            tax_url:'',
        },
        state.errors = []
    },
    CLEAR_CATEGORY(state){
        state.member = {
            total: 0,
            per_page: 2,
            from: 1,
            to: 0,
            current_page: 1
        },
        state.sorting =  {
            num:10,
            search: '',
            offset: 4,
            status:'',
            user:'',
            sort:'desc',
            placeholder:'Search Member'
        }
    },
    SET_ERRORS(state, payload) {
        state.errors = payload
    },
}

const actions = {
    getMember({ commit, state }) {
        return new Promise((resolve) => {
            $api.get(`member?page=${state.member.current_page}&paginate=${state.sorting.num}&search=${state.sorting.search}&sort=${state.sorting.sort}&package=${state.sorting.package}&status=${state.sorting.status}`)
            .then((response) => {
                commit('ASSIGN_DATA', response.data)
                resolve(response.data)
            })
        })
    },
    exportMember({ state }) {
        return new Promise((resolve) => {
            $api.get(`export-member?page=${state.member.current_page}&paginate=${state.sorting.num}&search=${state.sorting.search}&sort=${state.sorting.sort}&package=${state.sorting.package}&status=${state.sorting.status}`, {responseType: 'arraybuffer'})
            .then((response) => {
                resolve(response.data)
            })
        })
    },
    exportDetailMember(_,payload) {
        return new Promise((resolve) => {
            $api.get(`export-member/${payload}`, {responseType: 'arraybuffer'})
            .then((response) => {
                resolve(response.data)
            })
        })
    },
    createMember({ commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`member`, payload)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    editMember({ commit }, payload) {
        return new Promise((resolve) => {
            $api.get(`member?id=${payload}`)
            .then((response) => {
                commit('ASSIGN_FORM', response.data)
                resolve(response.data)
            })
        })
    },
    updateMember({ state,commit }, payload) {
        return new Promise((resolve) => {
            $api.post(`member/${state.formdata.id}`, payload.formData)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    deleteMember({ dispatch }, payload) {
        return new Promise((resolve) => {
            $api.delete(`member/${payload}`)
            .then(() => {
                dispatch('getMember').then(() => resolve())
            })
        })
    },
    editUserMember({ commit }, payload) {
        return new Promise((resolve) => {
            $api.get(`member-user/${payload}`)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    updateUserMember({ commit }, payload) {
        return new Promise((resolve) => {
            $api.put(`member-user/${payload.id}`, payload)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                if (error.response.status == 400) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
            })
        })
    },
    
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}