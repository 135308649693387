import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios'

import AuthControl from './modules/AuthControl'
import SelectControl from './modules/SelectControl'
import DashboardControl from './modules/DashboardControl'

import AdminControl from './modules/AdminControl'
import CategoryControl from './modules/CategoryControl'
import TagControl from './modules/TagControl'
import PostControl from './modules/PostControl'
import TeamControl from './modules/TeamControl'
import PageControl from './modules/PageControl'
import PopupControl from './modules/PopupControl'
import SliderControl from './modules/SliderControl'
import MenuControl from './modules/MenuControl'
import NewsletterControl from './modules/NewsletterControl'
import MessageControl from './modules/MessageControl'
import PackageControl from './modules/PackageControl'
import CategoryPackageControl from './modules/CategoryPackageControl'
import MemberControl from './modules/MemberControl'
import PaymentControl from './modules/PaymentControl'
import SettingsControl from './modules/SettingsControl'

Vue.use(Vuex);

const token = localStorage.getItem('token')
axios.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${ token }`;
    return config;
  }, 

  (error) => {
    return Promise.reject(error);
  }
);


export default new Vuex.Store({
  state:{
    isLoading:false,
  },
  modules: {
    AuthControl,
    SelectControl,
    DashboardControl,
    AdminControl,
    CategoryControl,
    TagControl,
    TeamControl,
    PostControl,
    PageControl,
    PopupControl,
    SliderControl,
    MenuControl,
    NewsletterControl,
    PackageControl,
    CategoryPackageControl,
    MemberControl,
    PaymentControl,
    SettingsControl,
    MessageControl
  }
});
