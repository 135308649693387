<template>
    <b-alert
        :show="a_dismiss"
        dismissible
        :variant="a_type"
        @dismissed="a_dismiss=0"
        @dismiss-count-down="countDownChanged"
    >
        <div class="alert-wrapper">
            <span class="mr-5 d-flex align-items-center">
                <i v-if="a_type === 'warning'" class="uil uil-exclamation-triangle"></i>
                <i v-if="a_type === 'success'" class="uil uil-check-circle"></i>
                {{this.a_text}}
            </span>
        </div>
    </b-alert>
</template>

<script>
    export default{
        data(){
            return {
                a_time: 5,
                a_dismiss: 0,
                a_show: false,
                a_type:'',
                a_text:'',
            }
        },
        methods:{
            countDownChanged(a_dismiss) {
                this.a_dismiss = a_dismiss
            },
            showAlert(type,text) {
                this.a_type = type
                this.a_text = text
                this.a_dismiss = this.a_time
            },
        }
    }

</script>